.demi-event-paging .result-text{
    font-size: calc(10rem/16);
    font-family: var(--font-bold-family);
}

@media screen and (min-width: 768px){
    .demi-event-paging .result-text{
        font-size: calc(14rem/16);
    }
}

.demi-event-paging.demi-event-paging--top{
    margin-bottom: 40px;
    margin-top: 10px;
}


@media screen and (min-width: 768px){
    .demi-event-paging.demi-event-paging--top{
        margin-bottom: 32px;
        margin-top: 0;
    }
}

.demi-event-paging.demi-event-paging--bottom{
    margin-top: 25px;
    padding-top: 5px;
    border-top: 1px solid #C1BFB5;
}


@media screen and (min-width: 768px){
    .demi-event-paging.demi-event-paging--bottom{
        margin-top: 36px;
        padding-top: 12px;
    }
}


.content-block{
    margin: 35px 0;

    @media screen and (min-width: 768px){
        margin: 70px 0;
    }
}


.content-block--less-mt {
    position: relative;
    margin-top:-18px;

    @media screen and (min-width: 768px){
        margin-top:-35px;
    }
}

.demi-event-detail-content-block,
.demi-infrastructure-detail-content-block,
.demi-event-paging,
.content-block-small{
    margin: 20px 0;

    @media screen and (min-width: 768px){
        margin: 40px 0;
    }
}

.content-block-results{
    margin: 10px 0 20px 0;

    @media screen and (min-width: 768px){
        margin: 20px 0 30px 0;
    }
}


.content-block-event{
    margin: 24px 0 20px 0;

    @media screen and (min-width: 768px){
        margin: 20px 0 30px 0;
    }
}


.page-wrapper--pistenpano .content-block.content-block {
    margin:0;
}
.custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 4px;
    padding-bottom: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #ada8a6;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #344256;
    border-radius: 1000px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #344256;
}

.offer-slider{
    position: relative;
    padding-bottom:45px;
}
.offer-slider .slick-track{
    margin-left:auto;
    margin-right:auto;
}
.offer-slider .slider__arrow{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index:10;
}
.offer-slider .slider__arrow.slick-prev{
    right: 50px;
}

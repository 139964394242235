.region-switch {
    position: relative;
}
.region-switch .navbar-top__item__icon {
    position: relative;
}

.region-switch:after{
    display: none;
}
.region-switch .region-switch__dropdown-icon{
    position: absolute;
    top:20px;
    left:50%;
    font-size:6px;
    margin-left: -28px;
}
/*
.region-switch:focus .region-switch__dropdown-icon,
.region-switch:hover .region-switch__dropdown-icon{
    top:5px;
}*/

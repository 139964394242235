.contact-teaser {
    position: relative;
}
@media screen and (max-width: 767px){
    html.mobile.portrait .contact-teaser {
        text-align:center;
    }
}

@media screen and (min-width: 768px){
    .presse-contact-box--lg .contact-teaser {
        padding-right:65px;
    }
}


@media screen and (min-width: 768px){
    .contact-teaser__hl{
        font-size: 28px;
    }
}

.contact-teaser__media{
    width: 100%;
    max-width: 160px;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}
.contact-teaser__media video{
   border-radius: 50%;
}

.contact-teaser a:hover,
.contact-teaser a:focus{
    text-decoration: none;
}

.contact-teaser__job {
    font-size:14px;
    font-family:var(--font-default-family);
}
.contact-teaser__desc {
    padding-top:1rem;
    font-size:0.7rem;
    color: #544b45;
    letter-spacing:0.05rem;
}
.contact-teaser__contact {
    padding-top:1rem;
    font-size:15px;
    line-height:1.25;
    font-family:var(--font-light-family);
}

@media screen and (max-width: 767px){
    .contact-teaser__media {
        margin-bottom:1rem;
    }
}

.contact-teaser__mail{
    text-decoration: underline;
    color: #766F6A;
}

@media screen and (max-width: 767px){
    a.contact-teaser__tel{
        color: #766F6A;
        text-decoration: underline;
    }
}
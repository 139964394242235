.tooltip.show {
    opacity: 1;
}
.tooltip-inner {
    max-width: 260px;
    padding: 6px 12px;
    background-color: #38312d;
    border-radius: 0.5rem;
}

/*.tooltip.bs-tooltip-left,*/
.tooltip.bs-tether-target-attached-left {
    margin-left:-10px !important;
}
/*.tooltip.bs-tooltip-top,*/
.tooltip.bs-tether-target-attached-top {
    margin-top:-10px !important;
}

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #38312d;
}

.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #38312d;
}

.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #38312d;
}

.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #38312d;
}
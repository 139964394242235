.anlagen-row,
.nomination-row{
    border-bottom: 1px solid var(--color-lightgrey);
    padding: calc(10rem/16) calc(20rem/16);
    margin: 0;
    row-gap: 10px;
}

.anlagen-status{
    width: 30px;
    height: 30px;
    border: 1px solid #f0efee;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right:10px;
}

.anlagen-status>.icon{
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    font-size: 28px;
    width: 30px;
    height: 30px;
    line-height:30px;
    text-align: center;
}
.anlagen-status>.icon-checkmark{
    font-size: 22px;
}
/*.nomination-row .anlagen-status>.icon-checkmark{
    @media screen and (max-width: 767px){
        left:2px;
    }
}*/

.anlagen-status--open{
    color: #00a102;
}

.anlagen-status--closed{
    color: #c90012;
}
.anlagen-status--vorbereitung{
    color: #F8B900;
}

.anlagen-cat{
    display: block;

    @media screen and (min-width: 768px){
        min-width: 60px;
    }

    @media screen and (max-width: 767px){
        margin-right: 15px;
    }
}

.anlagen-cat.anlagen-cat--empty {
    @media screen and (min-width: 768px){
        min-width: 20px;
    }
}

.anlagen-cat--icon{
    display: inline-block;
    font-size: 25px;
}

.anlagen-cat--difficulty__circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
}

.anlagen-cat--padding {
    padding-right: calc(50rem/16) !important;
}

.difficulty-red{
    background: #cc0000;
}

.difficulty-yellow{
    background: #ffdc00;
}

.difficulty-blue{
    background: #0074d9;
}

.difficulty-black{
    background: #000;
}

.difficulty-blue-red{
    background-image: linear-gradient(#0074d9, #0074d9), linear-gradient(#cc0000, #cc0000);
    background-size: 100% 10px, 100%;
    background-repeat: no-repeat;
    transform: rotate(90deg);
}

.difficulty-red-black{
    background-image: linear-gradient(#000, #000), linear-gradient(#cc0000, #cc0000);
    background-size: 100% 10px, 100%;
    background-repeat: no-repeat;
    transform: rotate(90deg);
}

.difficulty-black-blue{
    background-image: linear-gradient(#000, #000), linear-gradient(#0074d9, #0074d9);
    background-size: 100% 10px, 100%;
    background-repeat: no-repeat;
    transform: rotate(90deg);
}


.anlagen-cat--text{
    font-size: 14px;
    font-family: var(--font-light-family);

    @media screen and (min-width: 768px){
        font-size: 16px;
    }
}

.facilities-overview{
    margin-top: 40px;
    @media screen and (min-width: 992px){
        width: 950px;
        margin: 60px auto 0;
    }
}

.list-info-circles>.list-inline-item{
    margin:0;
    width:45%;
    @media screen and (min-width: 768px){
        width:24%;
    }
}

.list-info-circles>.list-inline-item:nth-child(4n) {
    @media screen and (min-width: 768px){
        margin-right: 0;
    };
}


.facilities-legend>.list-inline-item:not(:last-child){
    margin-right: 40px;
}

.facilities-legend .anlagen-status{
    top: 10px;
}
.slope-collapse  {
    position: relative;
    top:-3px;
}
.slope-collapse .icon {
    display: inline-block;
    position: relative;
    transform: rotate(45deg);
    top:2px;
    border: 1px solid #f0efee;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size:19px;
    text-align: center;
    margin-left:6px;
}
.slope-collapse.collapsed .icon {
    transform: rotate(0);
}

.facilities__opening-times {
    line-height: 1;

    @media screen and (min-width: 767px) {
        font-size: 16px;
    }
}

.facilities__progressbar-wrapper{
    line-height: 1;

    @media screen and (min-width: 767px) {
        font-size: 16px;
    }
}

.progress-section{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.task-progress{
    margin-left: 5px;
    position: relative;
    border: 1px black solid;
    border-radius: 11px;
    padding: 1px;
    max-height: 15px;
    width: 40%;
    @media screen and (min-width: 768px){
        height: 17px;
        max-height: 17px
    }
}

.anlagen__danger-icon{
     @media screen and (min-width: 768px){
         position: absolute;
         font-size: 23px;
         right: 0;
         margin-right: 20px;
      }
    position: absolute;
    right: 0;
    margin-right: 6px;
    font-size: 20px;
    color: #fec900;
}


progress {
    border: 0;
    border-radius: 20px;
    height: 11px;
    max-height: 11px;
    @media screen and (min-width: 768px){
        height: 13px;
        max-height: 13px;
    }
}

progress {
    opacity: 0;
}

.progress--color-green .progress-container {
    position: relative;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    border-radius: 50px;
    width: 0;
    top: -1px;
    background: #11ac02;
}
.progress--color-orange .progress-container {
    position: relative;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    border-radius: 50px;
    width: 0;
    top: -1px;
    background: #db6c23;
}


.progress--color-orange .progress-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: inherit;
    background: #db6c23;
}

progress::-webkit-progress-bar {
    border: 0;
    background-color: transparent;
    border-radius: 20px;
}
progress[value]{
    background-color: transparent;
}

.facilities__progressbar-length{
    font-family: var(--font-bold-family);
    position: absolute;
    z-index: 2;
    @media screen and (min-width: 768px){
        font-size: 13px;
        top: 1px;
     }
    font-size: 10px;
    margin-left: 8px;
    left: 0;
    top: 2px;
}

.facilities__name{
    font-family: UniversLTStd-BoldCn, sans-serif;
}

.facilities-arrange{
    box-sizing: border-box;
    margin: 0;
    min-width: 100%;
    vertical-align: middle;
    padding: 0;
    table-layout: auto;
    height: auto;
    min-height: 49px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.legend{
    display: flex;

}
.legend__dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.legend--green
{
    background-color: #11ac02;
}

.legend--orange
{
    background-color: #db6c23;
}

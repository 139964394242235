.content-video, .video-js {
    color:#fff;
}

.play-btn,
.vjs-default-skin .vjs-big-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    margin:-50px 0 0 -50px;
    display: block;
    font-size: 50px;
    width: 100px;
    height: 100px;
    background-color: #fff;
    color:var(--color-default);
    text-align: center;
    cursor: pointer;
    opacity: 1;
    border:0;
    border-radius: 50%;
    z-index: 20;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    transition: all .15s ease;
}
.vjs-default-skin .vjs-big-play-button:focus, .vjs-default-skin:hover .vjs-big-play-button,
.play-btn:hover,
.vjs-default-skin .vjs-big-play-button:hover {
    background-color: var(--color-default);
    box-shadow: 0 2px 14px rgba(0,0,0,.75);
    color:#fff;
}
.play-btn:before,
.vjs-default-skin .vjs-big-play-button:before {
    line-height: 100px;
    text-shadow: none;
    content: "\e001";
    font-family: VideoJS;
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 767px){
    .calendar-accordion {
        border-bottom:1px solid var(--bg-lightgrey);
    }
    .calendar-accordion .panel-group .panel.calendar-entry {
        border-radius:0;
        margin-bottom:5px;
    }
    .calendar-accordion .panel-group .panel.calendar-entry:last-of-type {
        margin-bottom:0;
    }
    .calendar-accordion .panel-body {
        position: relative;
        padding:10px 15px;
        font-size: 14px;
    }
    .calendar-accordion .panel-heading {
        border-radius: 0;
        padding:0;
        background-color:var(--bg-lightgrey);
    }
    .calendar-accordion .calendar-panel-cat {
        display: block;
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        width:4px;
    }
    .calendar-accordion h3.panel-title {
        position: relative;
        font-size: 14px;
        line-height: 1.35;
        padding:0;
        margin:0;
    }
    .calendar-accordion h3.panel-title>a {
        position: relative;
        display: block;
        padding: 10px 40px 10px 20px;
    }
    .calendar-accordion h3.panel-title>a>.icon {
        position: absolute;
        top:10px;
        right:10px;
        font-size:18px;
        transform:rotate(45deg);
    }
    .calendar-accordion h3.panel-title>a.collapsed>.icon {
        transform:rotate(0deg);
    }
    .calendar-accordion .calendar__date,
    .calendar-accordion .calendar__category,
    .calendar-accordion .calendar__desc{
        margin-bottom:8px;
    }
    .calendar-accordion .calendar__category{
        font-family: var(--font-bold-family);
    }
    .calendar-accordion .calendar__link {
        display: block;
        font-size: 14px;
        margin-bottom:16px;
        text-transform: uppercase;
    }
}
.btn.btn-fb{
    background: #3b5999;
    color: #fff!important;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 10px;
}
.footer .btn.btn-fb{
    font-size: 12px !important;
    line-height: 14px!important;
    padding: 2px 10px!important;
}

.btn.btn-gmail{
    background: #DC4E41;
    color: #fff;
}

.btn-fb .icon{
    font-size: 10px;
    margin-right: 3px;
}

.btn.btn-fb.btn-fb--big,
.btn.btn-gmail--big.btn-gmail--big{
    padding: 22px 30px;
    font-size: 17px;
    line-height: 25px;
    color: #fff !important;
}

.btn.btn-fb.btn-fb--big .icon,
.btn.btn-gmail .icon{
    font-size: 25px;
}
.is-portal-page .container-outer.portal--new-header {
    padding-top: 0px;
}

.is-portal-page:has(.navbar-region-bar) .container-outer.portal--new-header {
    padding-top: 50px;
}


.is-portal-page .portal--new-header .navbar-main.navbar-main--mainheader {
    background-color: transparent;
}

.is-portal-page .portal--new-header .header-sujet__video,
.is-portal-page .portal--new-header .header-sujet__item-lg {
    height: calc(100vh - 0px);

    @media screen and (max-width: 767px) {
        height: calc(100svh - 0px);

    }
}

.is-portal-page .portal--new-header .navbar.navbar-main {
    box-shadow: unset;
}

.is-portal-page:has(.navbar-region-bar) .portal--new-header .header-sujet__video,
.is-portal-page:has(.navbar-region-bar) .portal--new-header .header-sujet__item-lg {
    height: calc(100vh - 50px); /* topbar */

    @media screen and (max-width: 767px) {
        height: calc(100svh - 50px);

    }
}

.is-portal-page .portal--new-header .header-sujet {
    @media screen and (max-width: 767px) {
        top: -50px;
    }

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 45%);
        z-index: 0;
    }
}

.is-portal-page .portal--new-header .header-sujet__video .header-sujet__video-player {
    height: 100%;
}

.is-portal-page .portal--new-header .header-sujet__overlay-headline.header-sujet__overlay-headline {
    text-align: left;
    font-size: calc(56rem/16);

    @media screen and (min-width:768px){
        font-size: responsive 64px 84px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }
}

.is-portal-page .portal--new-header .header-sujet__overlay-headline.header-sujet__overlay-headline:has(+ .header-sujet__overlay-headline) {
    line-height: 100%;
    font-size: 1.5rem;

    @media screen and (min-width: 768px){
        font-size: responsive 24px 44px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
        line-height: unset;
        margin-bottom: -.75rem;
    }

}

.is-portal-page .portal--new-header .navbar-top {
    background-color: transparent;
    transition: .35s ease background-color, .35s ease height, .35s ease color;
}

.is-portal-page .portal--new-header header.affix .navbar-top {
    background-color: white;
}

.is-portal-page .portal--new-header .navbar-top__item {
    color: white;
    background-color: transparent;
}

.is-portal-page .portal--new-header .navbar-main .navbar-top__item--with-bg,
.is-portal-page .portal--new-header .navbar-main .navbar-top__item--with-bg .btn{
    color: var(--color-default);
}

.is-portal-page .portal--new-header .navbar-main .navbar-top__item-with-bg {
    transition: 0.35s ease background-color;
}

.is-portal-page .portal--new-header .navbar-top__item.navbar-top__item--with-bg {
    background-color: #fec900;
}

.is-portal-page .portal--new-header .navbar-main .navbar-top__item--with-bg.navbar-top__item--with-grey-bg,
.is-portal-page .portal--new-header .navbar-main .navbar-top__item--with-bg.navbar-top__item--with-grey-bg .btn{
    color: white;
    background-color: transparent;
    transition: 0s color;
}

.is-portal-page .portal--new-header .navbar-header.navbar-header--has-background .navbar-top__item,
.is-portal-page .portal--new-header header.affix .navbar-top .navbar-top__item,
.is-portal-page .portal--new-header:has(.header-console-overlay.is-open) .navbar-top .navbar-top__item {
    color: var(--color-default);
}

.is-portal-page .portal--new-header .navbar-header--has-background.navbar-header,
.is-portal-page .portal--new-header:has(.header-console-overlay.is-open) .navbar-top {
    background-color: white;
}

/* when header is affix */
.is-portal-page .portal--new-header header.affix .navbar-top .navbar-top__item--with-bg.navbar-top__item--with-grey-bg,
.is-portal-page .portal--new-header header.affix .navbar-top .navbar-top__item--with-bg.navbar-top__item--with-grey-bg .btn,
.is-portal-page .portal--new-header:has(.header-console-overlay.is-open) .navbar-top .navbar-top__item--with-bg.navbar-top__item--with-grey-bg,
.is-portal-page .portal--new-header:has(.header-console-overlay.is-open) .navbar-top .navbar-top__item--with-bg.navbar-top__item--with-grey-bg .btn {
    background-color: #f2f2f1;
    color: var(--color-default);
}

@media screen and (min-width: 768px){
    .is-portal-page .badge{
        top: 140px;
    }
}

.is-portal-page .portal--new-header .header-sujet__centered-overlay{
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    transform: unset;

    padding-left: 1.5rem;
    padding-bottom: 3rem;
    height: calc(100svh - 50px); /* adapt to different navheigts on different sizes */

    @media screen and (min-width: 768px){
        padding-left: 8.3vw; /* 10rem on 1920px */
        padding-bottom: 8rem;
        margin-top: 100px; /* adapt to different navheigts on different sizes */
        height: calc(100svh - 100px - 50px); /* adapt to different navheigts on different sizes */
    }
}

.is-portal-page .portal--new-header .usp-item__list.justify-content-center {
    justify-content: flex-start !important;
    max-width: unset;
    margin: .6rem 0 .75rem 0;

    @media screen and (max-width: 767px) {
        margin: 0rem 0 1rem;
    }
}

.is-portal-page .portal--new-header .usp-item__list li:first-child {
    padding-left: 0;
}

@media screen and (max-width: 767px) {
    .is-portal-page .portal--new-header .usp-item__list li {
        padding-left: 0;
        width: unset;
        flex: unset;
    }

    .is-portal-page .portal--new-header .usp-item {
        display: inline-flex;
        align-items: center;
    }
}



.is-portal-page .portal--new-header .header-sujet__centered-overlay .header-sujet__btn {
    margin: unset !important;
}

@media screen and (max-width: 767px) {
    .is-portal-page .portal--new-header .navbar-toggler .icon-bar {
        background: white;
        transition: .35s ease background;
    }

    .is-portal-page .portal--new-header .navbar-header-items__items:not(.navbar-header-items__items--with-bg) {
        color: white;
        transition: .35s ease color;
    }

    .is-portal-page .portal--new-header .navbar-header.navbar-header--has-background .navbar-toggler .icon-bar {
        background: var(--color-default);
    }
    .is-portal-page .portal--new-header .navbar-header.navbar-header--has-background .navbar-header-items__items:not(.navbar-header-items__items--with-bg) {
        color: var(--color-default);
    }

    .is-portal-page .portal--new-header .navbar-header {
        transition: 0.35s ease background-color;
    }

    .is-portal-page .portal--new-header .navbar-main .navbar-top__item--with-bg,
    .is-portal-page .portal--new-header .navbar-main .navbar-top__item--with-bg .btn{
        color: var(--color-default);
    }
}
/* header */
.classiccar-header{
    position: relative;
    overflow: hidden;
}
.classiccar-header__body{
    text-align: center;
    position: absolute;
    left:50%;
    top:30px;
    transform: translateX(-50%);

    @media screen and (min-width: 768px){
        top:75px;
    }
}

.classiccar-header__hl{
    font-size: 40px;
        @media screen and (min-width: 768px){
        font-size: 68px;
    }
}
.classiccar__user{
    position: relative;
    margin-top:-50px;
    z-index: 5;
    @media screen and (min-width: 768px){
        margin-top:-110px;
        margin-bottom:50px;
    }
}
.classiccar__user-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    margin-bottom: 25px;

    @media screen and (min-width: 768px){
        width: 220px;
        height: 220px;
    }
}
.classiccar__user-hl {
    @media screen and (max-width: 767px){
        font-size:21px;
    }
}


/* login header */
.classiccar-header--login{
    position: relative;
    overflow: visible;
    @media screen and (min-width:768px){
        padding:70px 0;
        margin:50px 0;
    }
}
.classiccar-header__img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 5px rgba(0,0,0,.4), inset 2px 2px 3px rgba(0,0,0,.16);
    z-index: 1;
}
.classiccar-header__loginbox {
    background: #dcdbd9;
    padding:20px;
    @media screen and (min-width:768px){
        position: absolute;
        left:90px;
        top:0;
        bottom:0;
        width:400px;
        padding:50px 30px;
        z-index: 10;
    }
}

.classiccar-header__logininfo {
    position: absolute;
    left:25px;
    right:25px;
    text-align: center;
    top:35px;
    z-index: 5;
    text-shadow: 0 2px 5px rgba(0,0,0,0.45);
    @media screen and (min-width:768px){
        width:300px;
        left:auto;
        right:10%;
        top:50%;
        transform:translateY(-50%);
        text-align: left;
    }
}


@media screen and (max-width:767px){
    .classiccar-header__logininfo-hl {
        margin:0;
        font-size:26px;
    }
}


@media screen and (min-width:1200px){
    .classiccar-header__loginbox {
        left:90px;
        width:510px;
        padding:120px 70px;
    }
    .classiccar-header__logininfo{
        right:20%;
    }
}


/* loginheader right*/
@media screen and (min-width:768px){
    .classiccar-header--login-right .classiccar-header__loginbox {
        left:auto;
        right:90px;
    }
    .classiccar-header--login-right .classiccar-header__logininfo {
        right:auto;
        left:10%;
        text-align: left;
    }
}


.cc-highlightbox {
    background: #eeedeb;
    padding:20px;
    margin:0 auto;
    width: 100%;
    max-width: 450px;
}
.cc-highlightbox .form-section__headline {
    color: #c1a947 !important;
}


/* car teaser */
.car-teaser {
    padding:25px 0;
    border-bottom:1px solid #ddd;
    @media screen and (min-width:768px){
        padding:35px 0 30px 0;
    }
}
.car-teaser__btn.car-teaser__btn {
    z-index:10;
    text-align: center;
    text-transform: uppercase;
    background-color: #544B45;
    border-radius: 0;
    color:#fff!important;

    @media screen and (min-width:768px){
        width:auto;
        position: absolute;
        left:0;
        right:0;
        bottom:0;
    }
}
.car-teaser__btn.car-teaser__btn:hover,
.car-teaser__btn.car-teaser__btn:focus {
    background-color: #000;
}
.car-teaser__delete {
    border-radius:0 !important;
    @media screen and (min-width:768px){
        margin-bottom:20px;
        text-decoration: underline;
    }
}
.car-teaser__delete:hover,
.car-teaser__delete:focus {
    text-decoration: none;
}


.add-car {
    font-size:16px;
    display:block;
    padding-left:35px;
    position:relative;
    @media screen and (min-width:768px){
        font-size:22px;
    }
}
.add-car .icon {
    font-size:9px;
    position: absolute;
    left:8px;
    top:8px;
    transform:rotate(45deg);
    @media screen and (min-width:768px){
        top:14px;
    }
}
.add-car:before {
    content:'';
    position: absolute;
    left:0;
    top:0;
    background:#ddd;
    border-radius: 50%;
    width:26px;
    height:26px;
    @media screen and (min-width:768px){
        top:6px;
    }
}


.add-car:hover:before {
    background:var(--color-default);
}
.add-car:hover .icon {
    color:#fff;
}
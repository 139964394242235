.webcam-map {
    position: relative;
    margin:35px 0;

    @media screen and (min-width:768px){
        margin:35px 0 -35px 0;
    }
}
.webcam-map__lenzerheide,
.webcam-map__arosa {
    display: inline-block;
    position: absolute;
    padding:5px 0;
    color:#fff !important;
    font-size:18px;
    line-height:1.1;
    max-width:40%;
}
.webcam-map__lenzerheide .icon,
.webcam-map__arosa .icon {
    font-size:12px;
    @media screen and (min-width:768px){
        font-size:16px;
    }
}

.webcam-map__lenzerheide {
    padding-left:75px;
    @media screen and (min-width:768px){
        padding-left:100px;
    }
}
.webcam-map__arosa {
    padding-right:75px;
    text-align: right;
    @media screen and (min-width:768px){
        padding-right:100px;
    }
}

.webcam-map__lenzerheide:hover .webcam-map__circle{
    transform: translateX(5px);
}

.webcam-map__arosa:hover .webcam-map__circle{
    transform: translateX(-5px);
}

.webcam-map__circle {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    display: inline-block;
    width:60px;
    height:60px;
    padding:15px 10px;
    border-radius:50%;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    background:#fff;
    transition: all 150ms ease;
    @media screen and (min-width:768px){
        width:80px;
        height:80px;
        margin-top: -40px;
        padding:22px 10px;
    }
}
.webcam-map__lenzerheide .webcam-map__circle {
    left:0;
}
.webcam-map__arosa .webcam-map__circle {
    right:0;
}
.webcam-map__circle .icon {
    display: block;
    color:var(--color-default);
}
.webcam-map__amount {
    display: block;
    color:var(--color-default);
    font-size: 1.1rem;
}



.webcam-map__arosa {
    top:40%;
    right:5%;

    @media screen and (min-width:768px){
        right:10%;
    }
}
.webcam-map__lenzerheide {
    top:40%;
    left:5%;

    @media screen and (min-width:768px){
        left:10%;
    }
}
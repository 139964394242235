.navbar-fake-overlay{
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  width: 480px;
  @media screen and (max-width: 767px){
    display: none;
  }
}

.navbar-fake-overlay .navbar-left__main{
  padding: 30px 50px 30px 40px;
  width: 100%;
}

.navbar-fake-overlay__hl{
  text-align: right;
  color: #000;
  font-size: 28px;
  margin-bottom: 20px;
}

.nav-tabs.nav-tabs--fake-overlay{
  border-bottom: none;

  @media screen and (max-width: 768px){
    margin-top: 20px;
  }
}

.nav-tabs--fake-overlay .nav-item{
  @media screen and (min-width: 768px){
    text-align: right;
  }
}

.nav-tabs--fake-overlay .nav-item--disabled a{
  color: #BAB8B9;
  pointer-events: none;
  cursor: default;
}

.nav-tabs--fake-overlay .nav-link{
  font-size: 21px;
  position: relative;
  padding: 10px 0;

  @media screen and (min-width:768px){
    display: inline-block;
  }
}

.nav-tabs--fake-overlay .nav-link:before {
  content: "";
  height: 1px;
  background: #000;
  position: absolute;
  bottom: 7px;
  right: 30%;
  left: 30%;
  transform: scaleX(0);
  transition: transform .3s ease;
  transform-origin: 100% 50%;

  @media screen and (min-width: 768px){
    right: 0;
    left:0;
    bottom: 9px;
  }
}

.nav-tabs--fake-overlay .nav-link.active:before,
.nav-tabs--fake-overlay .nav-link:hover:before,
.nav-tabs--fake-overlay .nav-link:focus:before {
  transform: scaleX(1);
}

@media screen and (min-width: 992px){
  .navbar-fake-overlay .navbar-left__bottom>li>a {
    font-size: 22px;
    padding: 32px 35px 28px;
    line-height: 24px;
  }
}

.navbar-fake-overlay__info{
  @media screen and (min-width: 768px){
    margin-bottom: 55px;
  }
}

.navbar-fake-overlay__img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #EDEBEC;
  position: relative;
  text-align: center;
  padding: 15px 0;
  margin-right: 15px;
}

.navbar-fake-overlay__img .icon{
  font-size: 25px;
}

.navbar-fake-overlay__name{
  font-size: 21px;
  font-family: var(--font-light-family);

  @media screen and (min-width: 768px){
    color: #BBB7B5;
  }
}

@media screen and (max-width: 767px){
  .navbar-fake-overlay-mobile{
    background: #fff;
    padding: 15px;
  }

  .fake-overlay-content .btn-style-checkbox-wrapper--with-margin .btn-style-checkbox__text{
    margin-bottom: 10px;
  }
}
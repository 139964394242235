/*row gutter*/
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}

.row.row--gutter-width-2 {
    margin-left:-1px;
    margin-right:-1px;
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:1px;
    padding-right:1px;
}
.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:5px;
    padding-right:5px;
}

.row.row--gutter-width-15 {
    margin-left:-7.5px;
    margin-right:-7.5px;
}
.row--gutter-width-15 > .col,
.row--gutter-width-15 > [class^="col"],
.row--gutter-width-15 > [class*=" col"] {
    padding-left:7.5px;
    padding-right:7.5px;
}

.row.row--gutter-width-24 {
    margin-left:-12px;
    margin-right:-12px;
}
.row--gutter-width-24 > .col,
.row--gutter-width-24 > [class^="col"],
.row--gutter-width-24 > [class*=" col"] {
    padding-left:12px;
    padding-right:12px;
}

@media screen and (max-width: 767px){
    .row.row--gutter-width-xs-0 {
        margin-left:0;
        margin-right:0;
    }
    .row--gutter-width-xs-0 > .col,
    .row--gutter-width-xs-0 > [class^="col"],
    .row--gutter-width-xs-0 > [class*=" col"] {
        padding-left:0;
        padding-right:0;
    }
    .row.row--gutter-width-24.row--gutter-width-adjust-16 {
        margin-left:-8px;
        margin-right:-8px;
    }
    .row--gutter-width-24.row--gutter-width-adjust-16 > .col,
    .row--gutter-width-24.row--gutter-width-adjust-16 > [class^="col"],
    .row--gutter-width-24.row--gutter-width-adjust-16 > [class*=" col"] {
        padding-left:8px;
        padding-right:8px;
    }
}

.row.row--gutter-width-40 {
    margin-left:-20px;
    margin-right:-20px;
}
.row--gutter-width-40 > .col,
.row--gutter-width-40 > [class^="col"],
.row--gutter-width-40 > [class*=" col"] {
    padding-left:20px;
    padding-right:20px;
}


@media screen and (min-width: 768px){
    .row.row--gutter-width-80 {
        margin-left:-40px;
        margin-right:-40px;
    }
    .row--gutter-width-80 > .col,
    .row--gutter-width-80 > [class^="col"],
    .row--gutter-width-80 > [class*=" col"] {
        padding-left:40px;
        padding-right:40px;
    }
}


/* vertical gutter */
.row--has-demi-event-teaser,
.row--has-demi-infrastructure-teaser {
    margin-top: -30px;
    clear: both;

    @media screen and (max-width: 767px){
        margin-left:-5px;
        margin-right:-5px;
    }
}
.row--has-demi-infrastructure-teaser > [class^="col"],
.row--has-demi-infrastructure-teaser > [class*=" col"],
.row--has-demi-event-teaser > [class^="col"],
.row--has-demi-event-teaser > [class*=" col"] {
    margin-top: 30px;

    @media screen and (max-width: 767px){
        margin-top: 10px;
        padding-left:5px;
        padding-right:5px;
    }
}

.row--vertical-gutter-10 {
    margin-top: -10px;
    clear: both;
}
.row--vertical-gutter-10 > [class^="col"],
.row--vertical-gutter-10 > [class*=" col"] {
    margin-top: 10px;
}


.row--vertical-gutter-24 {
    margin-top: -24px;
    clear: both;
}
.row--vertical-gutter-24 > [class^="col"],
.row--vertical-gutter-24 > [class*=" col"] {
    margin-top: 24px;
}


.row--vertical-gutter-40 {
    margin-top: -40px;
    clear: both;
}
.row--vertical-gutter-40 > [class^="col"],
.row--vertical-gutter-40 > [class*=" col"] {
    margin-top: 40px;
}



@media screen and (min-width: 768px){
    /* row--same-height */
    .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height,
    .row--same-height.slick-slider .slick-track {
        display: flex;
        flex-direction: row;
    }
    .row--same-height {
        flex-wrap: wrap;
    }
    .row--same-height.slick-slider {
        display: block;
    }
    .row--same-height:before,
    .row--same-height:after {
        display: none;
    }
    .row--same-height > [class^="col"],
    .row--same-height > [class*=" col"],
    .row--same-height.slick-slider [class^="col"],
    .row--same-height.slick-slider [class*=" col"] {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

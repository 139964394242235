.ticket-calc-item {
    position: relative;
    margin:20px 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.ticket-calc-item__body {
    position: relative;
    padding:10px 15px;

    @media screen and (min-width: 768px) {
        padding:20px 28px;
    }
}

.ticket-calc-item__body table {
    @media screen and (min-width: 768px) {
        font-size:17px;
    }
}

.ticket-calc-item__body .wysiwyg ol, .ticket-calc-item__body .wysiwyg p, .ticket-calc-item__body .wysiwyg ul {
    margin-bottom: 14px;
    @media screen and (min-width: 768px) {
        margin-bottom: 22px;
    }
}

.ticket-calc-item__hl {
    color:#fff;
    font-family: var(--font-light-family);
    background-color: var(--color-primary);
    padding:5px 15px;

    @media screen and (min-width: 768px) {
        padding:8px 28px;
    }
}

.ticket-calc-infobox {
    font-size:13px;
    padding:1.375rem 1.25rem;
    border:1px solid;
    margin:18px 0 8px 0;
    @media screen and (min-width: 768px){
        font-size: 15px;
        margin:0;
    }
}


.ticket-calc-badge {
    position: absolute;
    right:-20px;
    top:15px;
    color: #111;
    padding: .625rem 3rem;
    font-size:1rem;
    font-family: var(--font-bold-family);
    background-color: #fec900;

    @media screen and (max-width: 767px) {
        display:none;
    }
}
.ticket-calc-badge:before {
    position: absolute;
    top:100%;
    right:0;
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #d8ab00 transparent transparent transparent;
}
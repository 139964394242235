.slider-teaser-block__teaser{
    margin: 10px 0;
    @media screen and (min-width: 768px){
        margin: -130px 50px 0;
        position:relative;
        z-index:10;
    }
}

.content-slider .badge{
    z-index:25;
}
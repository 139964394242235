.event-detail-info{
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
    padding: calc(32rem/16) calc(22rem/16);
}


	@media screen and (min-width: 768px){
        .event-detail-info{
            padding: calc(22rem/16) calc(36rem/16);
        }
	}


.event-detail-info__date{
    margin-top: calc(28rem/16);
    font-size: calc(16rem/16);
    display: flex;
    align-items: start;
}

.event-detail-info .icon{
    font-size: calc(25rem/16);
}


.event-detail-info__title{
    font-family: var(--font-default-family);
    font-size: calc(22rem/16);
    margin-bottom: calc(24rem/16);
}

@media screen and (min-width: 768px){
    .event-detail-info__title{
        font-size: calc(28rem/16);
        margin-bottom: calc(32rem/16);
    }
}

.event-detail-info__weekdays{
    display: flex;
    margin-top: calc(12rem/16);
    margin-bottom: calc(26rem/16);
}

.event-detail-info__weekdays-item{
    background-color: #F2F2F1;
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    font-size: calc(14rem/16);
    font-family: var(--font-default-family);
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-detail-info__links .btn{
    padding: calc(8rem/16) calc(16rem/16);
    font-size: calc(12rem/16);
    min-height: calc(30rem/16);
    margin-right: calc(10rem/16);
}


@media screen and (min-width: 768px){
    .event-detail-info__links .btn{
        padding: calc(12rem/16) calc(16rem/16);
        font-size: calc(14rem/16);
        min-height: calc(40rem/16);
    }
}

.event-detail-info__time,
.event-detail-info__place,
.event-detail-info__duration,
.event-detail-info__price{
    font-size: calc(16rem/16);
    display: flex;
    align-items: start;
    margin-bottom: calc(12rem/16);
}

.event-detail-info__time{
    justify-content: space-between;
}

.event-detail-info__time-clock{
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.event-detail-info__place a{
    color: var(--color-arosa);
    text-decoration: underline;
}

.event-detail-info__time-label{
    background-color: #F2F2F1;
    padding: 0 calc(12rem/16);
}

.event-detail-info__booking-btn{
    width: 100%;
    margin-bottom: calc(12rem/16);
}

.event-detail-info__booking-btn.disabled{
   background-color: #F2F2F1;
    color: #232323;
}

.event-detail-info__days{
    margin-top: calc(20rem/16);
    margin-bottom: calc(18rem/16);
}


@media screen and (min-width: 768px){
    .event-detail-info__days{
        margin-top: calc(32rem/16);
        margin-bottom: calc(26rem/16);
    }
}

.event-detail-info__days-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(12rem/16);
}

.event-detail-info__days-item-day{
    display: flex;
    align-items: center;
    font-size: calc(16rem/16);
}

.event-detail-info hr{
    margin: calc(23rem/16) 0;
}

.event-detail-info__facts{
    padding-top: calc(20rem/16);
    border-top: 1px solid #E0E0DA;
}
.event-detail-info__contact{
    font-size: calc(18rem/16);
    line-height: calc(26rem/16);
}

.event-detail-info__img img{
    width: 100%;
}

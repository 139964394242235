.countdown-slide__hl{
    /*font-family: var(--font-heavy);*/
}
.countdown__item {
    margin:0;
    display: inline-block;
    text-align: center;
    @media screen and (min-width: 768px){
        margin:0 15px;
    }
}

.countdown__darkgrey {
    color:#fff;
    background:#000;
}
.countdown__lightgrey {
    background:#eeedeb;
}

.countdown__item--darkgrey .countdown__label {
    @media screen and (max-width: 767px){
        color:#fff;
        background:#000;
    }
}

.countdown__item--lightgrey .countdown__label{
    @media screen and (max-width: 767px){
        background:#eeedeb;
    }
}

.countdown-days,
.countdown-hours,
.countdown-mins,
.countdown-secs {
    padding:12px 14px 9px 14px;
    @media screen and (min-width: 768px){
        padding:27px 35px 25px 35px;
    }
}

@media screen and (min-width: 768px){
    .countdown-days {
        padding-left:55px;
        padding-right:55px;
    }
}

.countdown__time {
    font-size: 50px;
    line-height:1;
    font-family: var(--font-bold-family);
}
.countdown__time--secs {
    color:#bbb7b4;
}

.countdown__label {
    font-size: 18px;
    font-family: var(--font-light-family);
    @media screen and (min-width: 768px){
        font-size: 20px;
    }
    @media screen and (max-width: 767px){
       padding-bottom: 10px;
    }
}
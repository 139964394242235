.ratings {
    position: relative;
}

.rating {
    padding: 20px 0 100px 0;
    border: 1px solid #eeedec;

    @media screen and (min-width: 768px) {
        padding: 40px 20px 110px 20px;
    }
}

/*.rating:after {
    content:'';
    width:100px;
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    z-index:50;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}*/
.rating__logo {
    margin-bottom: 12px;
    width: 80px;
    height: auto;
}

.rating-total {
    margin-bottom: 22px;
}

.ratings__teaser {
    height: 100%;
    margin: 0 auto 20px auto;
    background-color: #EEEDEC;
}

.ratings__teaser-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px 25px 35px 25px;
    height: 100%;
}

.ratings__teaser-top {
    justify-content: normal;
}

.ratings__teaser-bottom {
    align-self: flex-end;
    width: 100%;
}

.ratings__teaser-quote {
    color: #BBB7B5;
    font-size: 2rem;
    margin-bottom: .5rem;
}

/*rating circles tripadvisor*/
.rating-circles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rating-circles .rating-circle__circle {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    border: 2px solid #00aa6c;
    margin: 0 2px 0 0;
}
.rating-circles .rating-circle__circle--half,
.rating-circles .rating-circle__circle--filled {
    background: #00aa6c;
    border: 2px solid #00aa6c;
}

/*.rating-circles .rating-circle__circle--half:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    display: block;
    border-radius: 50%;
    background: #00aa6c;
    z-index: 4;
}*/

.rating-circles .rating-circle__circle--half:before {
    content: '';
    position: absolute;
    right: 0;
    top: -10px;
    bottom: -10px;
    width: 8px;
    height: auto;
    display: block;
    background: #fff;
    z-index: 5;
}

/* small */
.rating-circles--small .rating-circle__circle {
    width: 16px;
    height: 16px;
}

.rating-circles--small .rating-circle__circle--half:after,
.rating-circles--small .rating-circle__circle--filled:after {
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
}

.rating-circles--small .rating-circle__circle--half:before {
    width: 6px;
}
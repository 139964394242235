@media screen and (max-width: 374px) {
    .navbar-header-items__items {
        width: calc(48rem/16);
    }
}

@media screen and (max-width: 767px) {
    .navbar-main {
        //box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.125);
    }
    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mobile-nav {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-top: 0;
        transform: translateX(-100%);
        transition: transform 200ms ease-in-out;
        z-index: 9999;
    }
    .mobile-nav__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: calc(17rem/16) 30px 20px;
    }

    .mobile-nav.is-open {
        transform: translateX(0);
    }
    .mobile-nav-container {
        background: #fff;
    }
    .mobile-nav-container .navbar-nav {
        margin: 0;
    }
    .navbar-nav>li.is-open>.main-nav__dropdown {
        display: block;
    }

    .navbar-left__nav{
        margin-top: 40px;
        margin-bottom:25px;
    }

    .navbar-left__nav>li>a{
        font-size: 27px;
        line-height: 32px;
    }

    /*subnav*/
    .navbar-subnav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 10px;
        right: 0;
        transform: translateX(220%);
        transition: transform 250ms ease-in-out;
        z-index:1;
        padding-bottom: 48px;
        overflow: hidden;
        overflow-y: scroll;
        display: block;
        text-align: left;
        background-color: #FFF;
        padding-top: 45px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    }

    .navbar-sub-subnav {
        left: 10px;
    }

    .navbar-subnav li:not(:last-of-type){
        border-bottom: 1px solid #e2e2e2;
    }

    .navbar-left__nav li.is-open>.navbar-subnav {
        transform: translateX(0);
    }
    .navbar-main__dropdown__main {
        margin: 0 -15px;
    }
    .navbar-top__regionchanger .region-switch {
        font-size: 24px;
        display: inline-block;
        position: relative;
        padding: 14px 10px 8px 10px;
        margin: 0;
    }
    .xs-header-langswitch.xs-header-langswitch {
        font-size: 14px;
        padding-left:0;
        padding-right: .75rem;
    }
    .xs-header-langswitch .icon {
        position: relative;
        margin-right:.25rem;
        top:2px;
        font-size: 20px;
    }

    .xs-header-langswitch-label {
        margin-top: .15rem;
    }

    /*.xs-header-search-container{
        position: absolute;
        right:58px;
        top:2px;
    }*/

    .xs-header-search{
        display: flex;
        position: relative;
        padding: 0;
        margin: 0;
        height: 48px;
        align-items: center;
    }
    .xs-header-search>.icon{
        position: relative;
        top:2px;
        font-size:22px;
    }
    .header-searchbar {
        position: fixed;
        left:-8px;
        right:-8px;
        top:67px;
        padding:20px 28px;
        background:#fff;
        box-shadow: inset 0 0px 5px 0 rgba(0,0,0,.25);
    }



 /*   .xs-header-navbar-toggler-container{
        position: absolute;
        right:15px;
        top:2px;
    }*/
    .navbar-toggler{
        position: relative;
        padding: 14px 10px;
        height: 48px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .navbar-toggler .icon-bar{
        background: #38312D;
        width: 27px;
        height: 2px;
        border-radius: 0;
        display: block;
    }

    .navbar-toggler .icon-bar+.icon-bar {
        margin-top: 5px;
    }
    .navbar-left__nav>li>.navbar-subnav>ul>li>a {
        display: block;
        font-size: 23px;
        padding: 10px 20px;
        margin: 0;
    }
    .navbar-left__nav .navbar-subnav li>a>.icon {
        font-size:15px;
        position: absolute;
        right: 20px;
        top: 16px;
        transform: scale(-1, 1);
    }
    .navbar-sub-subnav>ul>li>a {
        padding: 10px 20px;
        display: block;
        line-height: 1.2;
    }
    .navbar-sub-subnav .navbar-sub-subnav ul>li>a>.icon {
        display: none;
    }
    .navbar-subnav__back {
        display: inline-block;
        padding:30px 20px;
    }
    .navbar-subnav__back>.icon {
        margin-right:10px;
    }

    .navbar-header{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }


    .navbar-header-items{
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
    }

    .navbar-header-items__items {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50px;
    }
    .navbar-main.navbar-main--mainheader-is-microsite .navbar-header-items__items {
        height: 66px;
    }
    .navbar-main .navbar-header-items__items--with-bg {
        background: #fec900;
    }

    .navbar-header-items__items .icon-warenkorb{
        font-size: 17px;
    }

    .navbar-header-items__cart{
        position: fixed;
        top: 60px;
        right: -14px;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        box-shadow: 0 0 4px rgba(111, 111, 111, 0.34);
        font-size: 13px;
        background-color: #fec900;
        border: 2px solid #fff;
    }

    .navbar-header-items__cart .icon{
        position: relative;
        left: -3px;
        top: -1px;
    }
}


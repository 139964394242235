.weather-teaser {
    position: relative;
    padding:30px 15px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
    margin-bottom: 20px;
    background:#fff;

    @media screen and (min-width:768px){
        padding:40px;
        margin-bottom: 0;
    }
}

.weather-teaser__hl{
    font-size: 25px;
    @media screen and (min-width: 768px){
        font-size: 35px;
        line-height: 42px;
    }
}

.weather-teaser__sl{
    font-size: 16px;
    line-height: 1;
}

.weather-teaser .card {
    border:0;
}

.weather-teaser__icon {
    font-size:6.5rem;
    @media screen and (max-width: 767px){
        margin-top:1.5rem;
    }
}

.weather-teaser__img {
    display: block;
    width: 100%;
    height:auto;
    margin:0 auto;
    @media screen and (max-width: 767px){
        width: 50%;
    }
}

.weather-teaser__temp {
    font-size:calc(44rem/16);
    line-height: 0.8;
    margin-bottom: .5rem;
    @media screen and (min-width:1440px){
        font-size:calc(58rem/16);
    }
}
.weather-teaser__temp>sup {
    top: -1rem;
    font-size:1.25rem;

    @media screen and (min-width:768px){
        top: -1.75rem;
    }
}
.weather-teaser__temp.has-border {
    border-left:3px solid var(--color-default);
}
.weather-teaser__temp.has-pl {
    padding-left:10px;
}

.weather-teaser__temp-small {
    font-size:1.75rem;
}
.weather-teaser__temp-small>sup {
    top: -.75em;
    font-size:1rem;
}
.weather-teaser__temp-legend {
    font-size:calc(11rem/16);
    letter-spacing: 0.8px;
}
.weather-teaser__temp-legend.has-pl {
    padding-left:10px;
}
.windwerte-heading {
    font-size:1rem;
}
.windwerte-heading .icon{
    position: relative;
    top:10px;
    font-size:1.5rem;
    margin:0 .5rem 1rem 0;
}
.weather-teaser__details {
    font-size:14px;
    margin-bottom:3rem;
    color: #544b45;
    position: relative;
}
.weather-teaser__details--bordered:before,
.weather-teaser__details--bordered:after {
    content:'';
    height:1px;
    width:auto;
    position: absolute;
    left:-16px;
    right: -16px;
    top:-22px;
    background: #cecece;

    @media screen and (min-width: 768px){
        left:-40px;
        right: -40px;
    }
}
.weather-teaser__details--bordered:after {
    top:auto;
    bottom:-22px;
}


.weather-teaser__details__value{
    font-size: 17px;
    color: var(--color-default);
}


.weather__last-update {
    margin:1.5em 0 2em 0;
    font-size:11px;
    color:var(--color-lightgrey);
}
.weather__last-update img {
    position: relative;
    top:-2px;
    margin-left:10px;
}



/* tagesverlauf */
.weather-teaser__tagesverlauf{
    margin-bottom:calc(30rem/16);
}
.weather-teaser__time {
    font-size:1rem;
}
.weather-teaser__img-small>img {
    max-width:25px;
}
.weather-teaser__temp-day {
    font-size:calc(22rem/16);
    line-height: 1;
}
.weather-teaser__temp-day>sup {
    top: -.4em;
    font-size:1rem;
}
.weather-teaser__rain,
.weather-teaser__wind {
    font-size:calc(15rem/16);
}




/* table*/
.weathertable td:first-child {
    padding-left:2px;
}
.weathertable__img {
    width: 56px;
}

.weathertable__day {
    font-size:calc(17rem/16);
    margin-right: 5px;

    @media screen and (min-width: 768px){
        font-size:calc(25rem/16);
    }
}
.weathertable__temp {
    line-height: 1;
    font-size:calc(25rem/16);
    position:relative;
    @media screen and (min-width: 768px){
        font-size:calc(30rem/16);
    }
}
.weathertable__temp--with-divider:before {
    content:'';
    display: block;
    position: absolute;
    left:-13px;
    top:8px;
    bottom:2px;
    width:1px;
    background: var(--color-default);
}
.weathertable__temp>sup {
    top: -.5em;
    font-size:.875rem;
    @media screen and (min-width: 768px){
        top: -.75em;
        font-size:1rem;
    }
}
.weathertable__temp-legend {
    font-size:calc(11rem/16);
    letter-spacing: 0.8px;
    margin-bottom:calc(5rem/16);
    line-height:calc(10rem/16);
}

.weathertable__min-temp {
    font-size:calc(15rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(17rem/16);
    }
}

.is-min-temp {
    color:#0078C8;
}
.is-max-temp {
    color:#AF131F;
}
.img-text-slider{
    @media screen and (max-width: 767px) {
        margin-bottom:100px;
    }
}

.img-text-slide{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.img-text-slide__body{
    padding: 25px;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: 50px 50px 65px 50px;
    }
    @media screen and (min-width: 1200px){
        padding: 50px 50px 20px 50px;
    }
}

.img-text-slide__img{
    height: 100%;
}

.img-text-slide__img img{
    object-fit: cover;
}

.img-text-slide__hl{
    margin-bottom: 20px;
}

.img-text-slide__link{
    margin-top: 20px;
    white-space:normal;
    @media screen and (min-width: 768px){
        margin-top: 30px;
    }
}

.img-text-slider{
    position: relative;
}

.img-text-slider__item{
    padding: 2px 0;
}

@media screen and (max-width: 767px){

    .img-text-slider .slick-prev,
    .img-text-slider .slick-next {
        margin-bottom: -45px;
    }

    .img-text-slider .slider-nav-custom{
        bottom: -100px;
    }

    .img-text-slider .slider-nav-custom .slider-paging {
        margin-top: 45px;
    }

}

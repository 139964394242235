.fake-overlay-content{
  background: #D8D8D8;
  position: relative;

  @media screen and (min-width: 768px){
    margin-left: 484px;
    top: -115px;

  }
}

@media screen and (max-width: 767px){
  .btn-close-overlay,
  .fake-overlay-content .btn-close-navbar{
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

.tab-content--fake-overlay{
  padding: 20px 20px;

  @media screen and (min-width: 1200px){
    padding: 125px 200px;
  }

  @media screen and (min-width: 768px){
    padding: 125px 20px;
  }
}

.tab-content--fake-overlay__hl{
  max-width: 770px;
  margin: 0 auto;
  line-height: 24px;

  @media screen and (min-width: 768px){
    line-height: 35px;
  }
}

.fake-overlay-container{
  max-width: 408px;
  margin: 0 auto;
  width: 100%;
}

.fake-overlay-container--big{
  max-width: 826px;
}

.fake-overlay-container--big .fake-overlay-divider{
  margin: 50px 0 60px;
}

.form-profile .btn{
  padding: 16px 30px;
  max-width: 408px;
  margin: 0 auto;

  @media screen and (min-width: 768px){
    margin-top: 50px;
  }
}


.profile-login-switch{
  color: #766F6A;
}

.profile-login-switch a{
  margin-left: 10px;
}

.myprofile-label{
  font-size: 28px;
  line-height: 34px;
}

.form-section__datepicker-fake-overlay{
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}
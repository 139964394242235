.author-teaser{
  background-color: #EEEDEC;
  padding: 30px 20px;

  @media screen and (min-width: 768px){
    padding: 50px;
  }
}

.author-teaser__job{
  font-size: 16px;

  @media screen and (min-width: 768px){
    font-size: 20px;
  }
}

.author-teaser__name{
  font-size: 25px;
  line-height: 28px;

  @media screen and (min-width: 768px){
    font-size: 35px;
    line-height: 42px;
  }
}

.author-teaser__link{
  margin-top: 10px;

  @media screen and (min-width: 768px){
    margin-top: 25px;
  }
}

.author-teaser__link:hover{
  text-decoration: underline;
}

.author-teaser__desc{
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 767px){
    margin-top: 25px;
  }
}

.author-teaser__more{
  margin-top: 30px;

  @media screen and (min-width: 768px){
    margin-top: 60px;
  }
}

.blog-author_sl{
  line-height: 32px;
}

.blog-detail__author-img{
  margin: 25px 0;

  @media screen and (min-width: 768px){
    margin: 40px 0;
  }
}
.number-spinner {
    position: relative;
}

.number-spinner .btn{
    position: absolute;
    top:0;
    bottom:0;
    width:50px;
    height:auto;
    background-color:var(--bg-lightgrey);
    padding:0;
    border-radius: 0;
}

.spinner-input {
    text-align: center;
    width: 100%;
    height:3.5rem;
}

.number-spinner__label{
    position: relative;
}
.number-spinner .btn.number-spinner__down{
    left:0;
}
.number-spinner .btn.number-spinner__up{
    right:0;
}
.number-spinner .btn-link .icon{
    font-size:20px;
    color:#000;
}
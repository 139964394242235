.has-paging--list {
    text-align:center;
    @media screen and (min-width: 768px){
        text-align:right;
    }
}

.pagination-results,
.pagination-map{
    display: inline-block;
    position: relative;
    padding: 6px 0;
}
.pagination-map{
    padding-left:30px;
}
.pagination-map>.icon{
    position: absolute;
    font-size:150%;
    top:6px;
    left:0;
}
.pagination {
    margin: 0;
}
.pagination > li {
    display: inline;
}

.pagination > li > a,
.pagination > li > a.page-link,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #BBB7B5;
    text-decoration: none;
    background-color: transparent;
    border:0;
    border-right: 1px solid #EFECEE;

    @media screen and (min-width: 768px){
        font-size:20px;
        padding: 6px 16px;
    }
}

.pagination > li > a .icon,
.pagination > li > span .icon {
    position: relative;
    top:2px;
    font-size:90%;
    line-height: inherit;
}
.pagination > li > a .icon-arrow-left:before,
.pagination > li > span .icon-arrow-left:before {
    content:var(--icon-arrow-back);
}
.pagination > li > a .icon-arrow-right:before,
.pagination > li > span .icon-arrow-right:before {
    content:var(--icon-arrow-next);
}

.pagination >li.disabled > a .icon,
.pagination >li.disabled > a.pagination-arrow .icon{
    color: #F1EEF0;
}

.pagination .pagination-arrow{
    color: var(--color-default);
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-right:0;
}
.pagination > li:nth-last-child(2) > a,
.pagination > li:nth-last-child(2) > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-right:0;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    z-index: 2;
    color: var(--color-default);
}

.page-item.active .page-link,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 3;
    color: var(--color-default);
    cursor: default;
    background-color: transparent;
    border-color: #EFECEE;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    cursor: not-allowed;
    color: var(--color-lightgrey);
}
.pagination-lg > li > a,
.pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

/* sort */

.btn.pagination__sort-button{
    border: none;
    font-size: calc(18rem/16);
    padding-right: calc(14.5rem/16);
}

.btn.pagination__sort-button:focus{
    box-shadow: none;
}

.btn.pagination__sort-button::after{
    content: var(--icon-arrow-dropdown);
    font-family: iconfont;
    border: none;
    font-size: calc(8rem/16);
    line-height: 1;
    transform: translateY(1px);
}

.dropdown-menu.pagination__sort-dropdown{
    padding: calc(24rem/16);
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.22);
}

.pagination__sort-dropdown-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.pagination__sort-dropdown-item .icon{
    font-size: calc(13rem/16);
}

.pagination__sort-dropdown-title{
    font-family: var(--font-default);
    font-size: calc(20rem/16);
    line-height: calc(24rem/16);
    margin-bottom: calc(10rem/16);
    text-transform: none;
}

.pagination__sort-dropdown::before {
    display: inline-block;
    width: 0;
    height: 0;
    content: "";
    border-bottom: .6em solid #FFF;
    border-right: .6em solid transparent;
    border-left: .6em solid transparent;
    position: absolute;
    top: -0.6rem;
    left: 50%;
    transform: translateX(-50%);
}

.demi-event-paging .page-link{
    border: none;
    font-size: 12px;
}


.demi-event-paging .pagination > li > a,
.demi-event-paging .pagination > li > a.page-link,
.demi-event-paging .pagination > li > span {
    border: none;
    font-size: 12px;
}

@media screen and (min-width: 768px){
    .demi-event-paging .pagination > li > a,
    .demi-event-paging .pagination > li > a.page-link,
    .demi-event-paging .pagination > li > span {
        font-size: 14px;
    }
}

/* AL-Digital optimizations */
.al-digital {
    & .pagination>li>a.page-link {
        @media screen and (min-width: 768px) {
            font-size: 14px;
            border-right: none;
        }
    }
    & .pagination>li>a .icon {
        font-size: 80%;
    }
}
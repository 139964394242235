.accommodation-teaser__body{
    padding: 20px 15px 15px 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    z-index: 1;

    @media screen and (min-width:768px){
        padding: 30px 15px 15px 15px;
    }
}

.accommodation-teaser__link {
    font-size: 14px;
}

.accommodation-teaser__link-text {
    margin-top: -22px;
    opacity: 0;
    transition: all 150ms ease;
}

.accommodation-teaser:hover .accommodation-teaser__link-text {
    opacity: 1;
}

.accommodation-teaser__portal-text {
    transition: all 150ms ease;
}

.accommodation-teaser:hover .accommodation-teaser__portal-text {
    opacity: 0;
}

.accommodation-teaser.is-lenzerheide:hover .accommodation-teaser__link-text,
.accommodation-teaser.is-lenzerheide:hover .accommodation-teaser__portal-text {
    color: #8a0e18;
}

.accommodation-teaser.is-arosa:hover .accommodation-teaser__link-text,
.accommodation-teaser.is-arosa:hover .accommodation-teaser__portal-text {
    color: #012248;
}

.accommodation-badge{
    position: absolute;
    font-size: 14px;
    line-height: 1;
    padding: 4px 15px;
    left: 50%;
    top: 0;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px){
        min-width: 135px;
    }
}

.accommodation-teaser__bottom{
    padding-top: 10px;
}

.accommodation-teaser__price{
    margin-bottom: 15px;
}
.az-teaser__body{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    bottom: 15px;
    right: 15px;
    left: 15px;
    padding: 10px 12px;
    z-index: 1;

    @media screen and (min-width: 768px){
        bottom: 30px;
        right: 30px;
        left: 30px;
    }
}

.az-teaser__logo {
    display: block;
    position: absolute;
    left:12px;
    top:12px;
    right:12px;
    width:auto;
    height:auto;
    text-align: center;

    @media screen and (min-width: 1024px){
        left:20px;
        top:20px;
        right:20px;
    }
}
.presse-bilder-teaser{
  background-color: #EEEDEC;
  padding: 15px;
  height: 100%;

  @media screen and (min-width: 768px){
    padding: 25px;
  }
}

.presse-bilder-desc{
  font-size: 12px;
  margin-top: 20px;
}

.presse-bilder-teaser .download-link{
  font-size: 14px;
}

.presse-bilder-teaser__img>a{
  display: block;
  position: relative;
}

.presse-bilder-teaser__img .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 20px;

  @media screen and (min-width: 768px){
    font-size: 26px;
  }
}

.presse-bilder__bottom{
  width: 100%;
}

.presse-bilder__bottom .btn-add-to-collection{
  float: right;
}
.presse-bilder__bottom .btn-add-to-collection .icon{
  position: relative;
  top:3px;

}

@media screen and (max-width: 767px){
  .presse-bilder-teaser__img{
    width: 100%;
    text-align: center;
  }

  .img-collection-btns{
    text-align: center;
  }

  .img-collection-btns .btn{
    margin-bottom: 15px;
  }
}

.usp-item__list {
    @media screen and (min-width: 768px){
        max-width: 75vw;
        margin: 0 auto;
    }
    @media screen and (min-width: 1199px){
        max-width: 50vw;
    }
}

.usp-item {
    display: flex;
    align-items: start;
    margin-top: 0;
    font-size: calc(14rem/16);
    text-align: start;
    background-color: rgba(255,255,255,0.8);
    padding: calc(8rem /16) calc(10rem /16);
    border-radius: calc(24rem /16);
    color: var(--color-primary);
    font-family: var(--font-bold-family);
    transition: background-color 0.2s ease-in-out;
    @media screen and (min-width: 768px){
        align-items: center;
        margin-top: calc(12rem/16);
    }
}

.usp-item:hover {
    color: var(--color-primary);
    background-color: rgba(255,255,255,1);
}

.usp-item__circle {
    margin-right: calc(8rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(14rem/16);
}
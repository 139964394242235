.btn{
    padding: 12px 25px;
    font-size: calc(15rem / 16);
    line-height: 1;
    border-radius: 4px;
    box-shadow: none;
    font-family: var(--font-bold-family);
    cursor: pointer;
}
.btn.btn--no-cursor {
    cursor: default;
}
.btn-lg, .btn-group-lg > .btn {
    font-size:calc(22rem/16);
    font-family: var(--font-bold-family);
    border-radius: 2px;
}
.btn.btn-sm {
    padding: 11px 12px;
    font-size: calc(12rem / 16);
}

.btn-default{
    color: #fff;
    text-transform: uppercase;
    border:none;
    background-color: var(--color-default);
}

.btn-default:hover,
.btn-default:focus{
    color: #fff;
}

.btn-primary{
    color: #fff;
    text-transform: none;
    border:none;
    background-color: var(--color-primary);
}
.btn-primary:hover,
.btn-primary:focus{
    background: var(--color-primary-dark);
    color: #fff;
}

.btn-cta {
    border-radius:2px;
    color: #111;
    font-size:1rem;
    font-family: var(--font-bold-family);
    background-color: #fec900;
    border-color: #fec900;
}
.btn-cta:hover {
    background-color: #d8ab00;
    border-color: #cba100;
}
.btn-cta--lg {
    @media screen and (min-width: 768px){
        padding-top: 26px;
        padding-bottom: 26px;
        font-size:calc(18rem/16);
    }
}
.btn-cta--md {
    @media screen and (min-width: 768px){
        padding-top: 18px;
        padding-bottom: 18px;
    }
}

.btn-liftopia {
    border-radius:2px;
    color: #fff;
    font-size:1rem;
    font-family: var(--font-bold-family);
    background-color: #008fd9;
    border-color: #008fd9;
}
.btn-liftopia:hover {
    background-color: #0082c5;
    border-color: #0082c5;
}

.btn-secondary{
    background: transparent;
    color: var(--color-primary);
    text-transform: none;
    border: 1px solid var(--color-primary);
}
.btn-secondary:hover,
.btn-secondary:focus{
    color: #fff;
    background: #000;
    border: 1px solid #000;
}

.btn-white{
    background: #fff;
    color: var(--color-default);
    text-transform: uppercase;
    border: 1px solid #fff;
}
.btn-white:hover,
.btn-white:focus{
    color: var(--color-default);
    background: #fefefe;
    border: 1px solid #000;
}

.btn-light-grey{
    border-radius: 0;
    color: #000;
    background-color: #f2f2f1;
    text-transform: uppercase;
    padding: 12px 30px;
    border: 2px solid transparent;
    transition: all .15s ease;
}

.btn-light-grey:hover,
.btn-light-grey:focus{
    background: transparent;
    border: 2px solid #f2f2f1;
}

.btn-rounded{
    border-radius: 19px;
    border: 1px solid var(--color-lightgrey);
    background: transparent;
    color: var(--color-lightgrey);
    text-transform: uppercase;
    padding: 12px 30px;
}

.btn-lenzerheide,
.btn-lenzerheide:hover,
.btn-lenzerheide.is-active,
.portal-lenzerheide .portal-switch .btn-lenzerheide{
    background: var(--color-lenzerheide);
    color: #fff;
}

.btn-arosa,
.btn-arosa:hover,
.btn-arosa.is-active,
.portal-arosa .portal-switch .btn-arosa{
    background: var(--color-arosa);
    color: #fff;
}

.btn-icon .icon{
    margin-right: 7px;
    top: 1px;
    line-height: 1;
}

.btn-icon--left .icon {
    margin-right: 0;
    margin-left: 7px;
    vertical-align: middle;
}

.btn__small-info-text {
    font-size: 12px;
    margin-left: 6px;
    font-weight: var(--font-default-weight);
}

.btn--small-padding {
    padding: 10px 16px;
    font-size: 14px;
}
.btn-muted {
    background-color: var(--color-lightgrey);
}
.flex{
    display: flex;;
    flex: 1 0 auto;
    flex-direction: column;
}


.flex__grow-1{
    flex-grow: 1;
}

.flex__grow-0{
    flex-grow: 0;
}


.flex-arrange{
    display: flex;
}

.flex-arrange__item{
    flex: 1 1 100px;
    line-height: 1;
}

.flex-arrange--bottom,
.flex-row--bottom{
    align-items: flex-end;
}

.flex-row--middle,
.flex-arrange--middle{
    align-items: center;
}

@media screen and (min-width: 768px){
    .flex-row{
        display: flex;;
        flex: 1 0 auto;
        flex-direction: row;
    }

}
.img-teaser{
    position: relative;
}

.img-teaser:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(227deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: 1;
    opacity: 0.2;
    transition: opacity 200ms ease;
}

.img-teaser:hover:before{
    opacity: 0.4;
}

.img-teaser__video,
.img-teaser__image {
    transition: opacity 500ms ease;
}
.img-teaser__video{
    opacity: 0;
    object-fit: cover;
}
.img-teaser--video:hover .img-teaser__image,
.img-teaser--video:focus .img-teaser__image{
    opacity: 0;
}
.img-teaser--video:hover .img-teaser__video,
.img-teaser--video:focus .img-teaser__video{
    opacity: 1;
}

.img-teaser__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 10px 10px 10px;
    z-index: 2;

    @media screen and (min-width: 768px){
        padding: 0 40px 40px 40px;

    }
}

.img-teaser__body--top{
    bottom:20px;
    top:20px;

    @media screen and (min-width: 768px){
        bottom:40px;
        top:40px;
    }
}
.img-teaser__body--top .btn__align-bottom{
    position: absolute;
    bottom:0;
    left:0;
    right:0;
}
.img-teaser__hl{
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
    word-break: break-word;
    text-transform: none;
    @media screen and (max-width: 991px){
        font-size: 18px;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px){
        font-size: 27px;
    }

}
.img-teaser__sl{
    font-size: 18px;
    line-height: calc(24/18);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 991px){
        font-size: 16px;
    }
}

.img-teaser--small .img-teaser__body{
    padding: 0 10px 10px 10px;
    @media screen and (min-width: 768px){
        padding: 0 25px 25px 25px;
    }
}

.offer-badge{
    background: #fff;
    padding:5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 2;
    line-height: 1;

    @media screen and (min-width:768px){
        top: 30px;
        padding: 18px 20px;
    }
}

.offer-badge__price{
    font-size: 15px;

    @media screen and (min-width:768px){
        font-size: 23px;
    }
}
.img-teaser--dark-bg:before {
    opacity: .4;
}
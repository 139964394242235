.toggle {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.toggle__switch {
    position: relative;
    display: inline-block;
    width: 22px;
    height: 14px;
    & input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .toggle__slider:before {
            transform: translateX(8px);
        }
    }
}

.toggle__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    transition: .4s;
    &:before {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
    }
}

.toggle--round {
    border-radius: 10px;
    &:before {
        border-radius: 50%;
    }
}
.heading{
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 20px;

    @media screen and (min-width: 768px){
        margin-bottom: 35px;
    }
}


.heading__sl{
    @media screen and (min-width: 768px){
        line-height: calc(35/25);
    }
    @media screen and (max-width: 767px){
        font-size: 20px;
        line-height: 25px;
    }
}


.page-heading {
    position: relative;
    @media screen and (max-width: 767px) {
        padding-top:45px;
    }
}

.page-heading__headline {
    @media screen and (min-width: 768px) and (max-width: 1300px) {
        padding-left:170px;
        padding-right:170px;
    }
}

.page-heading__subline{
    line-height: 1.4;
}


/*overlay*/
.page-heading--overlay {
    position: absolute;
    left:0;
    right:0;
    top:15px;
    z-index:20;
    color:#fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (min-width: 768px){
        top:50px;
    }
    @media screen and (max-width: 767px){
        padding-top:0;
    }
}
.page-heading--overlay.page-heading--overlay-center {
    top:50%;
    transform: translateY(-50%);
}
.page-heading--overlay-center:before {
    display: none;
}
.page-heading--overlay-bottom {
    top:auto;
    bottom:15px;
    @media screen and (min-width: 768px){
        top:auto;
        bottom:95px;
    }
}

.page-heading--overlay-cta {
    top:auto;
    bottom:15px;
    @media screen and (min-width: 768px){
        top:auto;
        bottom:35px;
    }
}

/* sorry */
.pimcore_area_teaser-slider .page-heading--overlay-cta {
    top:auto;
    bottom:15px;
    @media screen and (min-width: 768px){
        bottom:100px;
    }
}

.page-heading--overlay:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:-15px;
    z-index:-1;
    height: 130px;
    pointer-events: none;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    @media screen and (min-width: 768px){
        top:-50px;
        height: 280px;
    }
}
.page-heading--overlay-cta:before,
.page-heading--overlay-bottom:before {
    display: none;
}

@media screen and (max-width: 767px){
    .page-heading--overlay .page-heading__headline {
        font-size:24px;
    }
}


/* marktplatz */
.page-heading--marktplatz.page-heading--marktplatz {
    position: absolute;
    left:0;
    right:0;
    top:50%;
    z-index:20;
    color:#fff;
    padding-top: 0;
    transform: translateY(-50%);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (min-width: 768px){
        top:75px;
        transform: translateY(0);
    }
}
.history-label {
    display: inline-block;
    color:#fff;
    background:#39312e;
    font-size: 13px;
    line-height:1;
    padding:4px 20px;
    position: relative;
    text-transform: uppercase;
}
.history-label:after {
    content:'';
    position:absolute;
    left:50%;
    margin-left:-2px;
    bottom:-25px;
    width:4px;
    height:25px;
    background: #dcdbd9;
}
.history-label--bottom {
    margin-top:25px;
}
.history-label--bottom:after {
    bottom:auto;
    top:-50px;
    height:50px;
    width:5px;
}

.history-loading-div {
    position: relative;
    min-height:0;
    transition: all 200ms ease;
}
.history-loading-div.loading {
    min-height:120px;
}


.history-arrow {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 50%;
    box-shadow: 0 5px 9px rgba(0,0,0,.2);
    background-color: #fff;
    font-size: 23px;
    color:#544b44;
    margin-bottom:20px;
}
.history-arrow:after {
    content:'';
    position:absolute;
    left:50%;
    margin-left:-2px;
    bottom:-20px;
    width:4px;
    height:15px;
    background: #dcdbd9;
}

.history-list {
    position: relative;
    z-index: 1;
}

.history-tabs {
    font-size: 13px;
    line-height:1;
    padding:16px 4px;
    display: block;
    position: relative;
    margin:1px auto;
    width:110px;
    background:#eeedeb;
}
.history-tabs:hover,
.history-tabs:focus {
    background:var(--bg-lightgrey);
}

.history-container:first-of-type {
    padding-top:25px;
}
.history-container {
    position: relative;
    margin-bottom:25px;
}
.history-teaser {
    position: relative;
    /*margin:25px 0 25px 0;*/
}
.history-teaser + .history-teaser {
    margin-top:50px;
}
.history-teaser:after {
    content:'';
    position:absolute;
    left:50%;
    margin-left:-2px;
    bottom:-35px;
    width:4px;
    height:35px;
    background: #dcdbd9;
}
.history-teaser__inner {
    position: relative;
    margin:0;
    padding:10px;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
    @media screen and (min-width:768px){
        margin:0 50px;
        padding:30px;
    }
}


@media screen and (max-width:767px){
    .history-teaser__img {
        margin-bottom:10px;
    }
}


.history-teaser__icon {
    position: relative;
    margin: 15px 0 0 0;
    @media screen and (min-width:768px){
        margin: 10px 0 -18px 0;
    }
}
.history-teaser__icon .icon {
    color:#fff;
    font-size:22px;
    z-index:10;
    position: relative;
}
.history-teaser__icon .icon:before {
    position:absolute;
    left:-11px;
    top:-25px;
    color:#fff;
    z-index:10;
}
.history-teaser__icon .icon:after {
    content:'';
    position:absolute;
    left:50%;
    top:-30px;
    transform:translateX(-50%);
    height:34px;
    width:34px;
    background:#fdc901;
    border-radius: 50%;
    z-index:8;
}
.history-teaser__icon--primary .icon:after {
    background:var(--color-primary);
}

@media screen and (min-width:768px){
    .history-teaser__icon:before,
    .history-teaser__icon:after {
        content:'';
        position:absolute;
        left:50%;
        margin-left:0px;
        bottom:-25px;
        width:4px;
        height:35px;
        background: #dcdbd9;
        transform: skew(-3deg);
        z-index:5;
    }
    .history-teaser__icon:before {
        margin-left:-2px;
        transform: skew(3deg);
    }
}
.history-teaser__year {
    font-size:16px;
    @media screen and (min-width:768px){
        font-size:27px;
    }
}
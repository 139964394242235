.modal--table-reservation {
    & .modal-dialog {
        max-width: 1400px;
    }

    & .modal-content {
        border-radius: 0;
        background-color: transparent;
    }

    & .modal-header {
        display: block;
        position: relative;
        background-color: rgba(0,0,0,.75);
    }

    & .modal-body {
        background-color: white;
    }

    & .modal-title {
        font-size: 36px;
        line-height: 42px;
        text-transform: none;
        color: white;
        text-align: center;
    }

    & .close {
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        text-shadow: none;
        opacity: 1;
        width: 40px;
        height: 40px;
    }

    & .modal__badge {
        margin-top: 24px;
        text-align: center;
    }

    & .teaser__opening-badge {
        position: static;
        display: inline-block;
        transform: none;
    }

    & .modal__town-name {
        text-transform: uppercase;
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: white;
    }

    & .modal__reservation-info {
        display: flex;
        flex-wrap: wrap;
        align-items: end;
        margin-top: 60px;
        column-gap: 18px;
    }

    & .modal__reservation-info-item {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    & .modal__reservation-info-item .form-group {
        width: 156px;
        margin-bottom: 0;
    }

    & .modal__reservation-info-item .form-group-item {
        padding: 10px 12px;
    }

    & .modal__reservation-info-item .form-group__icon {
        font-size: 24px;
    }

    & .modal__reservation-label {
        font-size: 14px;
        line-height: 20px;
        color: white;
        margin-bottom: 0;
        font-family: var(--font-default-family);
    }

    & .modal__reservation-time {
        display: flex;
        flex-wrap: wrap;
        column-gap: 18px;
    }

    & .modal__time-item {
        width: 88px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #C1BFB5;
        cursor: pointer;
        user-select: none;
        position: relative;
    }

    & .modal__time-item input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    & .parsley-errors-list {
        position: absolute;
        top: 0;
        right: 15px;
    }

    & .modal__checkbox-wrapper + .parsley-errors-list {
        position: relative;
        top: unset;
        right: unset;
    }

    & .modal__time-item-bg {
        opacity: 0;
        background-color: var(--color-primary);
        transition: background-color 300ms ease;
        position: absolute;
        inset: 0;
    }

    & .modal__time-item input:checked ~ .modal__time-item-bg {
        opacity: 1;
    }

    & .modal__time-item-text {
        font-size: 14px;
        color: #232323;
        z-index: 1;
    }

    & .modal__time-item input:checked ~ .modal__time-item-text {
        color: white;
    }

    & .modal__reservation-time-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 16px;
        position: relative;
        overflow: hidden;
        & > span {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                bottom: 6px;
                left: calc(100% + 12px);
                width: 10000px;
                height: 1px;
                background-color: #C1BFB5;
            }
        }
    }

    & .modal-header__image {
        position: absolute;
        inset: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    & .modal__reservation-area {
        margin-bottom: 48px;
    }

    & .modal__btns {
        padding-top: 48px;
        background-color: #FFFFFF;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    & .btn-disabled {
        background-color: #C1BFB5;
        pointer-events: none;
    }

    & .modal__reservation-from-header {
        background-color: #F2F2F1;
    }

    & .modal__reservation-form-header--submitted .close,
    & .modal__reservation-from-header .close {
        color: #000000;
    }

    & .modal__header-text {
        text-align: center;
        color: #232323;
        font-size: 18px;
        line-height: 26px;
        margin-top: 24px;
    }

    & .modal__header-text p {
        margin: 0;
    }
      
    & .modal__reservation-from-label {
        font-size: 14px;
        line-height: 20px;
    }

    & .modal__reservation-form-input {
        border: 1px solid #C1BFB5;
        border-radius: 4px;
        color: #232323;
        box-shadow: none;
    }

    & .modal__reservation-form-input.parsley-error {
        border-color: #C2193D;
    }

    & .parsley-required {
        color: #C2193D;
    }

    & select.form-control:not([size]):not([multiple]) {
        height: auto;
    }

    & .modal__reservation-form-input.has-error {
        border-color: #C2193D;
        background-color: #FFFFFF;
    }

    & .modal__reservation-form-input::placeholder {
        color: #C1BFB5;
    }

    & input.form-control:not(.form-control--blank):focus,
    & .modal__reservation-form-input:active,
    & .modal__reservation-form-input:focus,
    & .modal__reservation-form-input:not(:placeholder-shown) {
        border-color: #232323;
        box-shadow: none;
    }

    & textarea.modal__reservation-form-input {
        min-height: 120px;
    }

    & .modal__btns--prev {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #232323;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    & .modal__btns--prev .icon {
        margin-right: 12px;
    }

    & .modal__mandatory-info {
        font-size: 10px;
        line-height: 14px;
        color: #C1BFB5;
        margin-right: 24px;
    }

    & .modal__reservation-checkbox {
        height: 0;
        width: 0;
    }

    & .modal__reservation-checkbox + .modal__reservation-checkbox-label {
        padding-left: 24px;
        position: relative;
    }

    & .modal__reservation-checkbox + .modal__reservation-checkbox-label:before {
        content: '';
        height: 18px;
        width: 18px;
        border: 2px solid #000000;
        position: absolute;
        left: 0;
        top: 0;
    }

    & .modal__reservation-checkbox:checked + .modal__reservation-checkbox-label:after {
        content: var(--icon-checkmark);
        font-size: 16px;
        font-weight: bold;
        font-family: 'iconfont';
        position: absolute;
        left: 1px;
        top: -1px;
    }

    & .modal__reservation-form-header--submitted {
        background-color: #FFFFFF;
        text-align: center;
        margin-bottom: 0;
        border: none;
    }

    & .modal__calendar-icon {
        font-size: 48px;
        color: var(--color-primary);
        margin-bottom: 24px;
    }
}

@media screen and (min-width: 768px) {
    .modal--table-reservation {
        & .modal-header {
            padding: 60px 264px 24px;
        }

        & .modal__reservation-form-header--submitted {
            padding-top: 120px;
        }

        & .modal__reservation-time,
        & .modal__reservation-info {
            column-gap: 24px;
        }

        & .modal-body {
            padding: 48px 264px 60px;
        }

        & .modal-body--submitted {
            padding-top: 96px;
            padding-left: 40px;
            padding-right: 40px;
        }

        & .parsley-errors-list {
            top: 4px;
        }

        & .close {
            top: 12px;
            right: 12px;
        }

        & .modal__reservation-area {
            margin-bottom: 60px;
        }

        & .modal__btns {
            padding-top: 60px;
        }
    }
}

.modal-step-form {
    display: none;
}

.modal-step-form.is-current {
    display: block;
}
.sticky-console {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    background-color:#fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 2222;
}


.navbar-sticky-console ul,
.navbar-sticky-console ul>li {
    padding: 0;
    margin: 0 !important;
}

.navbar-sticky-console ul {
    width:100%;
}
.navbar-sticky-console ul>li {
    float:left;
    width:25%;
}
.sticky-console .navbar-sticky-console__item>a {
    padding: 5px 15px;
}
.sticky-console .navbar-sticky-console__item .navbar-sticky-console__item__icon {
    font-size: 20px;
    height: 25px;
}

.navbar-sticky-console__item .navbar-sticky-console__item__icon.icon-danger,
.navbar-top__item .navbar-top__item__icon.icon-danger,
.navbar-sticky-console__item .navbar-sticky-console__item__icon.icon-information,
.navbar-top__item .navbar-top__item__icon.icon-information{
    position: relative;
    top: 3px;
}
.event-filter__categories .btn-style-checkbox__text{
    border-radius: 4px !important;
}

.event-filter__categories.collapsed{
    height: 38px;
    overflow: hidden;
}

@media screen and (min-width: 768px){
    .event-filter__categories.collapsed{
        height: calc(44rem/16);
        overflow: hidden;
    }
}
.event-filter__categories{
    height: auto;
}

.event-filter-header__filter{
    padding-top: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px #C1BFB5;
}

@media screen and (min-width: 768px){
    .event-filter-header__filter{
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

@media screen and (min-width: 768px){
    .event-filter-header__filter{
        padding-top: 36px;
        padding-bottom: 24px;
        border-bottom: solid 1px #C1BFB5;
    }
}

.event-filter-header__filter .btn{
    border: 1px solid #C1BFB5;
    padding: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    color: #C1BFB5;
}


.event-filter-header__filter .icon{
    color: #111;
    font-size: calc(8rem/16);
    transition: transform 150ms ease;
}

.event-filter-header__filter .btn-style-checkbox__text{
    margin-bottom: calc(12rem/16);
}


.event-filter-header .form-control::placeholder{
    color: #C1BFB5;
}

.event-filter-header__top{
    padding-bottom: calc(16rem/16);
    border-bottom: 1px solid #C1BFB5;
}


@media screen and (min-width: 768px){
    .event-filter-header__top{
        padding-bottom: calc(16rem/16);
        border-bottom: 1px solid #C1BFB5;
    }
}

.event-filter-header .filter-wrapper {
    position: relative;
    margin-bottom: 10px;
    width:100%;
}

@media screen and (min-width: 768px){
    .event-filter-header .filter-wrapper {
        margin-bottom: 18px;
    }
}


@media screen and (max-width: 991px) and (min-width: 768px){
    .event-filter-header .form-group-item .hasDatepicker{
        font-size: 16px;
        margin-top: 12px;
    }
}
.event-filter-header label.label-with-icon:before{
    left: -68px;
}




.quickfinder {
    position: relative;
    margin:0 auto;
    width:100%;
    max-width:980px;
    padding:20px 0;

    @media screen and (min-width: 768px){
        padding:25px 0;
    }
}
.quickfinder__btn {
    padding:25px 0;
    border-radius:4px;
    color: #111;
    background-color: #fec900;
    border-color: #fec900;
    font-size: 18px;
}
.quickfinder__btn:hover {
    background-color: #d8ab00;
    border-color: #cba100;
}

@media screen and (max-width: 767px){
    .quickfinder__col {
        margin-bottom: 1rem;
    }
}
.quickfinder__select {
    font-size: 18px;
    line-height: calc(26/18);
    color: #C1BFB5;
}

.footer{
    background: #fff;
    font-size: 13px;
    line-height: 18px;
    font-family: var(--font-default-family);

    @media screen and (min-width: 768px){
        font-size: 14px;
    }
}
@media screen and (max-width: 767px){
    .footer--main-website {
        margin-bottom: 50px;
    }
    .footer--redesign.footer--main-website {
        margin-bottom: 0px;
        border-bottom:50px solid var(--color-default);
    }
    .footer__collapse-link {
        position: absolute;
        right:0;
        top:10px;
        display: inline-block;
        width: 30px;
        height:30px;
        border-radius: 100%;
        border: 1px solid #E2E2E2;
    }
    .footer__collapse-link > .icon {
        position: absolute;
        left:50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 18px;
    }
    .footer__hl--collapsible {
        position: relative;
        display: block;
        padding:15px 55px 15px 0;
        margin: 0;
        border-top: 1px solid #E2E2E2;
    }
}

.footer address{
    line-height: 18px;
}

.footer__top{
    padding: 25px 0;

    @media screen and (min-width: 768px){
        padding: 35px 0 30px 0;
    }
}

.footer__hl{
    font-size: 18px;
    font-family: var(--font-larken-bold);
    font-weight: 700;
    text-transform: none;
    margin-bottom: 17px;
    @media screen and (min-width: 768px){
        line-height: 1.25;
    }
}

.footer__top .btn-fb{
    position: relative;
    top: -3px;
    margin-left: 10px;

    @media screen and (max-width: 767px){
        top:auto;
        margin:0 0 12px 0;
    }
}

.footer__middle{
    padding: 0;

    @media screen and (min-width: 768px){
        border-top:2px solid #EEEDEC;
        padding: 15px 0;
    }
}
.footer__bottom{
    padding: 0 20px 50px;
    @media screen and (min-width: 768px){
        padding: 0 35px 80px;
        width:100%;
        max-width:1920px;
        margin:0 auto;
    }
}

.footer__top .footer__bottom {
    padding-bottom: 0;
}

.footer__bottom.footer__bottom--bright{
    padding: 0;
    max-width:100%;
}
.footer__bottom--bright .footer__partner-social {
    background:#EEEDEC;
    padding:25px  15px;
    @media screen and (min-width: 768px){
        padding: 15px 80px;
        width:100%;
        margin:0 auto;
    }
}
.footer__links--dark{
    background:var(--color-default);
    padding:15px 15px 20px 15px;
    color:#fff;
    @media screen and (min-width: 768px){
        padding: 40px 80px;
        width:100%;
        margin:0 auto;
    }
}
.footer__links--dark a{
    color:#fff;
}


.footer__bottom a:not(.btn):hover,
.footer__bottom a:not(.btn):focus{
    text-decoration: underline;
}
.footer__hl--horizontal{
    @media screen and (min-width: 768px){
        margin-bottom: 0;
        margin-right: 35px;
    }
}

.footer__bottom .list-partner>li{
    padding: 8px;
}
/*.footer__bottom .list-partner>li:last-child{
    display: block;
    text-align: center;
}
.footer__bottom .list-partner>li:last-child img{
    height: 27px;
    display: inline-block;
    width: auto;
}*/
.footer__bottom .list-partner>li:last-child img{
    height: 27px;
    width: auto;
}
.footer__bottom .list-partner>li>a{
    display: block;
}

.footer__partner-social{
    padding: 15px 0 10px;

    @media screen and (min-width: 768px){
        padding: 30px 0 10px;
    }
}

.list-footer-socials li{
    padding: 0 7px;
    margin:0 5px;
}

@media screen and (min-width: 768px){
    .list-footer-socials li:first-child{
        padding-left: 8px;
    }
}

/*.list-footer-socials li:last-child{
    padding-right: 0;
}*/
.list-footer-socials li.list-footer-socials__hl {
    text-transform: none;
    padding-right: 0;
    padding-left: 8px;
    display: block;
    margin-top:5px;

    @media screen and (max-width: 767px){
        padding:0;
        margin-top:12px;
    }

    @media screen and (min-width: 1680px){
        display: inline-block;
        position: relative;
        top:-3px;
        padding-left:48px;
        margin-top:0;
    }
}

@media screen and (min-width: 1680px){
    .list-footer-socials__hl:before {
        content:'';
        position: absolute;
        left:16px;
        top:0;
        display: inline-block;
        width: 1px;
        height:20px;
        background: #DDDBDA;
    }
}
.list-footer-socials li>a>.icon {
    font-size: 18px;
}

.footer__links:not(:last-child){
    padding: 20px 0;
}


.footer__elements-logo{
    margin:10px auto 0 auto;
    display: block;
}
.footer__elements-logo img{
    height: 15px;
    display: inline-block;
    width: auto;
}
@media screen and (min-width: 768px){
    .footer__elements-logo{
        display: inline-block;
        margin:0 auto 0 30px;
    }
    .footer__elements-logo img{
        position: relative;
        top: -3px;
    }
}


@media screen and (min-width: 768px){
    .footer__social-wrapper{
        float: right;
    }
    .footer__social-wrapper--center{
        float: none;
        opacity:0.8;
    }
}

@media screen and (max-width: 767px){
    .list-links-middle.list-links-middle {
        margin:10px 0;
    }
    .footer span.copy,
    .list-links-middle>li>a,
    .list-links-left>li>a{
        display: block;
        padding: 7px 2px;
    }
}

.footerpartner__list--small .img-fluid {
    margin:0 auto;
    max-width:100px;
}


/* scroll top btn */
.scroll-top-btn {
    position: fixed;
    bottom: 1rem;
    right:1rem;
    width:40px;
    height: 40px;
    display: block;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.33);
    z-index:800;
    opacity:0;
    transition: opacity 0.35s linear;
    @media screen and (max-width: 767px) {
        bottom: 5rem;
    }
}
.scroll-top-btn.active {
    opacity: 1;
}
.scroll-top-btn:before {
    transform: rotate(-45deg);
    position: absolute;
    left: 12px;
    top:15px;
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-default);
    border-width: 1px 1px 0 0;
}
.footer__email-link {
    overflow: hidden;
    text-overflow: ellipsis;
}

.footer__official-partner {
    padding: calc(32rem/16) calc(16rem/16);
}

.footer__official-partner-text {
    font-size: calc(24rem/16);
    line-height: calc(48rem/16);
    padding-bottom: 0;
}

@media screen and (min-width: 768px) {
    .footer__official-partner {
        padding: 0 calc(56rem/16);
    }

    .footer__official-partner-text {
        font-size: calc(20rem/16);
        line-height: calc(32rem/16);
    }
}
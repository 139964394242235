.col-infocircle-mobile {
    @media screen and (min-width: 360px) and (max-width: 767px){
        flex: 0 0 50%;
        max-width: 50%;
    }

}

.info-circle{
    transition: transform .35s cubic-bezier(.68,-.55,.27,1.55);
    text-align:center;

    @media screen and (max-width: 767px){
        width: 160px;
        display: inline-block;
    }
}

.info-circle:hover{
    transform: translateY(-10px);
}

.info-circle:hover .info-circle__link{
    color: var(--color-primary);
}

.portal-default .info-circle:hover .info-circle__link{
    color: #000;
}

.info-circle__circle{
    width: 120px;
    height: 120px;
    position: relative;
    margin: 0 auto;

    @media screen and (min-width: 768px){
        width: 168px;
        height: 168px;
    }
}

.info-circle__circle svg + svg{
    position: absolute;
    left:0;
    top:0;
}
.portal-arosa .info-circle__circle svg > path + path {
    stroke: var(--color-arosa);
}
.portal-lenzerheide .info-circle__circle svg > path + path {
    stroke: var(--color-lenzerheide);
}
.portal-arosa .info-circle__circle--gradient svg > path + path, .portal-lenzerheide .info-circle__circle--gradient svg > path + path {
    stroke: url(#gradient);
}
.tab-pane--facilities .info-circle__circle{
    @media screen and (min-width: 768px) and (max-width:1440px){
        width: 120px;
        height: 120px;
    }
}
.urdenbahn-status-wrapper {
    font-size: 18px;
    @media screen and (min-width: 768px) {
        margin-left:65px;
        font-size: 24px;
    }
}
.urdenbahn-status {
    display: inline-block;
    position: relative;
    width: 76px;
    margin: 0 10px;
}
.urdenbahn-status__status {
    display: inline-block;
    text-align: center;
    position: relative;
    top:4px;
    width: 22px;
    height:22px;
    border-radius: 100%;
    border:3px solid var(--color-default);
    z-index:10;
}
.urdenbahn-status__status--1 {
    background:#6a8a00;
}
.urdenbahn-status__status--0 {
    background:#c90012;
}

.urdenbahn-textstatus-1 {
    color:#6a8a00;
}
.urdenbahn-textstatus-0 {
    color:#c90012;
}


.urdenbahn-status__left,
.urdenbahn-status__right {
    position: absolute;
    top:50%;
    left:0;
    transform: translateY(-50%);
    color:var(--color-default);
    font-size: 22px;
    z-index:5;
}
.urdenbahn-status__right {
    left:auto;
    right:0;
}

.info-circle-values{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

.info-circle-values__current{
    font-size: calc(36rem/16);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(58rem/16);
    }
}

.info-circle-values__current-total,
.info-circle-values__current-value{
    font-size: calc(24rem/16);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
    }
}

.info-circle-values__current.icon-ticket{
    font-size: calc(32rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(44rem/16);
    }
}

.tab-pane--facilities .info-circle-values__current{
    @media screen and (min-width: 768px) and (max-width:1440px){
        font-size: calc(36rem/16);
    }
}

.info-circle-values__all{
    font-size: calc(28rem/16);
    font-family: var(--font-light-family);
    line-height: 1;
    @media screen and (min-width: 768px){
        font-size: calc(28rem/16);
    }
}

.info-circle-values__trenner{
    height: 3px;
    width: 32px;
    background: var(--color-default);
    display: block;
    position: relative;
    margin: 5px auto;

    @media screen and (min-width: 768px){
        width: 42px;
    }
}

.info-circle-values__unit{
    font-size: calc(11rem/16);
    letter-spacing: 0.83px;
    font-family: var(--font-light-family);
    position: absolute;
    right: -18px;
    top: -7px;

    @media screen and (min-width: 768px){
        right: -22px;
    }
}

.info-circle__link{
    font-size: calc(15rem/16);
    line-height: calc(28rem/16);
    font-family: var(--font-light-family);
    margin-top: calc(5rem/16);
    display: block;

    @media screen and (min-width: 768px){
        font-size: calc(21rem/16);
        margin-top: calc(10rem/16);
    }

    @media screen and (max-width: 767px){
        white-space:nowrap;
        margin-bottom: calc(15rem/16);
    }
}

.list-info-circles .info-circle__link{
    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.info-circle__link>.icon{
    font-size: calc(8rem/16);
    margin-left: calc(3rem/16);
    position: relative;
    top: -2px;
}

.info-circle__degrees{
    left:-4px;
}

.info-circle__degrees sup{
    font-size: 18px;
    position: absolute;
    right: -17px;
    top: 2px;
    display: block;
    line-height: 1;
}

.info-circle__icon{
    font-size: 30px;

    position: relative;
    top: -5px;

    @media screen and (min-width: 768px){
        font-size: 38px;
    }
}

.info-circle__text{
    font-size: 11px;
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: 14px;
    }
}

.info-circle-water-value{
    font-size: 21px;
    line-height: 1;
    @media screen and (min-width: 768px){
        font-size: 28px;
    }
}

.info-circle--water .info-circle-values__trenner{
    height: 2px;
    background: #eeedec;
    width: 60px;

    @media screen and (min-width: 768px){
        width: 120px;
    }
}

.info-circle--water .info-circle__degrees sup{
    font-size: 13px;
    top: 11px;
}
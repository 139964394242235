body {
    height: 100vh;
}
.portal-fullscreen {
    height: 100%;
    padding-top: 110px;
    @media (max-width: 767px) {
        padding-top: 48px;
    }
    @media (min-width: 768px) {
        padding-top: 90px;
    }
    @media (min-width: 1200px) {
        padding-top: 110px;
    }
}
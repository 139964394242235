.cart-item-list{
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    @media screen and (min-width: 768px){
        max-height: calc(100vh - 420px);
    }
}

.cart-item-list>li{
    margin-bottom: 20px;
    margin-right: 4px;
}

.cart-item{
    font-size: 16px;
    border: 1px solid #dedede;
    background-color: #fff;
}

.cart-item__img{
    padding: 10px;
}

.cart-item__title{
    font-size: 18px;
    font-family: var(--font-bold-family);
    line-height: 1.2;
    margin-bottom: 10px;
}

.cart-item__price{
    font-size: 18px;
    font-family: var(--font-bold-family);
}

.cart-item__body{
    padding: 10px;
    
    @media screen and (max-width: 767px){
       padding-left: 0;
    }
}

.total-price-text{
    font-size: 20px;
}
.total-price{
    font-size: 22px;
    color: var(--color-primary);
}
.job-filter {
    margin-bottom: calc(60rem/16);
}
.job-filter__btn, .job-filter__select {
    height: calc(60rem/16) !important;
    display: flex;
    align-items: center;
    font-size: calc(18rem/16);
}
.job-filter__btn {
    width: 100%;
    justify-content: center;
}
.filter-wrapper {
    position: relative;
    margin:20px auto;
    width:100%;

    @media screen and (min-width: 768px){
        margin:40px auto;

    }
}
.filter-wrapper--narrow {
    max-width:800px;
}
.filter-wrapper--extra-narrow {
    max-width:1112px;
}

@media screen and (max-width: 767px){
    .filter__col {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 768px){
    .btn-style-checkbox-wrapper {
        margin-top:1rem;
    }
}


.filter__btn{
    padding: .9rem 0;
}
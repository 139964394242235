.note-editable b, .note-editable strong{
    font-family: var(--font-default);
    font-weight: 700;
}
.note-editor.note-frame {
    border:1px solid #DDDDDD;
    overflow: hidden;
}
.note-toolbar {
}
.note-editor.note-frame .note-editing-area .note-editable {
    padding: 1.25rem;
    font-size: 15px;
    background-color: #F5F5F5;
}
.note-editor.note-frame .note-editing-area .note-editable:focus {
    background-color: #fff;
}
.note-editable * {
    color: #495057 !important;
}

.note-toolbar .note-style .note-dropdown-menu {
    min-width: 300px;
    overflow-y: scroll;
    height: 170px;
}

/* table */
.note-popover .note-popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted, .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
    background: url(/static/img/summernote/table.png) repeat;
}

.summernote-label {
    font-size: calc(20rem/16);
    font-weight: 400;
}
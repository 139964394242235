/*.warning {
    position: fixed;
    left:0;
    right:0;
    top:0;
    padding:10px 30px 10px 0;
    background:#af1320;
    color:#fff;
    font-size: 13px;
    z-index:2500;
    @media screen and (min-width:768px){
        text-align:center;
        padding:13px 0;
        font-size:16px;
    }
}
.warning a{
    color:#fff !important;
    text-decoration: underline;
}
.warning__close{
    position: absolute;
    right:0;
    top:0;
    padding:12px;
    @media screen and (min-width:768px){
        padding:15px 15px 0px 15px;
    }
}*/


/* NEW */
.warning {
    position: fixed;
    right:0;
    top: 48px;
    width: 280px;
    padding:20px 30px 20px 20px;
    color:var(--color-text-default);
    background:rgba(254,201,0,.9);
    font-size: 14px;
    z-index:2300;
    box-shadow: 0 0 7px 0 rgba(0,0,0,.4);
    @media screen and (min-width:768px){
        top: 165px;
        padding:35px 45px 35px 35px;
        font-size:18px;
        width: 420px;
    }
}
.warning--danger {
    color:#fff;
    background:rgba(175,19,32,.9);
}
.warning a{
    color:inherit !important;
    text-decoration: underline;
}
.warning__close{
    position: absolute;
    right:0;
    top:0;
    padding:12px;
    @media screen and (min-width:768px){
        padding:15px 15px 0px 15px;
    }
}

.warning__headline {
    font-size: 20px;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-bold-family);
    margin-bottom: 10px;
    @media screen and (min-width:768px){
        font-size:32px ;
    }
}
.header-sujet{
    position: relative;
    overflow: hidden;
}

.header-sujet-slider__item{
    position: relative;
}
.header-sujet__video,
.header-sujet__item-lg{
    position: relative;
    width:100%;
    max-width:2560px;
    margin:0 auto;
    height:calc(100vh - 285px);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:cover;
    @media screen and (max-width: 767px){
        height:65vh;
    }
}
.header-sujet__video {
    overflow: hidden;
}
.header-sujet__logo {
    position: absolute;
    right:-1rem;
    top:50%;
    transform: translateY(-50%);
    height:auto;
    max-height:40vh;
    width: auto;
    z-index: 4;
    @media screen and (max-width: 767px){
        max-height:10vh;
        right:-.5rem;
        top:auto;
        bottom:2rem;
        transform: none;
    }
}
.play-btn.header-sujet__pause,
.play-btn.header-sujet__play {
    position: relative;
    top:auto;
    right: auto;
    left: auto;
    margin: 30px auto 0 auto;
    width: 70px;
    height: 70px;
    font-size: 40px;
}
.play-btn.header-sujet__pause:before,
.play-btn.header-sujet__play:before {
    line-height: 70px;
}
.play-btn.header-sujet__pause:before{
    content: "\e002";
}
.header-sujet__video video {
    position: relative;
    /*top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);*/
    /*min-width: 101%;
    min-height: 100%;*/
    width: 100%;
    height:  100%;
    object-fit: cover;
    @media screen and (max-width: 767px){
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        min-width: 100%;
        width: auto;
        height: 70vh;
    }
}
/* sorry */
.pimcore_area_teaser-slider .header-sujet-slider__item{
    @media screen and (max-width: 767px){
        margin-bottom:1.5rem;
    }
}

.header-sujet .slick-dots{
    @media screen and (max-width:767px){
        margin-top:10px;
    }

    @media screen and (min-width:768px){
        position: absolute;
        bottom:20px;
        left:0;
        right:0;
    }
}

.header-sujet__overlay {
    padding:20px 10px 0 10px;
    margin-bottom:5px;
    z-index: 5;

    @media screen and (min-width:768px){
        position: absolute;
        top:0;
        left:0;
        right:0;
        padding:60px 180px;
        box-shadow: none;
        margin-bottom:0;
    }
}
.header-sujet__centered-overlay {
    width: 100%;
    padding:10px;
    margin:0;
    position: absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
    z-index: 5;
}

.header-sujet__overlay-headline.header-sujet__overlay-headline {
    color:inherit;
    font-size:32px;
    margin-bottom:10px;
    text-shadow: 0 0 20px rgba(0,0,0,.15);
    @media screen and (min-width:768px){
        font-size: responsive 40px 68px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }
}

.header-sujet .console-link-list>li {
    margin-top:10px;
}

.header-sujet__btn{
    @media screen and (min-width:768px){
        font-size:calc(22rem/16);
        padding:20px 45px;
        font-family: var(--font-bold-family);
    }

    @media screen and (max-width: 767px){
        margin:5px 0;
    }
}
.header-sujet .btn-lg{
    @media screen and (max-width: 767px){
       white-space: normal;
        line-height: 1.15;
    }
}
.header-sujet--dark-bg {
    &:after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(0,0,0,.2);
        z-index: 0;
    }
}
body {
    font-family: var(--font-default-family);
    font-size: inherit;
    color: var(--color-default);
    font-weight: 400;
}

@media screen and (min-width: 768px){
    body {
        font-size: 18px;
        line-height: calc(28 / 18);
    }
}



@media screen and (min-width: 768px){
    h1, .h1 {
        /* font-size: 35px;
         line-height: calc(40/35);*/
        font-size: responsive 50px 97px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
        font-family: var(--font-larken-default);
        font-weight: 400;
        margin-bottom: 20px;
    }

    h2, .h2 {
        font-family: var(--font-larken-default);
        font-weight: 400;
        font-size: responsive 45px 75px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }

    .demi-event-detail-content-block--intro h1,
    h3, .h3 {
        text-transform: uppercase;
        font-size: responsive 38px 48px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }
    h4, .h4 {
        font-family: var(--font-larken-bold);
        font-weight: 700;
        font-size: responsive 32px 36px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }

    h5, .h5{
        font-size: responsive 30px 38px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
        font-family: var(--font-larken-default);
        font-weight: 400;
    }

    h6, .h6{
        font-size: responsive 22px 25px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
        font-family: var(--font-larken-default);
        font-weight: 400;
    }
    .wysiwyg h6{
        font-size: 20px;
    }

    .intro-text {
        font-size: responsive 19px 21px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }

    .content-hl{
        font-size: responsive 30px 35px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }

    .intro-text__hl{
        font-size: responsive 38px 48px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
    }
}


@media screen and (min-width: 1200px){
    h6, .h6{
        line-height:32px;
    }
}


.font-bold, strong, .strong, b {
    font-family: var(--font-bold-family);
    font-weight: 400;
}
.font-light {
    font-family:var(--font-light-family) !important;
    font-weight: 400;
}
.font-default {
    font-family:var(--font-default-family);
    font-weight: 400;
}
.font-special.font-special {
    font-family:var(--font-larken-bold);
    font-weight: 700;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 10px;
    color: inherit;
}

h1, .h1 {
    font-size: 42px;
    line-height: calc(46/42);
    font-family: var(--font-larken-default);
    font-weight: 400;
    margin-bottom: 20px;
}
h2, .h2 {
    font-size: 42px;
    line-height: calc(46/42);
    font-family: var(--font-larken-default);
    font-weight: 400;
}
.demi-event-detail-content-block--intro h1,
h3, .h3 {
    font-size: 25px;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-bold-family);
}
h4, .h4 {
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-bold-family);
}

h5, .h5{
    font-size: 25px;
    line-height: 1;
    font-family: var(--font-light-family);
}

h6, .h6{
    font-size: 20px;
    line-height:calc(23/20);
    font-family: var(--font-light-family);
}

.wysiwyg h6{
    font-size:16px;
}

.sub-hl{
    font-size: 21px;
    line-height:calc(28/21);

}
.intro-text {
    font-size: 17px;
    line-height:calc(27/17);
    font-family: var(--font-light-family);
}

.intro-text__hl{
    margin-bottom: 10px;
    font-size: 28px;
    font-family: var(--font-bold-family);
    text-transform: uppercase;
}

.content-hl{
    font-size: 25px;
    line-height:calc(28/25);
    font-family: var(--font-light-family);
    margin-bottom: 15px;
}


a.further-link,
a.download-link {
    position: relative;
    padding-left: 20px;
    display: inline-block;
    text-transform: uppercase;
    font-size:13px;

    @media screen and (min-width:768px){
        font-size:15px;
    }
}
a.further-link:hover,
a.further-link:focus,
a.download-link:hover,
a.download-link:focus {
    text-decoration: underline;
}
.list-inline a.further-link,
.list-inline a.download-link{
    margin-right:20px;
}

a.further-link > .icon,
a.download-link > .icon {
    position: absolute;
    left:0;
    top:4px;
    font-size:8px;
    color:inherit;

    @media screen and (min-width:768px){
        top:6px;
        font-size:10px;
    }
}
a.download-link > .icon {
    transform: rotate(90deg);
}

a.download-link--zip {
    padding-left:27px;
}
a.download-link--zip > .icon {
    transform: rotate(0deg);
    top: 2px;
    font-size:14px;

    @media screen and (min-width:768px){
        top: 4px;
        font-size:15px;
    }
}


/*
@media screen and (min-width: 992px){
    h1, .h1 {
        font-size: 97px;
        line-height: calc(104/97);
    }
    h2, .h2 {
        font-size: 75px;
        line-height: calc(88/75);
    }
    h3, .h3 {
        font-size: 48px;
    }
    h4, .h4 {
        font-size: 38px;
    }
    h5, .h5{
        font-size: 38px;
    }
    h6, .h6{
        font-size: 25px;
        line-height: calc(28/25);
    }
    .intro {
        font-size: 21px;
        line-height:calc(32/21);
    }

}
*/

.fs1.fs1{ font-size: 1rem; }

.fz9{ font-size: 9px; }
.fz10{ font-size: 10px; }
.fz12{ font-size: 12px; }
.fz13{ font-size: 13px; }
.fz14{ font-size: 14px; }
.fz15{ font-size: 15px; }
.fz17.fz17{ font-size: 17px; }
.fz20{ font-size: 20px; }
.fz22{ font-size: 22px; }
.fz24{ font-size: 24px; }
.console-detail-link-nav{
    margin-bottom: 20px;

    @media screen and (min-width: 768px){
        margin-bottom: 30px;
    }

    @media screen and (max-width: 767px){
        padding-top: 65px;
    }
}

.console-detail__hl{
    margin-bottom: 10px;
}

.console-detail__sl{
    @media screen and (min-width: 768px){
        font-size: 35px;
    }
}

.console-detail-link-nav .console-nav-item{
    transition: all 150ms ease;
}

.console-detail-link-nav li.active .console-nav-item,
.console-detail-link-nav li .console-nav-item:hover{
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: #fff;
}

.portal-default .console-detail-link-nav li.active .console-nav-item,
.portal-default .console-detail-link-nav li .console-nav-item:hover{
    background: var(--color-default);
    border: 1px solid var(--color-default);
}

.modal.modal-newsletter{
    border: none;
}

.modal.modal-newsletter .modal-dialog-centered {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    min-height:calc(100% - (.5rem * 2));
}

@media (min-width: 576px) {
    .modal.info-modal .modal-dialog-centered {
        min-height:calc(100% - (1.75rem * 2));
    }
}

.modal .modal-newsletter-content{
    border: none;
    width:100%;
}

.modal-newsletter-header{
    font-family: var(--font-default-family);
    padding: calc(40rem/16) calc(40rem/16) 0 calc(40rem/16);
}

.modal-newsletter-body{
    padding: calc(12rem/16) calc(20rem/16) calc(20rem/16) calc(20rem/16);
}

.modal .modal-newsletter-btn .icon{
    position: absolute;
    top: calc(20rem/16);
    right: calc(20rem/16);
    background-color: var(--color-primary);
    border-radius: 50%;
    font-size: calc(9rem/16);
    padding: calc(8rem/16);
    color: #fff;
}

.modal-newsletter img{
    width: 100%;
}


.modal-newsletter__form{
    padding: calc(20rem/16);
    border: 1px solid lightgrey;
    margin-top: calc(30rem/16);
    margin-bottom: calc(50rem/16);
}

.modal-newsletter__form .form-control{
    box-shadow: none;
    border: 1px solid lightgrey;
    height: calc(60rem/16);
}
.modal-newsletter__form label{
    top: calc(35rem/16);
    color: lightgrey;
    text-transform: uppercase;
}
.webcam-hotspots-map{
    margin-bottom: 25px;
    @media screen and (min-width: 768px){
        margin-bottom: 40px;
    }
}

.webcam-hotspots-map__body{
    position: relative;
}

.webcam-hotspots-map__body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4), inset 2px 2px 3px rgba(0, 0, 0, 0.16);
    z-index: 1;
}


.webcam-hotspot{
    position: absolute;
    z-index: 1;
}

.webcam-hotspot__circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transition: transform 200ms ease;
}

.webcam-hotspot:hover .webcam-hotspot__circle{
    transform: scale(1.08);
}

.webcam-hotspot__circle .icon{
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.webcam-hospot__text{
    color: #fff;
    text-shadow: 0 0 6px rgba(0, 0, 0, .95);
    font-size: 13px;
    line-height: 1;
    position: absolute;
    white-space: nowrap;
    top: 13px;
    left: 48px;
    font-family:var(--font-bold-family);
}
.show .dropdown-menu {
    display: block;
}
.dropdown-menu {
    border:0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
}
.dropdown-item {
    padding:0;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: transparent;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: #262626;
    background-color: #f5f5f5;
}
.teaser{
    position: relative;

    @media screen and (min-width: 768px) {
        height: 100%;
    }
}
.teaser__img{
    position: relative;
}
.demi-recommendation-teaser .teaser__img{
    flex:none;
}
.teaser__img:after{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    //box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

.teaser__img:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(227deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: 1;
    opacity: 0;
    transition: opacity 200ms ease;
    pointer-events: none;
}

.teaser:hover .teaser__img:before{
    opacity: 0.4;
}



.teaser__opening-badge{
    color:#fff;
    background: #898482;
    position: absolute;
    font-size: 14px;
    line-height: 1;
    padding: 4px 15px;
    left: 50%;
    top: 0;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px){
        min-width: 135px;
    }
}
.teaser__opening-badge.teaser__opening-badge--open {
    background: #78bb32;
}
.teaser__opening-badge.teaser__opening-badge--closed {
    background: #c2193d;
}


.portal-tag{
    font-size: 14px;
    padding: 4px 15px;
    line-height: 1;

    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
}
.portal-tag--inside{
    transform: translateY(0);
}

.teaser__body{
    padding: 20px 8px 15px 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    z-index: 1;

    @media screen and (min-width:768px){
        padding: 30px 15px 15px 24px;
    }
}

.infrastructure-teaser .teaser-body {
    @media screen and (max-width: 767px) {
        padding: 30px 8px 15px 8px;
    }
}

.teaser__body--small-padding {
    @media screen and (min-width:768px){
        padding: 20px 15px 15px 24px;
    }
}

.teaser__date{
    font-size: 13px;
    margin-bottom: 5px;
}

.teaser__badge{
    position: absolute;
    font-size: 14px;
    line-height: 1;
    padding: 4px 15px;
    left: 50%;
    top: 0;
    transform: translateY(-50%) translateX(-50%);
}

.teaser__badge.teaser__badge--event{
    max-width: 80%;
    width: max-content;

}

@media screen and (max-width: 767px){
    .teaser__badge.teaser__badge--event {
        font-size: 12px;
    }
}

.teaser__body.teaser__body--event{
    padding: 19px 24px 65px;
}

.teaser__badge.teaser__badge--top{
    transform: translateX(-50%);
}

.teaser__link {
    font-size: 14px;

    @media screen and (max-width: 767px){
        font-size: 13px;
    }
}


@media screen and (max-width: 767px){
    .teaser:not(.demi-recommendation-teaser) .h6 {
        font-size:16px;
    }
    .teaser.demi-recommendation-teaser .h6 {
        min-height: 38px;
        font-size:16px;
    }
}

.teaser__desc{
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    margin-top: 15px;

    @media screen and (min-width: 768px){
        margin-bottom: 30px;
        margin-top: 20px;
    }
}

.teaser__desc.teaser__desc--no-bulletpoints ul,
.teaser__desc.teaser__desc--no-bulletpoints ul li {
    padding-left:0;
    list-style: none;
}

.teaser__rating{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(13rem/16);
}
.teaser__rating-overlay{
    display: inline-block;
    margin:2rem auto;
    background: #fff;
    padding:.625rem 1.875rem;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    @media screen and (min-width: 768px){
        margin:1rem auto;
    }
}

.teaser__rating-hl{
    font-size: calc(16rem/16);
    margin-bottom: calc(6rem/16);
    font-family: var(--font-bold-family);
    color:var(--color-default);
}
.teaser__rating-title{
    font-size: calc(16rem/16);
    font-family: var(--font-default);
    margin-bottom: calc(12rem/16);
}

.teaser__rating-logo{
    width: calc(54rem/16);
    margin-right: calc(16rem/16);
}

.teaser__rating-reviews{
    font-size: calc(12rem/16);
    color: var(--color-lightgrey);
    text-decoration: underline;
    margin-left: calc(10rem/16);
}
.teaser__rating-reviews--lg {
    font-size: calc(16rem/16);
}
.content-slider{
    position: relative;
    overflow: hidden;
}
.content-slider--offer{
    padding-bottom:80px;
}

.content-slider__item{
    position: relative;
}


.content-slider__item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4), inset 2px 2px 3px rgba(0, 0, 0, 0.16);
    z-index: 1;
}

.content-slider-nav{
    position: absolute;
    bottom:30px;
    right: 123px;
    width: 180px;
}

.content-slider-nav__item{
    width: 52px;
    margin: 0 5px;
    border:2px solid #fff;
    cursor: pointer;
    opacity: 0.8;
}


.content-slider .slider__arrow{
    position: absolute;
    right: 15px;
    bottom: 14px;
    z-index:10;

    @media screen and (min-width: 768px){
        right: 25px;
        bottom: 32px;
    }
}
.content-slider .slider__arrow.slick-prev{
    right: 62px;
    @media screen and (min-width: 768px){
        right: 75px;
    }
}


.content-slider .slick-current{
    border-width:3px;
    opacity: 1;
}

.content-slider--teaser .slider__arrow{
    @media screen and (min-width: 768px){
        bottom:auto;
        top:36px;
    }
}


.content-slider--teaser .content-slider-nav{
    bottom:auto;
    top:30px;
}
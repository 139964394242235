.infoteaser {
    position: relative;
    padding:15px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
    margin-bottom: 20px;
    background:#fff;

    @media screen and (min-width:768px){
        padding:30px;
        height: 100%;
    }
}

.infoteaser p:last-child{
    margin-bottom: 0;
}

.infoteaser+.infoteaser{
    margin-bottom: 0;
}

.infoteaser a{
    color: inherit;
}

.portal-default .infoteaser a{
    color: #000;
}

.infoteaser a.btn-primary {
    color: #fff;
}

.infoteaser .infoteaser__social a{
    color: #fff;
}
.infoteaser--with-pattern {
    background: url(/static/img/structure.png);
}
.infoteaser.infoteaser--weather-console {
    padding:0;
    box-shadow: none;
    margin-bottom:0;
    background:transparent;
}
.infoteaser__headline {
    margin-bottom: 10px;
    color:#544b45;

    @media screen and (min-width:768px){
        margin-bottom: 20px;
        font-size: responsive 22px 28px; /* min-size, max-size */
    }
}

.infoteaser__img{
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
}

.infoteaser__hashtag {
    font-family:var(--font-light-family);
    font-size:1.5rem;
}
.infoteaser__social{
    margin-top:1rem;

    @media screen and (min-width: 768px){
        margin:0;
        position: absolute;
        bottom:25px;
        right: 25px;
        left:25px;
    }
}

.infoteaser__list + .infoteaser__list {
    margin-top: 10px;

    @media screen and (min-width:768px){
        margin-top: 20px;
    }
}

.infoteaser__list li {
    margin-bottom: 8px;
}
/* square */
.infoteaser.infoteaser--square {
    padding: 0;
    padding-top:100%;
    display: block;
    height: 0;
    overflow: hidden;
    margin: 0;
}
.infoteaser__content {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
    border: 0;
    padding:15px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);

    @media screen and (min-width:768px){
        padding:20px;
        font-size:14px;
    }

    @media screen and (min-width:1440px){
        padding:25px 40px;
        font-size:inherit;
    }
}
.infoteaser__txt {
    font-family:var(--font-light-family);
    margin-bottom: 10px;

    @media screen and (min-width:768px){
        margin-bottom: 25px;
    }
}


/* weather */

.infoteaser__weathericon {
    margin:0;
    font-size:2rem;
}
.infoteaser__temp {
    font-size:1.75rem;
}
.infoteaser__temp>sup {
    top: -.65em;
    font-size:1rem;
}
.iframe-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5rem;
    position: relative;
    @media screen and (min-width: 768px) {
        padding-top: 6.5rem;
    }
}

.iframe-tabs .nav-item {
    font-size: calc(18rem / 16);
}

.iframe-tabs__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(42rem / 16);
    visibility: hidden;
    opacity: 0;
    transition: opacity .25s, visibility 0.25s ease-in;
    @media screen and (min-width: 768px) {
        height: calc(64rem / 16);
    }
}

.nav-link.active + .iframe-tabs__logo {
    visibility: visible;
    opacity: 1;
}

.iframe-tabs .nav-link {
    font-family: var(--font-bold-family);
    color: var(--color-lightgrey);
    border: 0;
    border-radius: 0;
    padding: .25rem .5rem;
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: .25rem 1rem;
        border-bottom: 4px solid transparent;
    }
}

.iframe-tabs .nav-link:focus, .nav-tabs .nav-link:hover,
.iframe-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: #0a0a0a;
    color: var(--color-default);
}

.iframe-collapse {
    background-color: #f2f2f1;
    width: 100%;
    margin: 1rem 0;
}

.iframe-collapse__btn:active,
.iframe-collapse__btn:focus-visible,
.iframe-collapse__btn:focus {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
}

.iframe-collapse__btn,
.iframe-collapse__btn.collapsed {
    display: flex;
    align-items: center;
    background: transparent;
    width: 100%;
    font-size: calc(20rem / 16);
    padding: calc(12rem /16);
    transition: all .2s ease-in-out;
    cursor: pointer;
}


.iframe-collapse__btn:not(.collapsed).is-arosa {
    background: var(--color-arosa);
    color: #fff;
}

.iframe-collapse__btn:not(.collapsed).is-lenzerheide {
    background: var(--color-lenzerheide);
    color: #fff;
}

.iframe-collapse__btn:not(.collapsed).is-skigebiet {
    background: linear-gradient(90deg, #005d9e, #f2213b);
    color: #fff;
}

.iframe-collapse__icon {
    font-size: calc(24rem / 16);
    margin-right: 1rem;
}

.iframe-collapse__link {
    width: 100%;
    cursor: pointer;
    display: block;
    padding: .5rem;
}

.iframe-collapse__link.iframe-active,
.iframe-collapse__link:hover,
.iframe-collapse__link:active,
.iframe-collapse__link:focus {
    background: #d9d9d9;
}
.region-map {
    position: relative;
    width: 100%;
    max-width: 2560px;
    margin: 0 auto;
}
.region-map__overlay {
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    text-shadow: 0 0 10px rgba(0,0,0,.55);
}
.region-map__headline {
    position: absolute;
    bottom:65px;
    left:50%;
    margin:0 0 0 -250px;
    width:500px;
    font-size:32px;
    height:40px;
    line-height:40px;
    color:#fff;
    text-transform: uppercase;
    font-family: var(--font-bold-family);
}


.region-map__overlay .region-map__lenzerheide,
.region-map__overlay .region-map__arosa,
.region-map__overlay .region-map__rothorn,
.region-map__overlay .region-map__weisshorn{
    display: inline-block;
    position: absolute;
    padding:5px 10px;
    font-size:15px;
    transition: all 150ms ease;
    text-shadow: 0 0px 4px rgba(255,255,255,.9);
    line-height: 1.1;
    @media screen and (min-width:768px) and (max-width:1440px){
        padding:20px;
        font-size:24px;
    }
    @media screen and (min-width:1441px){
        padding:3% 1%;
        font-size:24px;
    }
}
.region-map-hide-text {
    opacity: 0;
}

.region-map__lenzerheide:hover,
.region-map__arosa:hover{
    text-shadow: 0 0px 4px rgba(0,0,0,.35);
}

/*.region-map__lenzerheide:hover{
    color: var(--color-lenzerheide);
}

.region-map__arosa:hover{
    color: var(--color-arosa);
}*/

.region-map__overlay .region-map__lenzerheide>.icon,
.region-map__overlay .region-map__arosa>.icon {
    position: relative;
    top:5px;
    margin:0 8px;
    font-size:26px;
    @media screen and (min-width:768px){
        margin:0 12px;
        font-size:56px;
        top:20px;
    }
}

.region-map__overlay .region-map__lenzerheide {
    top: 13%;
    right: 28%;
    color: var(--color-lenzerheide);
    @media screen and (min-width:768px){
        top:16%;
        right:31.5%;
    }
}
.region-map__overlay .region-map__arosa {
    top:41%;
    left:0%;
    color: var(--color-arosa);
    @media screen and (min-width:768px){
        top:46%;
        left:9%;
    }
}

.region-map__overlay .region-map__rothorn {
    left: 34%;
    top: 5%;
     @media screen and (min-width:768px){
         left: 34%;
         top: 5%;
     }
 }

.region-map__overlay .region-map__weisshorn {
    left: 30%;
    top: 36%;
    @media screen and (min-width:768px){
        left: 30%;
        top: 36%;
    }
}

.region-map__altitude{
    font-size: 14px;
}
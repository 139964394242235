.log-in-toggle{
  display: block;
}

.nav-tabs--fake-overlay .nav-link.log-in-toggle{
  display: inline-block;
}

.log-out-toggle,
.nav-tabs--fake-overlay .nav-link.log-out-toggle{
  display: none;
}

.is-login .log-in-toggle,
.nav-tabs--fake-overlay.is-login .nav-link.log-in-toggle{
  display: none;
}

.is-login .log-out-toggle{
  display: block;
}

.nav-tabs--fake-overlay.is-login .nav-link.log-out-toggle{
  display: inline-block;
}
form label {
    margin-bottom: .125rem;
    font-size:0.75rem;
    font-family: var(--font-default-family);
}
.form-control {
    font-family: var(--font-default-family);
    color:var(--color-default);
    border-radius:2px;
    border:0;
    padding: .75rem 1rem;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}
.form-control:disabled, .form-control[readonly] {
    background-color: #fff;
}

input.form-control:not(.form-control--blank):focus {
    box-shadow: inset 0 0 4px 0 rgba(0,0,0,.6);
}

.form-control-lg {
    font-size:1.5rem;
}
select.form-control.form-control-xl:not([size]):not([multiple]) {
    font-size:1.5rem;
    height:4.5rem;
}
.form-control--blank.form-control--blank {
    box-shadow: none;
    padding: 0;
}

.form-control--noshadow.form-control--noshadow {
    box-shadow: none !important;
}
.form-section {
    margin-bottom:1.75rem;
}
.form-section--lg {
    margin-bottom:4rem;
}
.form-section__headline{
    margin-bottom:1.25rem;
}

.form-section__headline--fake-overlay{
    font-size: 28px;
}
.form-group {
    margin-bottom:1.25rem;
}
.form-group--child {
    margin-left:-2px;
}
.form-group.mb10{
    margin-bottom: 10px;
}
.form-group--shadow {
    border-radius:4px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}
.form-group--nomargin {
    margin-bottom:0;
}
.form-group--minh .form-group-item {
    min-height:3.75rem; 
    @media screen and (min-width: 768px){
        min-height:4rem;
    }
}
.form-group a {
    text-decoration: underline;
}
.form-group a:hover,
.form-group a:focus {
    text-decoration:none;
}
.form-group .form-group-item {
    position: relative;
    padding: .375rem 1rem .75rem 1rem;
}
.form-group .form-group-col  + .form-group-col  .form-group-item {
    border-left:1px solid var(--bg-lightgrey);
}

.form-group-item--datepicker{
    height:62px;
    box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.form-group-item--datepicker-with-icon{
    position: relative;
}
.form-group-item--datepicker.form-group-item--datepicker-with-icon .form-control{
    width:100%;
}
.form-group-item--datepicker-with-icon .icon{
    position: absolute;
    right:1rem;
    top:50%;
    transform: translateY(-50%);
    font-size:1.75rem;
    pointer-events: none;
}

@media screen and (min-width: 768px){
    .form-group-item--datepicker{
        height:72px;
    }

    .form-group-item--datepicker .form-control{
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
        padding: 20px 20px 20px;
        width: 93%;
    }

    .form-group-item--datepicker label{
        z-index: 1;
        position: relative;
    }

    .form-group .form-group-item{
        padding: 8px 20px;
        line-height: 1;
    }

    .form-group .form-group-item--small{
        padding: 8px 12px;
    }

/*    .form-group .form-group-item .form-control{
        font-size: 30px;
    }*/

    .form-group-item label{
        font-size: 14px;
    }
}

.form-control--bold {
    font-family: 'Circular-Bld', Tahoma, sans-serif;
}


label.label-with-icon{
    position: relative;
}
label.label-with-icon:before{
    font-family: iconfont;
    font-size: 2rem;
    position: absolute;
    left: -70px;
    top: 3px;
    z-index: 1;

    @media screen and (min-width: 768px){
        left: -75px;
        top: 8px;
    }
}
label.label-with-icon--date:before{
    content:var(--icon-calendar);
}
label.label-with-icon--search:before{
    content:var(--icon-suche);
}
label.label-with-icon--ticket:before{
    content:var(--icon-tickets);
}


.form-control--with-icon-left {
    padding-left:2.25rem;
}
.form-control--with-icon-left + .icon {
    position: absolute;
    left:0;
    bottom:0;
    top:0;
    padding:.75rem .5rem;
    font-size:1.5rem;
    pointer-events: none;
}


.form-control--with-icon-right {
    padding-right:2.25rem;
}
.form-control--with-icon-right + .icon {
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    padding:.75rem 1rem;
    font-size:1.75rem;
    pointer-events: none;
}




.form-group-iconwrapper {
    position: relative;
    padding-left:55px;
}


@media screen and (min-width: 768px){
    .form-group-iconwrapper--max-width{
        max-width: 420px;
        margin-right: calc(60rem/16);
        width: 100%;
    }
}

@media screen and (max-width: 767px){
    .form-group-iconwrapper--max-width .form-control{
       font-size: 16px;
    }
}


.form-group__icon {
    font-size:2rem;
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index:1;
}
.form-group__icon--left {
    left:20px;
}
.form-group__icon--left + .form-control,
.form-group__icon--left + .twitter-typeahead .form-control{
    padding-left:3rem;
}
.form-group__icon--right {
    left:auto;
    right:20px;
}



select.form-control:not([size]):not([multiple]) {
    height: 2.75rem;
}
.form-group-item--small select.form-control:not([size]):not([multiple]) {
    height: 2.25rem;
    padding: .5rem .5rem .5rem 1.5rem;
    background-position: 5px 50%;
}

.custom-select {
    height: calc(2.25rem + 2px);
    padding: .75rem .75rem .75rem 2.25rem;
    line-height: 1.25;
    font-family: var(--font-default-family);
    color:var(--color-default);
    border-radius:4px;
    border:0;
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMiA5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMiA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzM3MzEyRDt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTAuNCwwIDYsNC43IDEuNiwwIDAsMS43IDYsOC4xIDExLjksMS43ICIvPgo8L3N2Zz4K');
    background-size:10px 10px;
    background-repeat:no-repeat;
    background-position: 12px 50%;

    @media screen and (min-width: 768px){
        background-position: 15px 50%;
    }
}

.custom-select:focus {
    box-shadow: inset 0 0 4px 0 rgba(0,0,0,.6);
}


/* custom radio and checkbox */
.custom-control {
    padding-left: 2rem;
    font-size:1.1rem;
    line-height: 27px;
}

.custom-control.fz15{
    font-size: calc(15rem/16);
}

.custom-control-indicator {
    top: 0.25rem;
    width: 1.25rem;
    height:  1.25rem;
    background:#fff;
    box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.custom-checkbox .custom-control-indicator {
    border-radius: 0;
}
.custom-control-input:checked ~ .custom-control-indicator {
    background: #fff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator:before {
    content:'';
    display: block;
    width:14px;
    height:8px;
    border:3px solid #000;
    border-width:0 0 3px 3px;
    position: absolute;
    top:0.25rem;
    left:0.25rem;
    transform: rotate(-45deg);
}
.custom-radio .custom-control-input:focus {
    outline: 0;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator:before {
    content:'';
    display: block;
    width: 0.5rem;
    height:  0.5rem;
    border-radius: 50%;
    background:#000;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);
}


/* errors */
.form-control.has-error {
    background: var(--bg-lightgrey);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.75);
}
ul.parsley-errors-list.filled {
    list-style:none;
    padding:0;
    margin:0;
    font-size:14px;
    color:red;
}
label.has-error,
.form-control.has-error ~ label {
    color:red;
}


/* button style checkbox + radio */
.btn-style-checkbox,
.btn-style-radio{
    position: relative;
    cursor: pointer;
    display: inline-block;
}
.btn-style-checkbox__text,
.btn-style-radio__text {
    border-radius: 999px;
    padding: .75rem 1.5rem;
    line-height:1;
    display: inline-block;
    border:1px solid #dddbda;
    background-color: #fff;
    color: #BBB7B5;
    font-size: 13px;
    font-family: var(--font-default-family);
}

.btn-style-radio__text.btn-style-radio__text--category {
    border-radius: 4px;
}

.btn-style-checkbox.checked .btn-style-checkbox__text.btn-style-checkbox__text--category,
.btn-style-radio__input.checked + .btn-style-checkbox__text.btn-style-checkbox__text--category,
.btn-style-radio__input:checked + .btn-style-checkbox__text.btn-style-checkbox__text--category,
.btn-style-checkbox__input.checked + .btn-style-checkbox__text.btn-style-checkbox__text--category,
.btn-style-checkbox__input:checked + .btn-style-checkbox__text.btn-style-checkbox__text--category {
    border: 2px solid #232323;
    background-color: transparent;
    color: #232323;
    font-family: var(--font-bold-family);
}

.btn-style-checkbox-wrapper--with-margin .btn-style-checkbox__text,
.btn-style-checkbox-wrapper--with-margin .btn-style-radio__text {
    margin-bottom:0.5rem;
}
.btn-style-checkbox__input,
.btn-style-radio__input {
    position: absolute;
    left:-19999px;
    top:0;
}

.btn-style-checkbox.checked .btn-style-checkbox__text,
.btn-style-radio__input.checked + .btn-style-checkbox__text,
.btn-style-radio__input:checked + .btn-style-checkbox__text,
.btn-style-checkbox__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox__input:checked + .btn-style-checkbox__text {
    color:#fff;
    background-color: #544b44;
}

.btn-style-checkbox--dark .btn-style-checkbox__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox--dark .btn-style-checkbox__input:checked + .btn-style-checkbox__text,
.btn-style-checkbox--dark .btn-style-radio__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox--dark .btn-style-radio__input:checked + .btn-style-checkbox__text{
    color:#fff;
    background-color: var(--color-default);
}

.btn-style-checkbox--lenzerheide .btn-style-checkbox__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox--lenzerheide .btn-style-checkbox__input:checked + .btn-style-checkbox__text,
.btn-style-checkbox--lenzerheide .btn-style-radio__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox--lenzerheide .btn-style-radio__input:checked + .btn-style-checkbox__text {
    color:#fff;
    background-color: var(--color-lenzerheide);
}

.btn-style-checkbox--arosa .btn-style-checkbox__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox--arosa .btn-style-checkbox__input:checked + .btn-style-checkbox__text,
.btn-style-checkbox--arosa .btn-style-radio__input.checked + .btn-style-checkbox__text,
.btn-style-checkbox--arosa .btn-style-radio__input:checked + .btn-style-checkbox__text {
    color:#fff;
    background-color: var(--color-arosa);
}

a.btn-style-radio:focus .btn-style-checkbox__text,
a.btn-style-radio:hover .btn-style-checkbox__text,
a.btn-style-checkbox:focus .btn-style-checkbox__text,
a.btn-style-checkbox:hover .btn-style-checkbox__text,
.btn-style-radio__input:focus + .btn-style-checkbox__text,
.btn-style-radio__input:hover + .btn-style-checkbox__text,
.btn-style-checkbox__input:focus + .btn-style-checkbox__text,
.btn-style-checkbox__input:hover + .btn-style-checkbox__text {
    border: 1px solid #544b44;
}


.btn-style-checkbox--lenzerheide .btn-style-checkbox__input:focus + .btn-style-checkbox__text,
.btn-style-checkbox--lenzerheide .btn-style-checkbox__input:hover + .btn-style-checkbox__text,
.btn-style-checkbox--lenzerheide .btn-style-radio__input:focus + .btn-style-checkbox__text,
.btn-style-checkbox--lenzerheide .btn-style-radio__input:hover + .btn-style-checkbox__text {
    border: 1px solid var(--color-lenzerheide);
}

.btn-style-checkbox--arosa .btn-style-checkbox__input:focus + .btn-style-checkbox__text,
.btn-style-checkbox--arosa .btn-style-checkbox__input:hover + .btn-style-checkbox__text,
.btn-style-checkbox--arosa .btn-style-radio__input:focus + .btn-style-checkbox__text,
.btn-style-checkbox--arosa .btn-style-radio__input:hover + .btn-style-checkbox__text{
    border: 1px solid var(--color-arosa);
}

/*placeholder*/
.form-control::-webkit-input-placeholder {
    color:var(--color-default);
}
.form-control::-moz-placeholder {
    color:var(--color-default);
}
.form-control:-ms-input-placeholder {
    color:var(--color-default);
}
.form-control::placeholder {
    color:var(--color-default);
}



/* floating labels */
.form-group--floating {
    position: relative;
}
.form-group--floating .req {
    position: absolute;
    right:.5rem;
    top:0;
}
.form-group--floating label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 2.5rem;
    font-size:1.25rem;
    transform: translateY(-1.25rem) translateX(1.5rem);
    transition: transform 200ms ease-in-out;
    transform-origin: left center;
}
.form-group--floating .custom-select + label {
    transform: translateY(-1.25rem) translateX(2rem);
}
.form-group--floating .custom-select,
.form-group--floating .form-control {
    font-size:1.25rem;
    padding: 2rem 1rem 1rem 1.25rem;
}
.custom-select-lg,
.form-group--floating .custom-select {
    height:4.5rem !important;
    padding-left:2.25rem;
}

.form-group--floating .form-control--file {
    font-size:0.95rem;
}

/*.form-group--floating .form-control[type=file]{
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
}*/

.form-group--floating .form-control.custom-select.has-value,
.form-group--floating .custom-select:focus {
    background-position:15px 50%;
}

.form-group--floating .form-control:focus ~ label,
.form-group--floating .form-control.has-value ~ label {
    transform: translateY(-2.25rem) translateX(1.25rem) scale(.7);
}
.form-group--floating .form-control.custom-select:focus ~ label,
.form-group--floating .form-control.custom-select.has-value ~ label {
    transform: translateY(-2.25rem) translateX(2.25rem) scale(.7);
}





/* demi infra and formbuilder-form overrides */
.formbuilder-form .form-control {
    font-size: 1.25rem;
    padding:1.5rem 1.25rem;
}
.formbuilder-form select.form-control:not([size]):not([multiple]) {
    height: 4.5rem;
    padding:.5rem 1.25rem;
}
.demi-infrastructure-sidebar__filters .checkbox label{
    font-size: 1.1rem;
    padding-left: 2rem;
}
.demi-infrastructure-sidebar__filters .checkbox label,
.formbuilder-form .form-group>label:not(.custom-control) {
    font-size: 1rem;
}
.demi-infrastructure-sidebar__filters input[type="checkbox"]{
    display:none;
}
.demi-infrastructure-sidebar__filters .checkbox {
    position: relative;
}
.demi-infrastructure-sidebar__filters .checkbox label:before{
    position: absolute;
    left:0;
    top: .25rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border:0;
    background:#fff;
    box-shadow: inset 0 0 3px 0 rgba(0,0,0,.4);
}
.demi-infrastructure-sidebar__filters .checkbox label:before {
    content:' ';
}
.demi-infrastructure-sidebar__filters .checkbox input[type=checkbox]:checked + label:after {
    content:' ';
    position: absolute;
    width: 14px;
    height: 8px;
    border: 3px solid #000;
    border-width: 0 0 3px 3px;
    top: .25rem;
    left: .25rem;
    transform: rotate(-45deg);
}
.demi-infrastructure-sidebar__filters .checkbox input[type=checkbox]:checked + label:after {
    top: .5rem;
}

.form-group--select-wrapper {
    position: relative;
    &:after {
        content: var(--icon-arrow-dropdown);
        font-family: iconfont;
        font-size: 6px;
        top: 16px;
        right: 12px;
        position: absolute;
    }
}

.custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: 4.6rem;
    margin: 0;
    opacity: 1 !important;
}

.custom-file-input .form-control{
    padding: 1.4rem 1.25rem;
}
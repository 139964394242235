.cookie-modal {
    z-index: 99999;
}
.cookie-modal .close {
    font-size: 2rem;
    position: relative;
    top: -5px;
    cursor: pointer;
}
.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #212529;
    color: #ffffff;
    font-family: var(--font-light-family);
    padding: .75rem 1rem;
    z-index:9999;
    font-size:15px;
    @media screen and (max-width: 767px) {
        text-align:center;
        font-size:14px;
    }
}
.btn.cookie-bar__accept {
    background-color: green;
    text-transform: uppercase;
    font-family: var(--font-light-family);
    border:0;
}
.btn.cookie-bar__decline {
    color: inherit;
    text-decoration:underline;
    background-color: transparent;
    font-family: var(--font-light-family);
}
.btn.cookie-bar__decline:hover,
.btn.cookie-bar__decline:focus {
    text-decoration:none;
}
@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
    .cookie-modal__title {
        font-size: 32px;
    }
}
.cookie-bar__text {
    display: inline;
}
@media screen and (min-width: 768px) {
    .cookie-bar__text {
        display: block;
        flex: 0 1 auto;
    }
}

.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline-block;
    padding:0;
    font-family: var(--font-light-family);
}
@media screen and (min-width: 768px) {
    .cookie-bar__detail {
        flex: auto;
        margin-left: 1rem;
        padding: 0;
    }
}
.btn.cookie-bar__detail-btn {
    display: inline;
    text-decoration: underline;
    vertical-align: inherit;
    background: transparent;
    color:inherit;
    padding: 0 .25rem !important;
    font-family: var(--font-light-family) !important;

    @media screen and (min-width: 768px) {
        font-size:15px;
    }
}
.cookie-bar__buttons {
    margin-top:.5rem;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin-left: 1rem;
        margin-top:0 !important;
        text-align: right;
    }
}
.cookie-bar__accept {
    margin-left: .75rem;
}

/* modal */
.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.25rem;
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}
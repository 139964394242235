#calendar {
    min-height:500px;
}
.fc-scroller {
    height:auto !important;
    overflow:visible !important;
}
.fc-today-button {
    display: none;
}


table th.fc-day-header{
    background: #fff;
    color: rgba(0,0,0,.6);
    font-family: var(--font-default-family);
    padding:5px;
    font-size:13px;
    text-transform:uppercase;
    text-align: center;
    border-color: transparent !important;
}
.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
    border-color: #fff;
}
.fc-basic-view .fc-day-number, .fc-basic-view .fc-week-number {
    padding: 2px 6px;
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    float: left;
    padding: 2px 10px;
    font-size: 1.25rem;
    font-family: var(--font-light-family);
}
.fc td, .fc th {
    padding: 5px;
    border-width: 10px;
}
.fc td {
    background: #f8f8f8;
}
.fc-row .fc-content-skeleton tbody td, .fc-row .fc-helper-skeleton tbody td {
    padding:0 0 1px 0;
}
.fc-row .fc-content-skeleton td, .fc-row .fc-helper-skeleton td {
    border-color: #fff;
}
.fc-widget-content {
    padding:0 !important;
}
.fc-head-container {
    padding:0 !important;
    background-color: transparent !important;
}
.fc-unthemed td.fc-today {
    background: #e3e6e8;
    border-color: #fff;
    border-style:solid;
}
.fc-event {
    border-radius: 0;
}
.fc-day-grid-event {
    cursor: pointer;
    padding: 5px !important;
}
.fc-day-grid-event .fc-content {
    white-space: normal;
    font-size:13px !important;
}
.fc-toolbar.fc-header-toolbar {
    position: relative;
    margin-bottom:15px;
}
.fc .fc-toolbar>*>* {
    margin:0;
}
.fc-toolbar h2 {
    float:none!important;
    clear: both!important;
    text-align: center;
    font-size:18px;
    text-transform: uppercase;
    font-family: var(--font-default-family);
}
.fc-toolbar .fc-left,
.fc-toolbar .fc-right {
    float:none!important;
    clear: both!important;
}
.fc-toolbar .fc-next-button,
.fc-toolbar .fc-prev-button {
    position: absolute;
    left:0;
    top:-10px;
}
.fc-toolbar .fc-next-button {
    left:auto;
    right:0;
}
.fc-toolbar .fc-button-group {
    width: 100%;
}

.fc-toolbar button.fc-state-default {
    width: 40px !important;
    height: 40px !important;
    border: 0;
    border-radius: 0;
    font-size: 13px;
    text-align: center;
    text-shadow: none;
    background: transparent !important;
    box-shadow: none;
}
.fc-toolbar button .fc-icon {
    font-family:iconfont;
}
.fc-icon {
    height:auto;
    line-height:1;
    overflow: visible;
}
.fc-icon-right-single-arrow:after,
.fc-icon-left-single-arrow:after {
    content:var(--icon-arrow-back);
    font-weight: 300;
    font-size: 125%;
    top: -2px;
}
.fc-icon-right-single-arrow:after {
    content:var(--icon-arrow-next);
}



@media (min-width: 1250px) {
    .fc-toolbar.fc-header-toolbar{
        margin:0 auto 20px auto;
        width:300px;
    }
}

/* popover styles */
.popover--calendar {
    padding: 40px;
    border-radius: 0;
    border:0;
    z-index: 90;
    background: #fff;
    font-family: var(--font-default-family);
    box-shadow: 0 0 12px 0 rgba(0,0,0,.35);
    margin-top:-8px;
}
.popover--calendar:after {
    content:'';
    position: absolute;
    left:50%;
    top:100%;
    margin-left:-10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 20px 0 20px;
    border-color: #ffffff transparent transparent transparent;
}
.popover--calendar .close-popover {
    position: absolute;
    right:5px;
    top:-10px;
    padding:10px;
    font-size:28px;
    color:#000;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}
.popover--calendar .popover-header{
    font-size: 20px;
    line-height: 1.25;
    text-transform: none;
    background-color:transparent;
    border-bottom: 0;
    border-radius: 0;
    padding:0;
    margin-bottom:10px;
    font-family: var(--font-light-family);
}
.popover--calendar .popover-body{
    padding:0;
}
.popover--calendar .calendar__date,
.popover--calendar .calendar__category,
.popover--calendar .calendar__desc{
    font-size: 15px;
    margin-bottom:12px;
}
.popover--calendar .calendar__category{
    font-family: var(--font-bold-family);
}
.popover--calendar .calendar__date{
    position: relative;
}
.popover--calendar .calendar__date:before{
    content:'';
    width:8px;
    height:8px;
    background: #ccd6e2;
    position: absolute;
    top:7px;
    left:-16px;
    border-radius: 50%;
}
.popover--calendar .calendar__link {
    display: block;
    font-size: 14px;
    margin-bottom:20px;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    .popover--calendar.popover-title {
        font-size: 26px;
    }
    .popover--calendar {
        max-width:450px;
    }
}
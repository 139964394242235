
.webcam-teaser__body{
    padding: 15px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    min-height: 120px;
    line-height: 1;
    position: relative;

    @media screen and (min-width:768px){
        padding: 15px 30px 20px 30px;
    }
}

.webcam-teaser__hl{
    font-size: 25px;
    line-height: calc(28/25);
    font-family: var(--font-light-family);
}

.webcam-teaser__icon{
    font-size: 25px;
    @media screen and (min-width: 768px){
        font-size: 32px;
    }
}

.webcam-teaser__link:hover,
.webcam-teaser__link:focus {
    text-decoration: underline;
}


.webcam-teaser--wide .webcam-teaser__body{
    min-height: 90px;
}

.webcam-teaser--wide .webcam-teaser__link{
    text-align: right;
}
.ad-teaser .ad-teaser__lbl{
    font-size: 12px;
    padding: 6px 15px;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    background:rgba(255,255,255,0.75)
}
.ad-teaser__logo{
    display: block;
    position: absolute;
    top: 0;
    right:0;
    background:rgba(255,255,255,0.75)
}
.ad-teaser__logo-left{
    position: absolute;
    left:0;
    top: 0;
    padding:12px 24px;
    background:rgba(255,255,255,0.75);
}


@media screen and (max-width: 767px){

    .ad-teaser__logo-left{
        padding: 6px 15px;
    }

    .ad-teaser__logo-left img{
        display: none;
    }

    .ad-teaser__logo-left-info{
        margin-top: 0;
    }
}

.ad-teaser__logo img{
    width: 55px;
    display: block;
    margin: 0 auto;
}

.ad-teaser__logo-left-info,
.ad-teaser__logo-ad-info,
.ad-teaser__logo-lbl {
    display: block;
    font-size: 13px;
    padding: 6px 10px;
    line-height: 1;
    width: 110px;
}
.ad-teaser__logo-lbl {
    padding-top: 0;
}
.ad-teaser__logo-left-info,
.ad-teaser__logo-ad-info {
    font-size: 11px;
    width: auto;
}
.ad-teaser__logo-ad-info-abs-right{
    padding: 6px 15px;
    line-height: 1;
    font-size: 11px;
    display: inline-block;
    position: absolute;
    top: 0;
    right:0;
    background:rgba(255,255,255,0.75);
}

@media screen and (max-width: 767px){
    .ad-teaser .offer-teaser__hl{
        margin-bottom: 6px;
    }
}

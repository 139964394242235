.nomination-card,
.accordion-card{
    border-radius: 0;
    border: none;
    margin-bottom: 5px;
}
.accordion-card--facilities{
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}
.nomination-card__header,
.accordion-card .card-header{
    background: #eeedec;
    border-bottom: none;
    padding: 0;

}

.accordion-card--facilities h6{
    margin-top:30px;
}
.accordion-card--facilities h6:first-of-type{
    margin-top:10px;
    @media screen and (min-width: 768px){
        margin-top:0;
    }
}

.accordion-card .card-header:first-child{
    border-radius: 0;
}

.accordion-card__collapse{
    padding: 10px 100px 10px 30px;
    display: block;
    position: relative;

    @media screen and (min-width: 768px){
        padding: 12px 40px 12px 70px;
    }
}
.accordion-card__collapse:not(.collapsed),
.accordion-card__collapse:hover{
    background: #e2e1e0;
}

.accordion-card__toggle{
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 2px;
    color: var(--color-default);

    @media screen and (min-width: 768px){
        left: 30px;
        width: 14px;
    }

}

.accordion-card__toggle:before{
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background: #766f6a;

    @media screen and (min-width: 768px){
        width: 14px;
    }
}

.accordion-card__toggle:after{
    content: '';
    position: absolute;
    height: 12px;
    width: 2px;
    background: #766f6a;
    left: 5px;
    top: -5px;
    opacity: 0;
    transition: opacity 250ms ease;

    @media screen and (min-width: 768px){
        height: 14px;
        left: 6px;
        top: -6px;
    }
}

.accordion-card .card-block {
    padding:20px 0;

    @media screen and (min-width: 768px){
        padding:40px 0;
    }
}

.accordion-card .card-header a.collapsed .accordion-card__toggle:after{
    opacity: 1;
}

.accordion-card__download{
    color: #766f6a;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 13px 15px 13px 12px;
    border-left:1px solid var(--color-lightgrey);

    @media screen and (min-width: 768px){
        padding: 16px 30px 16px 15px;
    }

    @media screen and (max-width: 767px){
        font-size: 11px;
    }
}

.accordion-card__download .icon{
    margin-right: 6px;
    font-size: 11px;
    position: relative;
    top: 1px;

    @media screen and (min-width: 768px){
        font-size: 13px;
    }
}

.accordion-card--facilities .card-block{
    padding: 0;
}
.accordion-card__hl{
    text-transform:none !important;
    font-size: 26px;
    font-family:var(--font-default-family);
    @media screen and (max-width: 767px){
        font-size: 17px;
    }
}

.accordion-card__hl--small{
    font-size: 22px;
    @media screen and (max-width: 767px){
        font-size: 17px;
    }
}

.nomination-card__hl{
    padding: .625rem 1.25rem;
    @media screen and (max-width: 767px){
        font-size: 14px;
    }
    @media screen and (min-width: 768px){
        font-size: calc(20px + 3 * ((100vw - 768px) / 932));
    }
}

.card-block a:not(.slope-collapse):not(.btn) {
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
}

.card-block a:not(.slope-collapse):not(.btn):hover {
    color: var(--color-primary);
    text-decoration: none;
}
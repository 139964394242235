.table table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border:0;
}
.table table > thead > tr > th,
.table table > tbody > tr > th,
.table table > tfoot > tr > th,
.table table > thead > tr > td,
.table table > tbody > tr > td,
.table table > tfoot > tr > td {
    padding:5px 6px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align:center;
    border: 0;
    border-top: 1px solid #eeedec;
    @media screen and (min-width: 768px){
        padding: 14px 26px;
    }
    @media screen and (max-width: 767px){
        width:auto !important; /* override width */
    }
}


.table table > thead > tr > th:first-child,
.table table > tbody > tr > th:first-child,
.table table > tfoot > tr > th:first-child,
.table table > thead > tr > td:first-child,
.table table > tbody > tr > td:first-child,
.table table > tfoot > tr > td:first-child {
    text-align:left;
}
.table table > thead > tr:last-child > th,
.table table > tbody > tr:last-child > th,
.table table > tfoot > tr:last-child > th,
.table table > thead > tr:last-child > td,
.table table > tbody > tr:last-child > td,
.table table > tfoot > tr:last-child > td {
    border-bottom: 1px solid #eeedec;
}
.table table > thead > tr > th {
    vertical-align: bottom;
    background: #eeedec;
    font-family: var(--font-light-family);

    @media screen and (min-width: 768px){
        font-size:1.25rem;
        letter-spacing:-0.5px;
    }
}
.pimcore_area_table .table table > thead > tr > th {
    color:#fff;
    background: var(--color-default);
    font-family: var(--font-bold-family);
}
.table table > thead > tr > th.small-th {
    vertical-align: top;
    @media screen and (min-width: 768px){
        font-size:calc(14rem/16);
        line-height:1;
        letter-spacing:0;

        padding: 20px 26px 15px;
    }
}
.table table > caption + thead > tr:first-child > th,
.table table > colgroup + thead > tr:first-child > th,
.table table > thead:first-child > tr:first-child > th,
.table table > caption + thead > tr:first-child > td,
.table table > colgroup + thead > tr:first-child > td,
.table table > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.table-bordered {
    border: 1px solid #eeedec;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #eeedec;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    /*border-bottom-width: 2px;*/
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #eeedec;
}
.table-hover > tbody > tr:hover {
    background-color: #f5f5f5;
}
table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}
/*.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}*/
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive table {
        margin-bottom: 0;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
        white-space: nowrap;
    }
    .table-responsive > .table-bordered {
        border: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child {
        border-left: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child {
        border-right: 0;
    }
    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th,
    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}


.table.weathertable {
    border-bottom: 1px solid #e9ecef;
}
.table.weathertable > thead > tr > th{
    font-family: var(--font-light-family);
    font-size: 15px;
    color:var(--color-lightgrey);
    text-align: center;
    vertical-align: top;
    border-top: 0;
    border-bottom: 1px solid #e9ecef;
}
.table.weathertable > tbody > tr > th{
    font-family: var(--font-light-family);
    font-size: 1rem;
}

.table.weathertable > tbody > tr > th,
.table.weathertable > tfoot > tr > th,
.table.weathertable > thead > tr > th,
.table.weathertable > tbody > tr > td,
.table.weathertable > tfoot > tr > td {
    padding: 7px 10px;

    @media screen and (min-width: 768px){
        padding: 7px 0;
    }
}
/* headerimg */
.content-block-small.demi-infrastructure-detail-header {
    position: relative;
    @media screen and (min-width: 768px){
        margin-left: 40px;
        margin-right: 40px;
    }
}
.demi-infrastructure-detail-header-img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 5px rgba(0,0,0,.4), inset 2px 2px 3px rgba(0,0,0,.16);
    z-index: 1;
}

@media screen and (min-width: 768px){
    .demi-infrastructure-overview-content-block .filter-categories form button {
        margin-top:1.75rem;
    }

    .demi-infrastructure-overview-content-block .demi-intrastructure-show-map {
        margin-bottom:1rem;
    }
}

.demi-infrastructure-paging:first-of-type{
    margin-bottom:20px;
    @media screen and (min-width: 768px){
        margin-bottom:40px;
    }
}
.demi-infrastructure-paging:last-of-type{
    margin-top:20px;
    @media screen and (min-width: 768px){
        margin-top:40px;
    }
}

#demi-infrastructure-content {
    margin-bottom:20px;
    @media screen and (min-width: 768px){
        margin-bottom:40px;
    }
}


@media screen and (min-width: 768px){
    .demi-infrastructure-line-teaser {
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
    }
    .demi-infrastructure-line-teaser .teaser__badge {
        left:0;
        transform: translateY(0) translateX(0);
    }
    .demi-infrastructure-line-teaser .teaser__body {
        padding-left:0;
        padding-top:10px;
        box-shadow: none;
    }
}

@media screen and (max-width: 767px){
    .demi-infrastructure-line-teaser .teaser__body {
        text-align: center;
    }
}

.demi-infrastructure-opening-badge{
    display: inline-block;
    color:#fff;
    background: #898482;
    font-size: 14px;
    line-height: 1;
    padding: 4px 15px;
    text-align:center;
    @media screen and (min-width: 768px){
        min-width: 135px;
        position:relative;
        top:-15px;
    }
}
.demi-infrastructure-opening-badge--open {
    background: #78bb32;
}
.demi-infrastructure-opening-badge--closed {
    background: #c2193d;
}


.demi-infrastructure-detail-content-block .slick-dots {
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
}
.demi-infrastructure-detail-content-block .slick-arrow {
    display: none !important;
}

/* content
.demi-infrastructure-overview-content-block--filter {
    margin-top:0 !important;
}*/

.wysiwyg .demi-infrastructure-detail-list {
    margin-top:20px;
}
.wysiwyg .demi-infrastructure-detail-list > li {
    position: relative;
    padding-left: 20px;
    text-transform: uppercase;
    font-size: 13px;
}
.wysiwyg ul.demi-infrastructure-detail-list>li:before {
    width: auto;
    height: auto;
    border-radius: 0;
    background: transparent;
    content: var(--icon-arrow-next);
    font-family: iconfont;
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 10px;
}
.demi-infrastructure-detail-content-block--video .container,
.demi-infrastructure-detail-content-block--gallery .container,
.demi-infrastructure-detail-content-block--times .container,
.demi-infrastructure-detail-content-block--prices .container {
    margin:0;
    padding:0;
}


/* filters */
.demi-infrastructure-sidebar__title {
    font-size:1.1rem;
}
.demi-intrastructure-show-map {
    margin-top:5px;
}
.demi-intrastructure-show-map + .demi-infrastructure-sidebar__hl {
    margin-top:20px;
}
.demi-infrastructure-sidebar__hl {
    font-size:1.1rem;
    font-family: var(--font-light-family);
    @media screen and (min-width: 768px){
        font-size:1.5rem;
    }
}
.demi-infrastructure-sidebar__body {
    padding:10px;
}
.demi-infrastructure-sidebar__box {
    padding:10px 0;
    position: relative;
    background-color:transparent;
    border:0;
    border-bottom: 1px solid rgba(0,0,0,.2);
    border-radius: 0;
}
.demi-infrastructure-sidebar__head {
    cursor: pointer;
}
.demi-infrastructure-sidebar__collapse-icon {
    position: relative;
    top:2px;
    transform: rotate(45deg);
}
.collapsed .demi-infrastructure-sidebar__collapse-icon {
    transform: rotate(0deg);
}

.demi-infrastructure-sidebar__filters .form-group {
    margin:20px 0;
}
.demi-infrastructure-sidebar .btn {
    margin:20px 0;
    display: block;
    width: 100%;
}

.demi-infrastructure-sidebar .pagination__sort-button {
    font-size: 1.1rem;
    font-family: var(--font-default-family);
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 1px;
}

.demi-infrastructure-sidebar .btn.pagination__sort-button:after {
    transform: translateY(-4px);
}

/* filter */
.quick-filter-bar {
    margin-bottom:20px;
    padding-left:1.25rem;
}
.quick-filter {
    position: relative;
    display: block;
    background: none;
    border: none;
    text-align: left;
    font-size: 13px;
    padding: 4px 10px 4px 20px;
    overflow: hidden;
}
.quick-filter:before {
    font-size:16px;
    font-family: iconfont;
    content:var(--icon-cross);
    position: absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
}


/*markers*/
.google-map__marker-icon {
    color:var(--color-primary);
    width: 48px;
    height:60px;
    font-size:60px;
}

/*Logo*/
.demi-infrastructure-detail-logo {
    width: 100%;
    height: 100%;
    padding: 30px 80px;
    @media screen and (min-width: 768px){
        height: auto;
        margin: 15px auto 25px;
        display: flex;
        justify-content: center;
        padding: 20px 60px;
    }
    @media screen and (min-width: 1200px){
        padding: 20px 100px;
    }
}
.demi-infrastructure-detail-logo > img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
}

.demi-infrastructure-sidebar__datepicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .form-group {
        margin: 0;
    }
    & .form-group .form-group-item {
        @media screen and (min-width: 768px) {
            padding: 10px 12px;
        }
    }
    & .form-group__icon {
        @media screen and (min-width: 768px) {
            font-size: 24px;
        }
    }
    & .form-group__icon--right {
        @media screen and (min-width: 768px) {
            right: 12px;
        }
    }
    & .form-control {
        @media screen and (min-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.demi-infrastructure-sidebar__datepicker-label {
    font-size: 20px;
}

.demi-infrastructure-sidebar__select {
    border-radius: 4px;
    padding: 10px 12px;
    height: auto !important;
    min-width: 88px;
    appearance: none;
}

/* AL-Digital optimizations */
.al-digital {
    & .demi-infrastructure-paging {
        font-size: 14px;
        font-weight: 700;
    }
    & .demi-infrastructure-paging--top {
        border-bottom: 1px solid #C1BFB5;
    }
    & .demi-infrastructure-paging--bottom {
        border-top: 1px solid #C1BFB5;
        padding-top: 10px;
    }
}

.demi-infrastructure-sidebar__input-placeholder::placeholder {
    color: var(--color-lightgrey);
}
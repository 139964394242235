.presse-contact-box{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  padding: 25px;


  @media screen and (min-width: 768px){
    padding: 50px;
    height: 100%;
  }
}

.presse-contact-box__hl{
  text-transform: capitalize;
}

.presse-contact-box .btn{
  margin-right: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px){
    .presse-contact-box__spacer {
        padding-left: 65px;
    }
}

.presse-contact-box__buttons{
  margin-top: 30px;

  @media screen and (min-width: 768px){
    margin-top: 70px;
  }
}

.presse-contact-box__docs{
  margin-top: 15px;

  @media screen and (min-width: 768px){
    margin-top: 50px;
  }
}

.presse-contact-box__sub-hl{
  font-size: 22px;

  font-family: var(--font-light-family);
  margin-bottom: 7px;
  @media screen and (min-width: 768px){
    font-size: 28px;
  }
}
.contentfry-teaser {
    position: relative;

    @media screen and (min-width:768px){
        font-size:16px;
    }
}


.contentfry-teaser__overlay {
    position: absolute;
    top: calc(15rem/16);
    left: calc(15rem/16);
    right: calc(15rem/16);
    text-shadow: 0 0 10px rgba(0,0,0,.55);
    z-index: 10;
    /*padding: 0 calc(15rem/16);*/
}
.contenfry-teaser__author-img {
    width: calc(25rem/16);
    height: calc(25rem/16);
    border-radius: 100%;
}
.contentfry-teaser__author-name {
    color: white;
    padding-right: calc(5rem/16);
    font-family: var(--font-light-family);
    font-size: calc(14rem/16);
    word-break: break-word;
}
.contentfry-teaser__sm-type {
    text-align: right;
    color: #fff;
}

.contentfry-teaser__body {
    padding: 15px 10px 10px 10px;
    text-align: center;
    font-size: calc(14rem/16);

    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    @media screen and (min-width:768px){
        padding: 25px 20px 20px 20px;
        position: relative;
        bottom:auto;
        left:auto;
    }
}
.contentfry-teaser__body-description {
    margin:15px auto 20px auto;
}
.contentfry-teaser__body-button {
    white-space: normal;

    @media screen and (max-width:767px){
        font-size:12px;
        padding: 12px 10px;
    }
}
.fullscreen-teaser {
    width: 100vw;
    height: 100%;
    display: flex;
    @media (max-width: 991px) {
        flex-direction: column;
        height: 100%;
    }
}
.fullscreen-teaser__item {
    flex: 1;
    position: relative;
    overflow: hidden;
}
.fullscreen-teaser__item::before {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateY(100%);
    transition: transform 500ms ease;
}
.fullscreen-teaser__item::after {
    content: '';
    position: absolute;
    inset: 0 0 0 0;
    background-color: black;
    opacity: .15;
    transition: opacity 500ms ease;
}
.fullscreen-teaser__item:hover::before {
    transform: translateY(0);
}
.fullscreen-teaser__item:hover::after {
    opacity: 0;
}
.fullscreen-teaser__item--skiresort::before {
    background: linear-gradient(to top, var(--color-lenzerheide), var(--color-arosa));
}
.fullscreen-teaser__item--arosa::before {
    background: #005D9E;
}
.fullscreen-teaser__item--lenzerheide::before {
    background: #F2213B;
}
.fullscreen-teaser__item--bikekingdom::before {
    background:#131a1c;
}
.fullscreen-teaser__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fullscreen-teaser__content {
    filter: brightness(10);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 440px);
    z-index: 1;
    transition: transform 500ms ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 991px) {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.fullscreen-teaser__item:hover .fullscreen-teaser__content {
    transform: translate(-50%, 340px);
    @media (max-width: 991px) {
        top: 50%;
        transform: translate(-50%, -75%);
    }
}
.fullscreen-teaser__logo {
    max-width: 330px;
    max-height: 68px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
        max-width: 160px;
        max-height: 33px;
    }
    @media screen and (max-width: 991px) {
        max-width: 240px;
        max-height: 46px;
    }
}
.fullscreen-teaser__caption {
    text-align: center;
    font-size: 38px;
    line-height: calc(55/38);
    margin-top: 20px;
    font-family: "Larken-Bld", serif;
    @media (max-width: 767px) {
        font-size: 25px;
        line-height: calc(36/25);
        margin-top: 12px;
    }
}
.fullscreen-teaser__text {
    width: 440px;
    font-size: 14px;
    line-height: calc(22/14);
    text-align: center;
    margin-top: 24px;
    transform: translateY(400px);
    transition: transform 500ms ease, opacity 300ms ease;
    opacity: 0;
    @media screen and (min-width: 992px) and (max-width:1299px){
        width: 330px;
    }
    @media (max-width: 991px) {
        display: none;
    }
}
.fullscreen-teaser__item:hover .fullscreen-teaser__text {
    transform: translateY(0);
    opacity: 1;
}
.fullscreen-teaser__icon {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    margin: 0 auto;
    transform: translateY(140px);
    transition: transform 500ms ease;
    width: 48px;
    height: 48px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
        bottom: 25px;
    }
}
.fullscreen-teaser__item:hover .fullscreen-teaser__icon {
    transform: translateY(0);
}

/*Small design*/
.fullscreen-teaser__small-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.fullscreen-teaser__content.fullscreen-teaser__is-small {
    transform: translate(-50%, calc(140rem /16));
    @media (max-width: 991px) {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.fullscreen-teaser__item:hover .fullscreen-teaser__content.fullscreen-teaser__is-small {
    transform: translate(-50%,  calc(40rem /16));
    @media (max-width: 991px) {
        top: 50%;
        transform: translate(-50%, -75%);
    }
}
.fullscreen-teaser__item:hover .fullscreen-teaser__icon.fullscreen-teaser__is-small {
    transform: translateY(calc(40rem /16));
    @media (max-width: 991px) {
        transform: translateY(0);
    }
}
.fullscreen-teaser__item--bikekingdom .fullscreen-teaser__logo {
    max-height: calc(80rem/16);
    @media screen and (min-width: 768px){
        max-height: calc(100rem/16);
    }
}
.js-slider:not(.slick-slider)>div+div,
.js-ad-slider:not(.slick-slider)>div+div,
.js-content-slider-big:not(.slick-slider) .content-slider__item+.content-slider__item{
    display: none;
}

.slick-slider .slick-list, .slick-slider .slick-track {
    transform: none;
    will-change: transform;
}

.slider__arrow {
    background: transparent;
    color: #fff;
    position: relative;
    font-size: 17px;
    padding: 12px;
}
.slider__arrow.slider__arrow--dark {
    color:#000;
}
.slider__arrow.slider__arrow--dark.slick-prev:after {
    background: #000;
}


.hide-arrows .slider__arrow{
    display: none!important;
}

.btn.slider__arrow:focus{
    outline:none;
    color: var(--color-primary);
}

.btn.slider__arrow.slider__arrow--img-text:focus{
    outline:none;
    color: #111;
}


.slider-nav-custom{
    width: 114px;
    height: 114px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 30px 0;
}
.slider-nav-custom--ratings {
    bottom: -80px;
    z-index:20;
}

.slider__arrow--ratings,
.slider__arrow--img-text{
    position: absolute;
    background: var(--color-primary);
    top: calc(250rem/16);
    border-radius: 50%;
    color: white;
    width: calc(30rem/16);
    height: calc(30rem/16);
    padding: calc(3rem/16);
}

.slider__arrow--img-text .icon{
    font-size: calc(12rem/16);
}

.wysiwyg-img-tab-slider__arrow{
    position: absolute;
    background: var(--color-primary);
    top: calc(400rem/16);
    border-radius: 50%;
    color: white;
    width: calc(30rem/16);
    height: calc(30rem/16);
    padding: calc(3rem/16);
}

.wysiwyg-img-tab-slider__arrow .icon{
    font-size: calc(12rem/16);
}

.wysiwyg-img-tab-slider__arrow.slick-prev{
    left: 0;
    z-index: 2;
    margin-left: calc(-10rem/16);
}
.wysiwyg-img-tab-slider__arrow.slick-next{
    right: 0;
    margin-right: calc(-10rem/16);
}

	@media screen and (min-width: 768px){
        .wysiwyg-img-tab-slider__arrow.slick-prev{
            margin-left: calc(-20rem/16);
        }
        .wysiwyg-img-tab-slider__arrow.slick-next{
            margin-right: calc(-20rem/16);
        }
        .wysiwyg-img-tab-slider__arrow{
            top: calc(200rem/16);
            width: calc(46rem/16);
            height: calc(46rem/16);
            padding: calc(12rem/16);
        }
        .wysiwyg-img-tab-slider__arrow .icon{
            font-size: calc(18rem/16);
        }

        .slider__arrow--img-text{
            padding: calc(12rem/16);
            top: calc(250rem/16);
            width: calc(46rem/16);
            height: calc(46rem/16);
        }

        .slider__arrow--img-text .icon{
            font-size: calc(18rem/16);
        }

    }


.wysiwyg-img-tab-slider__arrow:focus .icon{
    outline:none;
    color: #111;
}

.wysiwyg-img-tab-slider .slick-slide{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin: calc(2rem/16);
}

.slider__arrow--img-text.slick-prev{
    left: 0;
    z-index: 2;
    margin-left: calc(5rem/16);
}
.slider__arrow--img-text.slick-next{
    right: 0;
    margin-right: calc(5rem/16);
}


@media screen and (min-width: 1360px){
    .slider__arrow--img-text.slick-next{
        margin-right: calc(20rem/16);
    }

    .slider__arrow--img-text.slick-prev{
        margin-left: calc(20rem/16);
    }
}



.slider__arrow--ratings{
    bottom:-46px;
}


.slider__arrow--ratings.slick-prev:after,
.slider__arrow--img-text.slick-prev:after{
    background: var(--color-default);
}

/* Slick dots */

.slick-dots{
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin:0;
}

.slick-dots>li{
    display: inline-block;
    margin: 0 10px;
    width: 10px;
    height: 10px;
}

.slick-dots>li button{
    background: none;
    box-shadow: none;
    border: none;
    color: transparent;
    line-height: 0;
    background: var(--color-lightgrey)!important;
    width: 14px;
    height: 14px;
    padding: 0;
    border-radius: 50%;
    position: relative;
    top:-3px;
    @media screen and (min-width:768px){
        background: #fff;
    }
}

.slick-dots>li.slick-active button{
    margin: 0 -3px;
    background: var(--color-primary)!important;
    width: 20px;
    height: 20px;
    border:3px solid var(--color-lightgrey);
    @media screen and (min-width:768px){
        border:3px solid #fff;
    }
}

.hide-dots .slick-dots{
    display: none!important; /* Overwrite Slick inline style */
}
.steps {
    position: relative;
    max-width:740px;
    margin: 0 auto 50px auto;
}
.steps ul {
    position: relative;
    margin:0;
    padding:0;
    font-size: 0px;
}
.steps ul>li.list-inline-item{
    width:33.3333333%;
    margin:0;
    padding:0;
    text-align: center;
    position: relative;
}
.steps ul>li.list-inline-item:first-child{
    text-align: left;
}
.steps ul>li.list-inline-item:last-child {
    text-align: right;
}
.steps ul:before {
    content:'';
    position: absolute;
    left:10px;
    right:10px;
    top:40px;
    width:auto;
    height:2px;
    background:#eeedeb;
    @media screen and (max-width: 767px){
        top:30px;
    }
}
.steps li.steps__finished + li:before{
    content:'';
    position: absolute;
    right:50%;
    width: 125%;
    top:40px;
    height:2px;
    background:var(--color-primary);
    @media screen and (max-width: 767px){
        top:30px;
    }
}
.steps li.steps__finished + li:last-child:before{
    left:auto;
    right:10px;
    width: 150%;
}

.steps__item {
    text-align: center;
    position: relative;
    display: inline-block;
}



.steps__nr {
    position: relative;
    display: block;
    width:80px;
    height:80px;
    line-height:68px;
    font-size:50px;
    border:6px solid #eeedeb;
    color:#bbb7b4;
    font-family: var(--font-bold-family);
    border-radius: 50%;
    background:#fff;
    z-index:5;
    @media screen and (max-width: 767px){
        width:60px;
        height:60px;
        line-height:50px;
        font-size:28px;
        border:5px solid #eeedeb;
    }
}
.steps__nr .icon {
    position: relative;
    top:-2px;
    font-size:26px;
    @media screen and (max-width: 767px){
        font-size:22px;
        top:2px;
    }
}

.steps__txt {
    display: block;
    text-align: center;
    font-size: 20px;
    width:200px;
    margin-left:-100px;
    position: absolute;
    bottom:-35px;
    left:50%;
    font-family: var(--font-light-family);
    @media screen and (max-width: 767px){
        display: none;
    }
}


.steps__finished,
.steps__active {
    color:var(--color-primary);
}
.steps__finished .steps__nr,
.steps__active .steps__nr {
    color:var(--color-primary);
    border-color:var(--color-primary);
}
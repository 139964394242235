.job-detail__text {
    line-height: calc(26/18);
}
.job-detail__text-item {
    margin-bottom: calc(30rem/16);
    padding-right: calc(110rem/16);
}
.job-detail__infos {
    margin-bottom: calc(50rem/16);
    @media screen and (min-width: 768px) {
        display: flex;
        column-gap: calc(20rem/16);
    }
}
.job-detail__info-item {
    flex: 1 1 20%;
}
.job-detail__btn {
    min-width: calc(200rem/16);
    min-height: calc(60rem/16);
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: calc(9rem/16);
    font-size: calc(18rem/16);
    padding: 0 calc(20rem/16);
    margin-top: calc(7rem/16);
}
.job-detail__list {
    padding-left: calc(14rem/16);
    display: flex;
    flex-direction: column;
    row-gap: calc(14rem/16);
    line-height: calc(26rem/16);
}
.job-detail__list > li {
    position: relative;
}
.job-detail__list > li::before {
    content: "-";
    position: absolute;
    left: calc(-14rem/16);
    top: 0;
}
.job-detail__contact {
    border-radius: 0;
    padding: calc(22rem/16);
    margin-top: calc(16rem/16);
    box-shadow: 0 0 calc(16rem/16) rgba(0,0,0,.2);
    @media screen and (min-width: 768px) {
        column-count: 1;
        margin-top: 0;
    }
}
.job-detail__contact-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(4rem/16) 0;
    break-inside: avoid;
    margin-top: calc(8rem/16);
}
.job-detail__contact-item > span {
    overflow: hidden;
    text-overflow: ellipsis;
}
.job-detail__contact-item > span:first-child {
    font-weight: 700;
}
.job-detail__contact-item > span:last-child {

}
.job-detail__download-btn {
    margin-top: calc(8rem/16);
}
.job-detail__contact-title {
    column-span: all;
    font-family: Circular-Bld;
    font-size: calc(22rem/16);
}
.job-detail__contact-container {
    margin-top: calc(50rem/16);
}
.job-detail__back-link {
    font-family: Circular-Bld;
    position: absolute;
    top: calc(45rem/16);
    left: calc(40rem/16);
    display: flex;
    align-items: center;
    column-gap: calc(8rem/16);
    @media screen and (max-width: 767px) {
        position: relative;
        top: calc(30rem/16);
        left: calc(15rem/16);
    }
}
.job-detail__back-link-icon {
    font-size: calc(14rem/16);
}

.job-detail__upload-delete {
    position: absolute;
    right: calc(20rem/16);
    top: calc(20rem/16);
    cursor: pointer;
    font-size: 30px;
    background-color: white;
}
.embed-responsive-9by5::before{
    padding-top: 55%;
}

.embed-responsive-2by1::before{
    padding-top: 50%;
}
.embed-responsive-2by3::before{
    padding-top: 150%;
}

.embed-responsive-11by6::before{
    padding-top: 54.5454%;
}

.embed-responsive-11by7::before{
    padding-top: 63.6363%;
}

.embed-responsive-14by8::before{
    padding-top: 57.7%;
}

.embed-responsive-webcammap::before{
    padding-top:45.3125%;
    @media screen and (min-width:768px){
        padding-top: 20%;
    }
}
.embed-responsive-regionmap::before{
    padding-top:30.89285714285714%;
}
.embed-responsive-map::before{
    padding-top:125%;
    @media screen and (min-width:768px){
        padding-top: 60%;
    }
}

.embed-responsive-23by8::before,
.embed-responsive-23by8-small::before{
    padding-top: 34.375%;  /* Nach Bild anepasst */
}

.embed-responsive-17by3::before{
    padding-top: 17.4242%; /* Nach Bild anepasst */
}

.embed-responsive-14by5::before{
    padding-top: 35.6%; /* Nach Bild anepasst */
}

.embed-responsive-5by3::before{
    padding-top: 60.6060%; /* Nach Bild anepasst */
}

.embed-responsive-17by5::before{
    padding-top: 29.2857%; /* Nach Bild anepasst */
}

.embed-responsive-4by1::before{
    padding-top: 25%;
}

.embed-responsive-25by12::before{
    padding-top: 48%;
}

.embed-responsive-3by2::before{
    padding-top: 66.66%;
}
.embed-responsive-3by1:before {
    padding-top: 33.33333%;
}
.embed-responsive--img-teaser-fullwidth {
    padding-top:75%;
    @media screen and (min-width:768px){
        padding-top: 25%;
    }
}

.embed-responsive--fullwidth-teaser {
    padding-top: 100%;
    @media screen and (min-width:768px){
        padding-top: 25%;
    }
}
.event-teaser__headline {
    line-height:18px;
    font-size: 16px;
    font-family: var(--font-default);
}


@media screen and (min-width: 768px){
    .event-teaser__headline {
        line-height:30px;
        font-size: 22px;
    }
}
@media screen and (min-width: 768px){
    .teaser-grid .teaser__desc--min-h,
    .teaser-grid .event-teaser__headline-min-height {
        min-height:65px;
    }
}
.event-teaser__link-text {
    margin-top: -22px;
    opacity: 0;
    transition: all 150ms ease;
    @media screen and (max-width: 767px){
        display:none;
    }
}

.event-teaser__portal-text {
    transition: all 150ms ease;
}

.event-teaser.is-lenzerheide:hover .event-teaser__link-text,
.event-teaser.is-lenzerheide:hover .event-teaser__portal-text {
    color: #8a0e18;
}

.event-teaser.is-arosa:hover .event-teaser__link-text,
.event-teaser.is-arosa:hover .event-teaser__portal-text {
    color: #012248;
}


.event-teaser__top{
    padding-top: 0;
}

.event-teaser{
    height: 100%;
    text-align: center;
    position: relative;
}

.event-teaser__weekdays{
    z-index: 2;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 8px;
    transform: translate(-50%, -50%);
    left: 50%;
}

.event-teaser__weekdays-item{
    margin-right: calc(9rem/16);
    color: #C1BFB5;
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    font-size: calc(14rem/16);
    font-family: var(--font-default-family);
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-teaser__weekdays-item:last-child{
    margin-right: 0;
}


.event-teaser__weekdays-item.active-day{
    background-color: #F2F2F1;
    color: var(--color-default);
}

@media screen and (min-width: 768px) and (max-width: 1400px){
    .event-teaser__weekdays-item{
        margin-right: calc(4rem/16);
        width: calc(25rem/16);
        height: calc(25rem/16);
        font-size: calc(12rem/16);
    }
}

@media screen and (max-width: 767px){
    .event-teaser__weekdays-item{
        margin-right: calc(2rem/16);
        width: calc(20rem/16);
        height: calc(20rem/16);
        font-size: calc(8rem/16);
    }
}

.event-teaser__top-town {
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    font-weight: var(--font-default-weight);
    text-transform: uppercase;
    margin-bottom: 12px;
}

.event-teaser__label{
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-primary);
    color: white;
    font-size: calc(12rem/16);
    padding: calc(5rem/16) calc(14rem/16);
    z-index: 2;
}
@media screen and (max-width: 1199px) {
    .event-teaser__reservation-btn {
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .event-teaser__reservation-btn .btn__small-info-text {
        margin-left: 0;
    }
}
.nav-tabs.nav-tabs--default {
    border-bottom: 1px solid var(--color-default);
}
.nav-tabs.nav-tabs--default .nav-link {
    font-size:1rem;
    font-family: var(--font-bold-family);
    color:var(--color-lightgrey);
    border:0;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    padding: .25rem .5rem;

    @media screen and (min-width:768px){
        font-size: calc(18rem / 16);
        padding: .25rem 1.5rem;
    }
}
.nav-tabs.nav-tabs--default .nav-link:focus, .nav-tabs .nav-link:hover,
.nav-tabs.nav-tabs--default .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--color-default);
    border-bottom: calc(4rem/16) solid var(--color-default);
}

.nav-tabs .nav-link{
    border:none;
}
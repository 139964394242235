.teaser-grid.teaser-grid{
    margin:10px -5px;

    @media screen and (min-width: 768px) {
        margin: 24px -12px;
    }
}
@media screen and (max-width: 767px) {
    .row--teaser-grid__col:last-child {
        flex: 0 0 100%;
        max-width: 100%; 
    }
}
.teaser-grid__item{
    margin-bottom: 10px;
    padding:0 5px;
    width: 50%;

    @media screen and (min-width: 768px) {
        padding:0 12px;
        margin-bottom: 24px;
        //bottom: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        width:33.3333333%;

    }
    @media screen and (min-width: 992px) {
        width:25%;
    }
}
.google-map {
    position: relative;
}

/*controls*/
.google-map__controls {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 44px;
}
.google-map__controls>.btn.btn:last-child, .google-map__controls>.btn.btn:first-child {
    border-radius: 0;
}
.google-map__control {
    background: #fff;
    color: #000;
    font-size: 24px;
    padding: 9px;
    height: 44px;
}
.google-map__controls .google-map__control + .google-map__control {
    margin-top: 4px;
}
.google-map__control:hover {
    background: var(--color-default);
    color: #fff;
}

/* active marker */
.google-map__marker-wrapper .google-map__marker > .icon {
    opacity: 0.9;
}
.google-map__marker-wrapper.is-active .google-map__marker > .icon {
    opacity: 1;
    color:#000 !important;
}

/* infobox */
.google-map__infobox {
    font-size:1.25rem;
    line-height: 1.25;
    background: #fff;
    padding:0;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.4);
}
.google-map__infobox .google-map__infobox {
    padding:20px;
    box-shadow: none;
}
.google-map__infobox>img {
    position: absolute !important;
    top:5px;
    right:5px;
    z-index:5;
}
.google-map__infobox--demi-infra.google-map__infobox--demi-infra {
    padding: 0;
}
.google-map__infobox-img>img {
    position: relative;
    top:auto;
    right:auto;
    width:100%;
    height:auto;
}
.google-map__infobox--demi-infra .google-map__infobox-body {
    padding:10px 12px;
}
.google-map__infobox a {
    font-size:1rem;
    color:var(--color-primary);
    text-decoration: none;
}
.google-map__infobox a:hover,
.google-map__infobox a:focus {
    text-decoration: underline;
}

.sponsoring__list {
    width:100%;
    position: relative;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 768px){
        white-space: nowrap;
        display: table;
        table-layout: fixed;
    }
}

@media screen and (min-width: 768px){
    .sponsoring__list li {
        display: table-cell;
        padding: 0 15px;
    }
}

@media screen and (max-width: 767px){
    .sponsoring__list li {
        margin: 0 0 10px 0 !important;
        padding: 0 5px;
        width: calc(50% - 10px);
    }
}
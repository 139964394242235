
.no-margin.no-margin { margin: 0; }
.mb0.mb0 { margin-bottom: 0 }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb25 { margin-bottom: 25px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 20px; }
.mb60 { margin-bottom: 30px; }

.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt25 { margin-top: 25px; }
.mt30 { margin-top: 30px; }
.mt35 { margin-top: 35px; }
.mt40 { margin-top: 25px; }
.mt60 { margin-top: 60px; }
.mt70 { margin-top: 35px; }

.mr-20{margin-right: 20px}
.mr-40{margin-right: 40px}

.no-padding { padding: 0; }

@media screen and (min-width: 768px){
    .mb60 { margin-bottom: 60px; }
    .mb40 { margin-bottom: 40px; }
    .mt40 { margin-top: 40px; }
    .mt70 { margin-top: 70px; }
}


/*.bg-primary {background: var(--color-primary); color: #fff;}
.bg-arosa {background: var(--color-arosa); color: #fff;}
.bg-lenzerheide {background: var(--color-lenzerheide); color: #fff;}*/

.bg-portal {background: var(--color-primary); color: #fff !important;}
.is-lenzerheide .bg-portal {background: var(--color-lenzerheide); color: #fff !important;}
.is-arosa .bg-portal {background: var(--color-arosa); color: #fff !important;}
.is-arosa .color-portal {color: var(--color-arosa); border-color: var(--color-arosa);}
.is-lenzerheide .color-portal {color: var(--color-lenzerheide); border-color: var(--color-lenzerheide);}

.bg-yellow{
    background: #fec900;
}
/*text colors*/
.text-default { color: var(--color-default) !important;}
.text-primary { color: var(--color-primary) !important;}

.portal-default .text-primary { color: var(--color-anthrazit) !important;}

.text-portal{ color: #000 !important;}

.portal-lenzerheide .navbar-top__item--with-border>a.is-active,
.portal-lenzerheide ~ .sticky-console .is-active,
.portal-lenzerheide .region-switch .region-switch__dropdown-icon,
.portal-lenzerheide .region-switch .navbar-top__item__icon,
.portal-lenzerheide .regionchanger__poi-icon,
.is-lenzerheide .text-portal,
.portal-lenzerheide .text-portal { color: var(--color-lenzerheide) !important;}


.portal-arosa .navbar-top__item--with-border>a.is-active,
.portal-arosa ~ .sticky-console .is-active,
.portal-arosa .region-switch .region-switch__dropdown-icon,
.portal-arosa .region-switch .navbar-top__item__icon,
.portal-arosa .regionchanger__poi-icon,
.is-arosa .text-portal,
.portal-arosa .text-portal { color: var(--color-arosa) !important;}

.text-white.text-white { color: #fff !important;}
.text-black.text-black { color: #000 !important;}
.text-lightgrey.text-lightgrey { color: var(--color-lightgrey) !important;}

.text-arosa{ color: var(--color-arosa); }
.text-lenzerheide{ color: var(--color-lenzerheide); }

.text-grey{
    color: #766F6A!important;
}
.text-underline{
    text-decoration: underline;
}
.text-transform-none{
    text-transform: none !important;
}

.no-bg {
    background: transparent !important;
}

.bg-white{
    background: #fff;
}

/*helper*/
.cursor-default {cursor: default;}
.isClickable, .isCursor {cursor: pointer;}
.d-ib {display: inline-block;}
.d-b {display: block;}
.hidden {display: none !important;}
.v-hidden {visibility: hidden !important;}
.pos-rel { position:relative; }

a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 99999;
}
#table-of-content {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
}
.sr-only-focusable:focus,
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    color: #fff;
    font-size: 14px;
    padding: 10px;
}
.sr-only-focusable:focus {
    background:#000;
}

.relative {
    position: relative;
}

.w-auto {
    width: auto;
}

.shadow-box {
    padding:15px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
    margin-bottom: 20px;
    background:#fff;

    @media screen and (min-width:768px){
        padding:40px;
    }
}

.small--letter-spacing{
    letter-spacing: 0.8px;
    font-size: 11px;
}

.profile-divider{
    text-align: center;
    border-bottom: 1px solid var(--color-default);
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1;
    padding-bottom: 5px;
    margin: 20px 0;
}

.download-link-wysiwyg{
    position: relative;
    padding-left: 18px;
}

.download-link-wysiwyg:before{
    content: var(--icon-arrow-down);
    font-family: iconfont;
    position: absolute;
    left:0;
    top: -1px;
    font-size: 14px;
}

.portal-switch{
    @media screen and (max-width: 767px){
        margin-bottom: 20px;
    }
}

.portal-switch .btn-arosa,
.portal-switch .btn-lenzerheide{
    background: transparent;
    color: var(--color-default);
}

@media screen and (max-width: 767px){
    .js-toc{
        display: none;
    }
}

.modal {
    z-index: 2500;
}

.card.card--nostyle {
    border-radius: 0;
    border:0;
    margin:0;
    padding:0;
    box-shadow: none;
}

.social-addthis__hl{
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
}

/* anchor sticky header bugfix */
.pimcore_area_anchor a:target {
    display: block;
    position: relative;
    top:-80px;
    pointer-events: none;
}

.is-sticky {
    position: sticky;
    top: 104px;
    z-index: 500;
    @media screen and (max-width: 767px) {
        top: 48px;
    }
}
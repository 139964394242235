/* ARRANGE STUFF */
.arrange {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: table;
    margin: 0;
    min-width: 100%;
    padding: 0;
    table-layout: auto;
}
.arrange-sizeFill,
.arrange-sizeFit {
    display: table-cell;
    padding: 0;
    vertical-align: top;
}
.arrange-sizeFill {
    width: 100%;
}
.arrange-sizeFill img {
    height: auto;
    max-width: 100%;
}
.arrange-sizeFit img {
    max-width: none !important;
    width: auto !important;
}
.arrange--middle .arrange-sizeFill,
.arrange--middle .arrange-sizeFit {
    vertical-align: middle;
}
.arrange--bottom .arrange-sizeFill,
.arrange--bottom .arrange-sizeFit {
    vertical-align: bottom;
}
.arrange--equal {
    table-layout: fixed;
    width: 100%; /* 1 */
}
.arrange--equal > .arrange-sizeFill,
.arrange--equal > .arrange-sizeFit {
    width: 1%;
}
.arrange--withGutter {
    margin: 0 -10px;
}

.arrange--withGutter > .arrange-sizeFit,
.arrange--withGutter > .arrange-sizeFill {
    padding: 0 10px;
}
.arrange--vmiddle .arrange-sizeFit,
.arrange--vmiddle .arrange-sizeFill {
    vertical-align: middle;
}
.arrange--vbottom .arrange-sizeFit,
.arrange--vbottom .arrange-sizeFill {
    vertical-align: bottom;
}

.arrange--vtop .arrange-sizeFit,
.arrange--vtop .arrange-sizeFill {
    vertical-align: top;
}

.arrange-width--33{
    width: 33%;
}

@media screen and (max-width: 767px){
    .arrange-block-xs .arrange-sizeFit,
    .arrange-block-xs .arrange-sizeFill{
        display: block;
    }

}


@media screen and (max-width: 767px){
    /* No Arrange xs */
    .no-arrange-xs.arrange>.arrange-sizeFill,
    .no-arrange-xs.arrange>.arrange-sizeFit{
        display: block;
    }

    /* Arrange xs */

    .arrange-xs{
        box-sizing: border-box;
        display: table !important;
        margin: 0;
        min-width: 100%;
        padding: 0;
        table-layout: auto;
    }

    .no-arrange-xs .arrange-width--33{
        width: 100%;
    }

}
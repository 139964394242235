.datepicker {
    position: relative;
}
.datepicker .form-control {
    padding-right: 40px;
}
.datepicker:before {
    pointer-events: none;
    content: var(--icon-calendar);
    font-family:iconfont;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #666;
    font-size: 21px;
}
.datepicker .form-control:focus{
    border-color: #000;
}
.datepicker .form-control{
    border: 1px solid #000;
}
.datepicker .form-control-label{
    left: 20px;
}
.datepicker .form-control:focus ~ .form-control-label{
    transform: translateY(-30px) translateX(-20px) scale(.85);
}
.ui-datepicker {
    min-width: 280px;
    background: #fff;
    padding: 0;
    z-index:9999 !important;
    box-shadow: 0 0 6px rgba(0,0,0, .2);
}
.ui-datepicker-header {
    background: var(--color-primary);
    color: #fff;
}
.ui-datepicker th {
    font-weight:300;
    font-family:var(--font-bold-family);
}
.ui-datepicker .ui-state-default {
    text-align: center;
    border-radius: 100%;
    width: 34px;
    height: 34px;
}
.ui-datepicker .ui-state-hover {
    background: var(--bg-lightgrey);
}

.ui-datepicker .ui-state-disabled{
    opacity: 0.2;
}
.ui-datepicker .ui-state-active {
    background: var(--color-primary);
    color: #fff;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    top: 0;
    bottom: 0;
    height: auto;
}
.ui-datepicker-next.ui-state-hover,
.ui-datepicker-prev.ui-state-hover {
    background: transparent;
    color: #000;
}
.ui-datepicker .ui-datepicker-prev span {
    margin-top: -9px;
}
.ui-datepicker .ui-datepicker-next span {
    margin-top: -7px;
}
.ui-datepicker .ui-datepicker-prev span:before {
    transform: rotate(180deg);
    display: block;
}
.ui-datepicker-calendar th, .ui-datepicker-calendar td {
    color: black;
}

.datepicker--event {
   display: flex;
    justify-content: center;
    margin-top: calc(15rem/16);
    margin-bottom: 0;
}

.datepicker--event .ui-datepicker{
    margin-right: 0;
    box-shadow: none;
    width: 100%;
    z-index: 1!important;
}


.datepicker--event .ui-datepicker-header {
    background-color: transparent;
    color: #C1BFB5;
    margin-bottom: calc(8rem/16);
}
.datepicker--event .ui-datepicker table {
    font-size: calc(14rem/16);
}

@media screen and (max-width: 767px){
    .ui-datepicker .ui-state-default {
        padding: 7px;
    }
}

.datepicker--event .ui-datepicker .ui-datepicker__active-day .ui-state-default {
    background-color: #F2F2F1;
}

.datepicker--event .ui-datepicker .ui-state-active {
    background: none;
    color:  #C1BFB5;
}

.datepicker--event .ui-datepicker .ui-state-hover {
    background: none;
    color:  #C1BFB5;
}

.datepicker--event .ui-datepicker-title{
    font-size: calc(14rem/16);
}

.datepicker--event  .ui-datepicker .ui-datepicker-next span,
.datepicker--event  .ui-datepicker .ui-datepicker-prev span{
    font-size: calc(10rem/16);
}

.datepicker--event .ui-datepicker .ui-state-active{
    color: #111;
}

.datepicker--event .ui-datepicker .ui-state-hover{
    color: #111;
    cursor: unset;
}

.datepicker--event  a.ui-state-hover {
    cursor: default;
}

.datepicker--event  .ui-datepicker .ui-datepicker-prev{
   top: calc(5rem/16);
}

.datepicker--event  .ui-datepicker a{
    padding: calc(8rem/16);
}

/*cursor*/


.modal.info-modal{
    border: none;
    z-index: 4000 !important;
}

.modal .info-modal-content{
    border: none;
    width:100%;
}

.info-modal-header{
    font-family: var(--font-default-family);
    padding: calc(40rem/16) calc(40rem/16) 0 calc(40rem/16);
}

.info-modal-body{
    padding: calc(12rem/16) calc(20rem/16) calc(20rem/16) calc(20rem/16);
}

.modal .info-modal-btn .icon{
   position: absolute;
    top: calc(20rem/16);
    right: calc(20rem/16);
    background-color: var(--color-primary);
    border-radius: 50%;
    font-size: calc(9rem/16);
    padding: calc(8rem/16);
    color: #fff;
}

.info-modal img{
    width: 100%;
}


.modal.info-modal .modal-dialog-centered {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    min-height:calc(100% - (.5rem * 2));
}

@media (min-width: 576px) {
    .modal.info-modal .modal-dialog-centered {
        min-height:calc(100% - (1.75rem * 2));
    }
}
.header-console{

}



/* overlays */
.header-console-overlay-wrapper {
    @media screen and (max-width:767px){
        z-index:99;
    }
}
.header-console-overlay{
    position: absolute;
    top:150px;
    left:0;
    width: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    background-color:rgba(255, 255, 255, 0.95);
    padding:2.5rem 0 1.5rem 0;
    transform:translateX(-120%);
    opacity: 0;

    @media screen and (min-width: 768px){
        transform:translateX(0) translateY(-175%);
    }

    @media screen and (max-width:767px){
        padding:4rem 0 8rem 0;
        overflow-y:scroll;
        position:fixed;
        left:0;
        right:0;
        top:0;
        bottom:50px;
    }
}

.header-console-overlay--cart .is-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.header-console-overlay.header-console-overlay--cart{
    @media screen and (min-width: 768px){
        width: calc(400rem/16);
        transform:translateX(100%) translateY(0);
        transition: all .3s linear;
        left: auto;
        right: 0;
        height: calc(100vh - 165px);
    }
    
    @media screen and (max-width: 767px){
       padding-top: 15px;
        transform: translateX(120%);
    }
}

.header-console-overlay--cart__footer{
    @media screen and (max-width: 767px){
       padding-bottom: calc(30rem/16);
    }
}

header.affix .header-console-overlay.header-console-overlay--cart{
    @media screen and (min-width: 768px){
        height: calc(100vh - 125px);
    }
}

.header-console-overlay--cart__hl{
    font-size: 25px;
    font-family: var(--font-bold-family);
}

.header-console-overlay--cart .btn-close-overlay{
   @media screen and (min-width: 768px){
      width: 45px;
      height: 45px;
       font-size: 18px;
   }
}

header.affix .header-console-overlay{
    @media screen and (min-width:768px){
        top:105px;
    }
}

.header-console-overlay.is-open {
    transform:translateX(0) translateY(0);
    opacity: 1;
}

.header-console-overlay.header-console-overlay--cart.is-open{
    transform:translateX(0) translateY(0);
    transition: all .25s linear;
    @media screen and (min-width: 768px){
        transition: all .3s linear;
    }
}

.container.container--console-overlay{
    max-width: 750px;
}
.container.container--console-overlay.facilities-wrapper{
    max-width: 900px;
}
.container.container--console-overlay-narrow{
    max-width: 400px;
}

.portal-default .header-console-overlay--ticket .btn-primary {
    background-color: #fec900;
    border-color: #fec900;
    color: #111;
}
.portal-default .header-console-overlay--ticket .btn-primary:hover {
    background-color: #d8ab00;
    border-color: #cba100;
}
.lightbox-icon{
    color: #fff;
    font-size: 20px;

    @media screen and (min-width: 768px){
        font-size: 30px;
    }
}

.teaser .lightbox-icon{
    position: absolute;
    top:20px;
    right: 20px;
}

.lightbox-btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.6);
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    transition: opacity 250ms ease;

    @media screen and (min-width:768px){
        width: 80px;
        height: 80px;
    }
}

.lightbox-hover:hover .lightbox-btn{
    opacity: 1;
}

.lightbox-caption{
    margin-bottom: 40px;
}

.lightbox-caption .btn:first-child{
    margin-right: 20px;

    @media screen and (max-width: 767px){
        margin-bottom: 15px;
        margin-right: 0;
    }
}
.lg-actions .lg-next, .lg-actions .lg-prev {
    background-color: transparent;
    color: var(--color-default);
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #000;
}
.lg-backdrop{
    background-color: #DDDBDA;
}

.lg-sub-html, .lg-toolbar {
    background-color: #dddbda;
    color: #000;
}
.lg-outer .lg-video-cont {
    max-width:60% !important;
}
.lg-close.lg-close {
    width: 81px;
    height: 81px;
    line-height: 81px;
    position: absolute;
    right: 35px;
    top: 35px;
    padding:0;
    font-size: 26px;
    background:#fff;
    color:var(--color-default);
    border-radius:50%;
    box-shadow: 0 5px 9px rgba(0,0,0,.2);
    font-family: "iconfont" !important;
}
.lg-close.lg-close:hover,
.lg-close.lg-close:focus {
    background:var(--color-default);
    color:#fff;
}
.lg-close.lg-close:after {
    font-family: "iconfont" !important;
    content:var(--icon-close);
}

@media screen and (max-width: 767px){
    .lg-close.lg-close {
        width: 40px;
        height: 40px;
        line-height: 40px;
        right: 10px;
        top: 10px;
        font-size: 16px;
    }
}

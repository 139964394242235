.teaser.console-teaser{
    margin-bottom: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    height:auto;
    @media screen and (min-width:1200px){
        margin-bottom: 24px;
    }
}

.console-teaser__body{
    background: #fff;
    text-align: center;
    padding: 12px;
    @media screen and (min-width:1200px){
        padding: 25px;
    }
}
.console__hl-special.console__hl-special {
    text-transform: none;
    font-family: var(--font-larken-bold);
    font-weight: 700;
    @media screen and (max-width:767px){
        font-size:27px;
    }
}
.portal-lenzerheide .console__hl-special.console__hl-special {
    font-family: var(--font-themix-bold);
}
.portal-arosa .console__hl-special.console__hl-special {
    font-family: marine, sans-serif;
}

.console-teaser__body h2{
    text-transform: capitalize;
}


/* weather teaser */
.console-teaser--weather {
    position: relative;
}
.console-teaser--weather-small .console-teaser__body {
    @media screen and (min-width:768px){
        padding-top: 28px;
        padding-bottom: 28px;
    }
    @media screen and (min-width:1200px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.console-teaser--weather.console-teaser--weather-small .console-teaser__img.console-teaser__img{
    @media screen and (min-width:768px){
        margin-bottom: 28px;
    }
    @media screen and (min-width:1200px){
        margin-bottom: 37px;
    }
}

.console-teaser--weather .console-teaser__img {
    margin:0 auto;
    max-width:80px;

    @media screen and (min-width:1200px){
        max-width:110px;
    }
    @media screen and (min-width:1440px){
        max-width:115px;
    }
}
/*.console-teaser__img--small {
    margin:0 auto;
    max-width:40px;
}*/
.console-teaser__temp {
    font-size:calc(44rem/16);
    line-height: 0.8;
    margin-bottom: .75rem;
    @media screen and (min-width:1440px){
        font-size:calc(58rem/16);
    }
}
.console-teaser__temp>sup {
    top: -1.5rem;
    font-size:1.25rem;
}
.console-teaser__temp.has-border {
    border-left:3px solid var(--color-default);
}
.console-teaser__temp.has-pl {
    padding-left:10px;
}

.console-teaser__temp-small {
    font-size:1.75rem;
}
.console-teaser__temp-small>sup {
    top: -.75em;
    font-size:1rem;
}
.console-teaser__temp-legend {
    font-size:calc(11rem/16);
    letter-spacing: 0.8px;
}
.console-teaser__temp-legend.has-pl {
    padding-left:10px;
}



/* ticketing */
.form--ticket-console {
    margin:20px 0;
    @media screen and (min-width:768px){
        margin:35px 0;
    }
}
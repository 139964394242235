.img-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: calc(8rem/16);
}
.img-grid__item{
    cursor: pointer;
}
.img-grid.img-grid--one-big{
    grid-template-areas: "big big sm-1 sm-2 sm-3 sm-4 "
                          "big big sm-5 sm-6 sm-7 sm-8"
                          "sm-9 sm-10 sm-11 sm-12 sm-13 sm-14"
                          "sm-15 sm-16 sm-17 sm-18 sm-19 sm-20";
}
@media (max-width: 970px) and (min-width: 820px){
    .img-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .img-grid.img-grid--one-big{
        grid-template-areas: "big big sm-1 sm-2"
                            "big big sm-3 sm-4"
                            "sm-5 sm-6 sm-7 sm-8"
                            "sm-9 sm-10 sm-11 sm-12"
                            "sm-13 sm-14 sm-15 sm-16"
                            "sm-17 sm-18 sm-19 sm-20";
    }

}
.img-grid--one-big .img-grid__item--big{
    grid-area: big;
}
.img-grid__item--hide{
    display: none;
}
.img-grid--one-big .img-grid__item-1{
    grid-area: sm-1;
}
.img-grid--one-big .img-grid__item-2{
    grid-area: sm-2;
}
.img-grid--one-big .img-grid__item-3{
    grid-area: sm-3;
}
.img-grid--one-big .img-grid__item-4{
    grid-area: sm-4;
}
.img-grid--one-big .img-grid__item-5{
    grid-area: sm-5;
}
.img-grid--one-big .img-grid__item-6{
    grid-area: sm-6;
}
.img-grid--one-big .img-grid__item-7{
    grid-area: sm-7;
}
.img-grid--one-big .img-grid__item-8{
    grid-area: sm-8;
}
.img-grid--one-big .img-grid__item-9{
     grid-area: sm-9;
 }
.img-grid--one-big .img-grid__item-10{
      grid-area: sm-10;
}
.img-grid--one-big .img-grid__item-11{
    grid-area: sm-11;
}
.img-grid--one-big .img-grid__item-12{
    grid-area: sm-12;
}
.img-grid--one-big .img-grid__item-13{
    grid-area: sm-13;
}
.img-grid--one-big .img-grid__item-14{
    grid-area: sm-14;
}
.img-grid--one-big .img-grid__item-15{
    grid-area: sm-15;
}
.img-grid--one-big .img-grid__item-16{
    grid-area: sm-16;
}
.img-grid--one-big .img-grid__item-17{
     grid-area: sm-17;
 }
.img-grid--one-big .img-grid__item-18{
    grid-area: sm-18;
}
.img-grid--one-big .img-grid__item-19{
    grid-area: sm-19;
}
.img-grid--one-big .img-grid__item-20{
    grid-area: sm-20;

}
.img-grid__item {
    position: relative;
}
.img-grid__item-show-all {
    position: absolute;
    inset: 0;
    z-index: 1;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: calc(6rem/16);
    text-transform: uppercase;
    text-align: center;
    background-color: rgba(244,245,245,0.9);
    outline: 1px solid rgba(244,245,245,0.9);
}
.embed-responsive-img-grid-item-big{
    padding-bottom: 57%;
}
@media screen and (max-width: 820px){
    .img-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .img-grid.img-grid--one-big{
        grid-template-areas: "big big sm-1"
                            "big big sm-2"
                            "sm-3 sm-4 sm-5"
                            "sm-6 sm-7 sm-8"
                            "sm-9 sm-10 sm-11"
                            "sm-12 sm-13 sm-14"
                            "sm-15 sm-16 sm-17"
                            "sm-18 sm-19 sm-20";
    }
    .img-grid__item-show-all {
        font-size: 0.6rem;
    }
}
.console{
    padding-bottom:1rem;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    margin-bottom:1rem;

    @media screen and (min-width:768px){
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin:0;
        padding:0;
        /*max-width: 145px;*/
        background: transparent;
        height: 100%;
        width: auto;
        box-shadow: none;
    }
}

.console.is-open{
    width: 100%;
}

.console__hl{
    font-size: 22px;
    line-height: 1;
    font-family: var(--font-light-family);
    margin-bottom: 20px;
    text-align: center;

    @media screen and (min-width:1200px){
        font-size: 32px;
    }
    @media screen and (min-width:768px) and (max-width:1200px){
        margin-left:-45px;
        margin-right:-45px;
    }
}
.console__hl>small{
    font-size: 15px;
}

.console__nav{
    z-index: 2;
    text-align: center;
    @media screen and (min-width:768px){
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    @media screen and (min-width:1200px){
        right: 35px;
    }
}
.console-nav-item__icon {
    font-size: 31px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.console-nav-item__close{
    width: 60px;
    height: 60px;
    background: #fff;
    color:var(--color-default);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    padding: 20px;
    font-size: 20px;
    display: none;
    cursor: pointer;

    @media screen and (min-width:768px){
        width: 75px;
        height: 75px;
        padding: 27px;
    }
}

.console-nav-item__icon.icon-webcam{
    font-size: 18px;
}

.console-nav-item__icon.icon-ticket{
    font-size: 24px;
}

.nav-tabs.nav-tabs--console{
    border:none;
    display: block;
}

.nav-tabs.nav-tabs--console>li{
    position: relative;
    display: inline-block;
    margin:0 6px;

    @media screen and (min-width:768px){
        float: none;
        margin: 0 0 15px 0;
        display: block;

    }
}

.console-nav-item{
    border-radius: 50%;
    background: #fff;
    margin: 0;
    line-height: 1;
    display: block;
    width: 60px;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border:none;
    position: relative;
    transition: all 150ms ease;

    @media screen and (min-width:1200px){
        width: 75px;
        height: 75px;
    }
}

.console-nav-item:hover,
.console-nav-item__close:hover{
    background: var(--color-primary);
    color: #fff;
}

.portal-default .console-nav-item:hover,
.portal-default .console-nav-item__close:hover{
    background: var(--color-default);
    color: #fff;
}

.nav-tabs.nav-tabs--console>li>a{
    border:none;
    width: 60px;
    height: 60px;
    position: relative;
    display: block;

    @media screen and (min-width:1200px){
        width: 75px;
        height: 75px;
    }
}

.nav-tabs.nav-tabs--console>li>a.nav-link.active{
    background: transparent;
}

.nav-tabs.nav-tabs--console>li>a:hover,
.nav-tabs.nav-tabs--console>li>a:focus{
    background: none;
}
.nav-tabs.nav-tabs--console .console-nav-item{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.console-nav-item__inner{
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    cursor: pointer;

    @media screen and (min-width:768px){
        width: 75px;
        height: 75px;
    }
}

.nav-tabs.nav-tabs--console>li.is-active>a{
    box-shadow: none;
    background: none;
}

li.is-active .console-nav-item__inner{
    display: none;
}

li.is-active .console-nav-item__close{
    display: block;
}

.nav-tabs.nav-tabs--consoleli.active>a,
.nav-tabs.nav-tabs--console>li.active>a:focus,
.nav-tabs.nav-tabs--console>li.active>a:hover{
    border:none;
}

.tab-content--console {
    @media screen and (min-width:768px){
        width: 42%;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.95);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 350ms ease;

    }
}

.console.is-open .tab-content--console{
    transform: translateX(0);
}
.tab-content--console .tab-pane{
    margin:20px;

    @media screen and (min-width:768px){
        height: 100%;
        margin: 40px 120px 40px 60px;
    }
    @media screen and (min-width:1200px){
        margin:55px 165px 75px 95px;
    }
    @media screen and (min-width:1440px){
        margin:70px 190px 75px 110px;
    }
}


.list-facilities-console{
    margin-top: 1rem;

    @media screen and (min-width:1440px){
        margin-top: 2rem;
    }
}

.list-facilities-console>li{
    margin-bottom: 1rem;

    @media screen and (min-width:1440px){
        margin-bottom: 2rem;
    }
}
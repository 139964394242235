.offer-teaser__body{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    bottom: 15px;
    right: 15px;
    left: 15px;
    padding: 8px;
    z-index: 1;

    @media screen and (min-width: 768px){
        bottom: 30px;
        right: 30px;
        left: 30px;
        padding: 10px 12px;
    }
}
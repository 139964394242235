.anchor-nav--scroll{
    overflow: auto;
    overflow-x: scroll;
    display: flex;
    width: 100%;
}

.anchor-nav--scroll::-webkit-scrollbar{
    height: calc(6rem/16);
    border-radius: 50%;
}
.anchor-nav--scroll::-webkit-scrollbar-track{
    background: #ebebeb;
    border-radius: calc(15rem/16);
}
.anchor-nav--scroll::-webkit-scrollbar-thumb{
    background: var(--color-lightgrey);
    border-radius: calc(15rem/16);
    width: 20%;
}
.anchor-nav--scroll:last-child{
    margin-right: 0;
}

.anchor-nav--scroll > .anchor-nav__item.list-inline-item,
.anchor-nav--scroll > li.list-inline-item {
    margin-right: calc(40rem/16);
    flex: none;
    color: var(--color-default);
    margin-bottom: calc(8rem/16);
}
.anchor-nav--scroll > .anchor-nav__item > .anchor-nav__link > .icon,
.anchor-nav--scroll > li > a > .icon {
    font-size: calc(10rem/16);
}

.anchor-nav__wrapper {
    background-color: white;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* needed to prevent sticky anchor-nav from jittering while scrolling on iOS */
.anchor-nav__wrapper.is-sticky {
    & > * {
        transform: translateZ(0);
    }
}

.anchor-nav {
    background-color: white;
    max-width: 1000px;
    flex-wrap: nowrap;
}

.is-sticky .anchor-nav {
    padding: 15px 0 0;
    z-index: 10;
}

.anchor-nav .anchor-nav__link,
.anchor-nav .anchor-nav--scroll > li > a {
    padding: 0;
    position: relative; 
    color: var(--color-lightgrey) !important;
    font-family: var(--font-bold-family);
    background-color: transparent !important;
    font-size: calc(18rem/16);
    &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: var(--color-default);
        transition: width 300ms ease;
    }
    &.active {
        color: var(--color-default);
        background-color: transparent;
        &:after {
            width: 100%;
        }
    }
    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        color: var(--color-default);
    }
}
:root {
    --color-default: #232323;

    --color-primary: #544b45;
    --color-primary-dark: #37312d;

    --color-arosa: #005D9E;
    --color-lenzerheide: #F2213B;

    --bg-lightgrey: #dddbda;

    --color-lightgrey: #ada8a6;
    --color-anthrazit: #344256;


    /*!**** Fonts ****!*/

    --font-default-family: 'Circular-Bk', Tahoma, sans-serif;
    --font-light-family: 'Circular-Bk', Tahoma, sans-serif;
    --font-bold-family: 'Circular-Bld', Tahoma, sans-serif;

    --font-lobster-family: 'Lobster-Reg',sans-serif;

    --font-themix-family: 'TheMix', sans-serif;
    --font-themix-bold: 'TheMix-Bld', sans-serif;

    /* same font because of switch to typekit - font weights for distinguishing are 700 for bold  & 400 for regular */
    --font-larken-default: 'larken',serif;
    --font-larken-bold: 'larken',serif;

}
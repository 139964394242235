.demi-destination-package-list .card-body > .teaser__body { box-shadow: none; }
.demi-destination-package-list .card-body > .teaser__img:after { box-shadow: none; }

.demi-destination-package-list .card {
    border:0;
    border-radius: 0;
}
.demi-destination-package-list .card-body {
    padding: 0 15px 20px 15px;
    box-shadow: 0 1px 2px rgba(0,0,0,.2);

    @media screen and (max-width: 767px){
        padding:0 15px;
        box-shadow: 0 0 4px rgba(0,0,0,.2);
    }
}
.demi-destination-package-list .card-body .embed-responsive {
    position: relative;
    margin:0 -15px;
    width: auto;
}
.demi-destination-package-list .card-body h4,
.demi-destination-package-list .card-body h3{
    font-size: 20px;
    line-height:calc(23/20);
    font-family: var(--font-light-family);
    text-transform:none;
}
.demi-destination-package-list .card-body .text-muted{
    color:inherit !important;
}
.demi-destination-package-list .card-body ul{
    padding-left:20px;
}
.demi-destination-package-list .card-body .btn-success{
    background-color: #fec900;
    border-color: #fec900;
    color: var(--color-default);
}
.demi-destination-package-list .card-body .btn-success:hover{
    background-color: #d8ab00;
    border-color: #cba100;
}
.demi-destination-package-list .card-body hr{
    margin: .75rem 0 !important;
}

@media screen and (min-width: 768px){
    .demi-destination-package-list .card-body h4,
    .demi-destination-package-list .card-body h3{
        font-size: responsive 22px 25px; /* min-size, max-size */
        font-range: 768px 1700px; /* viewport widths between which font-size is fluid */
        font-family: var(--font-light-family);
        text-align: center;
        margin:.25rem .5rem 1.25rem .5rem !important;
    }
}

@media screen and (max-width: 767px){
    .demi-destination-package-list .card-body .small{
        font-size: 100% !important;
    }
}
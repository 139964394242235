.navbar{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding:0;
    z-index: 666; /* zindex higher than warning-area */
    border-radius: 0;
    margin-bottom: 0;
    height: 48px;
    border: none;
    background: #fff;
    font-family: var(--font-default-family);
    color: var(--color-default);
    //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: 768px){
        height: 110px;
        z-index: 555; /* zindex higher than warning-area */
    }
}

.is-blog .navbar {
    height: 67px;
}

.navbar>.container{
    @media screen and (max-width: 767px){
       padding: 0;
    }
}

body.lg-on .navbar {
    z-index:100;
}
.navbar-main--small{
    position: absolute;
}

.navbar-region-bar{
    background-color: #D9D9D9;
    color: #fff;
    position: relative;
    z-index: 100;
    
    @media screen and (min-width: 768px){
        width: 100%;
    }
    
    @media screen and (max-width: 767px){
        display: inline-block;
        text-align: center;
        border: 1px solid var(--color-default);
    }
}

@media screen and (min-width: 1921px){
    .navbar-region-bar__list{
        width: 100%;
        margin: 0 auto;
    }
}

.navbar-region-bar__link{
    font-size: 12px;
    line-height: calc(26/22);
    font-family: var(--font-bold-family);
    padding: 10px 7px;
    display: inline-block;
    transition: all 150ms ease;
    letter-spacing: 0px;
    color: #232323;
    position: relative;
    @media screen and (min-width: 360px) and (max-width: 768px){
        font-size: 13px;
        padding: 10px 13px;
    }

    @media screen and (min-width: 768px){
        font-size: 22px;
        padding: 12px 35px;
        width: 100%;
        text-align: center;
    }
}
.navbar-region-bar__link::after {
    content: "";
    position: absolute;
    left: -1px;
    top: 15px;
    bottom: 15px;
    width: 2px;
    background-color: #C1BFB5;
    opacity: 1;
    transition: opacity 300ms ease;
}
.navbar-region-bar__item:hover .navbar-region-bar__link::after,
.navbar-region-bar__item:hover + .navbar-region-bar__item .navbar-region-bar__link::after {
    opacity: 0;
}
.navbar-region-bar__link.default {
    font-family: var(--font-larken-bold);
    font-weight: 700;
}
.navbar-region-bar__link.arosa {
    font-family: marine, sans-serif;
    font-weight: 700;
}
.navbar-region-bar__link.lenzerheide {
    font-family: var(--font-themix-bold);
}



.navbar-region-bar__list {
    font-size: 0;
    @media (min-width: 768px) {
        display: flex;
    }
}
.navbar-region-bar__item {
    @media (min-width: 768px) {
        flex: 1;
    }
}


.list-inline-item.navbar-region-bar__item:not(:last-child){
    margin: 0;
}

.navbar-region-bar__item.active .navbar-region-bar__link,
.navbar-region-bar__item .navbar-region-bar__link:hover{
    background-color: #fff;
    color: var(--color-default);
}
.navbar-region-bar__item.active .navbar-region-bar__link::after,
.navbar-region-bar__item:first-child .navbar-region-bar__link::after,
.navbar-region-bar__item.active + .navbar-region-bar__item .navbar-region-bar__link::after {
    display: none;
}

.navbar-region-bar__item.active .navbar-region-bar__link.arosa,
.navbar-region-bar__item .navbar-region-bar__link.arosa:hover{
    background-color: #fff;
    color: var(--color-arosa);
}

.navbar-region-bar__item.active .navbar-region-bar__link.lenzerheide,
.navbar-region-bar__item .navbar-region-bar__link.lenzerheide:hover{
    background-color: #fff;
    color: var(--color-lenzerheide);
}

.navbar-top{
    height: 115px;
    padding: 0 0 0 30px;
    width: 100%;
    visibility:visible;
    background-color: #fff;
    position: relative;
    z-index: 100;
    transition: height 300ms ease;
    @media screen and (min-width:768px){
        padding: 0;
        margin:0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        height: 75px;
    }
    @media screen and (min-width:992px){
        flex-direction: row;
        height: 100px;
    }
    @media screen and (max-width: 767px){
        display: none;
    }
}
.navbar-top__left{
    margin: 25px 0;
    float: left;
    @media screen and (min-width: 768px){
        margin: 0;
        float: none;
    }
}

.is-blog .navbar-top__left{
    margin: 44px 0;
}

.navbar-top__left ul>li{
    float: left;
    padding: 0;
    position: relative;
}

.is-blog .navbar-top__left ul>li:not(:last-child){
    margin-right: 15px;
}

.navbar-top__right{
    @media screen and (min-width: 768px){

    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        align-self: flex-end;
        margin: 0;
    }
}
.navbar-top__right ul>li {
    float: left;
    padding: 0;
}



.navbar-top__regionchanger{
    margin:0 0 30px 0;
    text-align: center;
    background: #fff;

    @media screen and (min-width: 768px){
        margin: 15px 0;
        float: right;
        background: none;
    }

    @media screen and (max-width: 767px){
        position:absolute;
        right:105px;
        top:9px;
        margin:0;
    }
}
.navbar-top__regionchanger .regionchanger{
    position: relative;
    display: inline-block;
    padding:0 0 15px 0;
    font-size:16px;
    letter-spacing: -0.5px;
    transition: all 250ms ease;

    @media screen and (min-width: 768px){
        padding:15px 0;
        font-size:22px;
        letter-spacing: -0.5px;
    }
}
.navbar-top__regionchanger .regionchanger:after{
    display: none;
}
.navbar-top__regionchanger .regionchanger__dropdown-icon{
    position: relative;
    top:-3px;
    font-size:8px;
    margin:0 10px;
}
.navbar-top__regionchanger .regionchanger__poi-icon{
    position: relative;
    top:8px;
    font-size:35px;
    margin:0 10px;
    transition: all 250ms ease;
}
.navbar-top__regionchanger .regionchanger:focus .regionchanger__poi-icon,
.navbar-top__regionchanger .regionchanger:hover .regionchanger__poi-icon{
    top:5px;
}
.navbar-top__item {
    margin: 0;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px){
        width: 70px;
    }
    @media screen and (min-width: 1200px){
        width: 100px;
    }
}
.navbar-top__item .dropdown-menu--regionchanger,
.navbar-top__regionchanger .dropdown-menu--regionchanger {
    left:auto;
    right:0;
    padding: 15px 0;

    @media screen and (max-width: 767px){
        border-radius: 0;
        left:0;
        margin:0;
        padding:10px 0;
    }
}
.navbar-top__item .dropdown-menu--regionchanger > li > a,
.navbar-top__regionchanger .dropdown-menu--regionchanger > li > a {
    display: block;
    padding: 10px 10px;
    color: inherit;
    text-align:center;
    font-size:16px;
    letter-spacing: -0.5px;

    @media screen and (min-width: 768px){
        padding: 15px 60px;
        text-align:right;
        font-size:22px;
        letter-spacing: -0.5px;
    }
}
.navbar-top__item .dropdown-menu--regionchanger > li.active > a,
.navbar-top__regionchanger .dropdown-menu--regionchanger > li.active > a {
    opacity: 0.35;
}



@media screen and (max-width: 359px){
    .navbar-header-items__items--hide-xxs{
        display: none;
    }
}

.navbar-sticky-console__item,
.navbar-top__item{
    text-align: center;
}

.navbar-sticky-console__item>a,
.navbar-top__item>button,
.navbar-top__item>a,
.navbar-top__item .dropdown>a {
    display: block;
    padding: 10px 20px;

    @media screen and (min-width: 768px){
        padding: 10px 14px;
    }

    @media screen and (min-width:1200px) {
        padding: 10px 26px;
    }
} 

.navbar-top__right .navbar-top__item>a{
    display: block;
    padding: 10px 8px;
    position: relative;

    @media screen and (min-width: 1200px){
        padding: 10px 15px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding: 7px 8px;
    }
}
@media screen and (min-width: 768px){
    .navbar-main .navbar-top__item--with-bg {
        background-color: #fec900;
    }
    .navbar-main .navbar-top__item--with-grey-bg {
        background-color: #f2f2f1;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-main .navbar-top__item--with-bg:before {
        top: 0;
        bottom: 0;
    }
}

.navbar-sticky-console__item--with-border>a,
.navbar-top__item--with-border>a {
    position: relative;
}
.navbar-sticky-console__item--with-border>a:before,
.navbar-top__item--with-border>a:before {
    content:'';
    display: block;
    position: absolute;
    left:0;
    top: 1rem;
    bottom: 1rem;
    width:1px;
    height:auto;
    background:#ccc;
    opacity: .6;
}

@media screen and (max-width: 767px){
    .navbar-sticky-console__item--with-border:first-child>a:before {
        display: none;
    }
}


.navbar-top__item button{
    padding-left: 4px;
}

.navbar-top__item button>span,
.navbar-top__item a>span {
    line-height: calc(20/14);
    margin-top: 6px;
    @media screen and (min-width: 992px) {
        margin-top: 8px;
    }
}
.navbar-sticky-console__item .navbar-sticky-console__item__icon,
.navbar-top__item .navbar-top__item__icon{
    font-size: 24px;
    height: 24px;
    display: block;
    transition: all 250ms ease;
}


@media screen and (max-width: 767px){
    .navbar-top__item__icon {
        font-size: 20px;
    }
}

.navbar-top__item button:not(.custom-popup__close):hover .icon,
.navbar-top__item button:focus .icon,
.navbar-top__item a:hover .icon:not(.region-switch__dropdown-icon),
.navbar-top__item a:focus .icon:not(.region-switch__dropdown-icon) {
    transform: translateY(-3px);
}


.navbar-cart-count{
    width: 17px;
    height: 17px;
    background: #fec900;
    color: #544b45;
    border-radius: 50%;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    position: absolute;
    top: 11px;
    right: 28px;
    
    @media screen and (max-width: 767px){
        width: 18px;
        height: 18px;
        top: 1px;
        right: 29px;
        font-family: var(--font-bold-family);
        background-color: #fff;
        color: #544b45;
    }
}

@media screen and (max-width: 767px) {
    .popup-open{
        position: relative;
    }

    .popup-open:before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(236, 236, 236, 0.5);
        z-index: 2500;
    }
}

body.popup-open .custom-popup {
    opacity:1;
}
.custom-popup{
    cursor: pointer;
    background-color: #fff;
    font-family: var(--font-bold-family);
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    transition: all .25s linear;
    opacity:0;
    @media screen and (min-width: 768px){
        max-width: 180px;
        margin-top: 38px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    @media screen and (max-width: 767px){
        z-index: 2600;
        width: calc(100% - 30px);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        box-shadow: 0 0 10px 0 #C9C9C9;
    }
}

.affix .custom-popup{
    @media screen and (min-width: 768px){
       margin-top: 28px;
    }
}

@media screen and (min-width: 768px){
    .custom-popup:before {
        width: 0;
        height: 0;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-bottom: 20px solid white;
        content: '';
        position: absolute;
        top: 1px;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
    }
}


.navbar-top__item button.custom-popup__close,
.custom-popup__close {
    padding: 0 4px;
    line-height: 1;
    font-size: 14px;
    background: transparent;
    border: none;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    @media screen and (min-width: 768px){
        right: 8px;
        top: 8px;
        font-size: 10px;
    }
}

.custom-popup__top{
    position: relative;
    padding: 70px 25px;
    @media screen and (min-width: 768px){
        padding: 25px;
    }
}

.custom-popup__text{
    @media screen and (max-width: 767px){
        font-size: 20px;
        line-height: 1.2;
        max-width: 150px;
        margin: 0 auto;
    }
}

.custom-popup__text p{
    margin:0;
}

.custom-popup__bottom{
    position: relative;
    background-color: #fec900;
    padding: 25px;
    line-height: 1;

    @media screen and (min-width: 768px){
        padding: 16px 25px;
    }
}

.custom-popup__bottom .icon {
    font-size: 35px;

    @media screen and (min-width: 768px){
        font-size: 26px;
    }
}

.custom-popup__bottom .icon.icon-arrow-next{
    font-size: 23px;
    margin: 0 15px;

    @media screen and (min-width: 768px){
        font-size: 17px;
        margin: 0 11px;
    }
}

.navbar-brand{
    padding:0;
    margin:0;
    width: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px){
        width: 120px;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (min-width: 992px) and (max-width: 1599px){
        width: 200px;
    }
    @media screen and (min-width: 1600px){
        width: 448px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 767px){
        width: 120px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(0);
        font-size: 0;
        height: 100%;
    }
}
.navbar-brand.navbar-brand__default {
    background: linear-gradient(to right, var(--color-arosa), var(--color-lenzerheide));
}
.navbar-brand.navbar-brand__arosa {
    background: var(--color-arosa);
}
.navbar-brand.navbar-brand__lenzerheide {
    background: var(--color-lenzerheide);
}


.navbar-brand img{
    filter: brightness(10);
    @media screen and (max-width: 767px){
        height: 18px;
    }

    @media screen and (min-width: 768px){
        max-width: 80%;
        max-height: 30px;
        object-fit: contain;
    }

    @media screen and (min-width: 1200px){
        width: auto;
        max-width: 153px;
        max-height: 30px;
        object-fit: contain;
    }
}

.navbar-brand__neutral{
    background:#344256;
    @media screen and (max-width:991px){
        width: 180px;
    }
}
.navbar-brand__neutral img{
    @media screen and (min-width: 1600px){
        max-width: 280px;
    }
}

.navbar-brand img.navbar-brand__img-big{
    @media screen and (max-width: 767px){
        max-width: 80%;
        max-height: calc(100% / 3 * 2);
        object-fit: contain;
    }
}

header:not(.affix) .navbar-brand.navbar-brand--small {
    @media screen and (min-width: 768px){
        top:10px;
    }
}
header:not(.affix) .navbar-brand.navbar-brand--small img {
    @media screen and (min-width: 768px){
        width: 180px;
    }
    @media screen and (min-width: 1200px){
        width: 250px;
    }
}

.affix .navbar-brand > img,
.navbar-main--small .navbar-brand > img {
    @media screen and (min-width: 768px){
        width: 215px;
    }
}


@media screen and (min-width: 768px){
    .navbar-main.is-open .navbar-brand {
        display:none;
    }
}

@media screen and (max-width: 767px){
    .navbar-brand.navbar-brand--landingpage{
        top:0;
        /*position: absolute;
        left:50%;
        right:auto;
        width: auto;
        transform:translateX(-50%);
        text-align: center;*/
    }
    .navbar-brand.navbar-brand--landingpage-center{
        text-align: center;
        left:50%;
        transform:translateX(-50%);
    }
}


.affix .navbar-brand.navbar-brand--landingpage img,
.navbar-brand.navbar-brand--landingpage img{
    margin-top:12px;
    width: auto;
    height:42px;

    @media screen and (min-width: 768px){
        margin-top:10px;
        height:95px;
        float:none;
    }
}

@media screen and (min-width: 768px){
    .affix .navbar-brand.navbar-brand--landingpage {
        transform:translateX(-50%) translateY(0);
    }
    .affix .navbar-brand.navbar-brand--landingpage img {
        margin-top:0;
        height:55px;
    }
}

.navbar-brand__link {
    display: inline-block;
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    width:50%;
}
.navbar-brand__link--lh {
    left: auto;
    right:0;
}

.navbar-brand__text{
    font-size: 17px;
    line-height: 20px;
    text-shadow: 0px 0 2px rgba(0,0,0,.5);
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}

.affix .navbar-brand__text,
.navbar-main--small .navbar-brand__text{
    display: none;
}

/* affix adaptions */
@media screen and (min-width: 768px){
    header.affix,
    header.affix .navbar-top,
    header.navbar-main--small,
    header.navbar-main--small .navbar-top{
        height:55px;
        background-color: #fff;
    }
    header.affix{
        height:90px;
    }
    header.affix .navbar-top__item a>span:not(.navbar-cart-count, .navbar-language-switch-label),
    header.affix .navbar-top__item button:not(.custom-popup__close)>span,
    header.navbar-main--small .navbar-top__item a>span,
    header.navbar-main--small .navbar-top__item button>span {
        display: none !important;
    }

    header.affix .navbar-top__item a>span.navbar-language-switch-label {
        margin-top: 0;
    }
    .navbar-language-switch__flag {
        width: 1.5rem;
        height: auto;
    }
    header.affix .navbar-language-switch__flag {
        display: none;
    }

    header.affix .navbar-cart-count{
        right: 6px;
    }
    header.affix .navbar-top__right,
    header.affix .navbar-top__left,
    header.navbar-main--small .navbar-top__right,
    header.navbar-main--small .navbar-top__left {
        margin: 0;
    }
    header.affix .navbar-top__regionchanger .regionchanger,
    header.navbar-main--small .navbar-top__regionchanger .regionchanger {
        top:-2px;
        padding:0;
    }
}

.navbar-language-dropdown__flag {
    width: 1.2rem;
    margin-right: .75rem;
    height: auto;
}


html.has-overflow-hidden,
.has-overflow-hidden body {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


/* header search */
.header-searchbar {
    display: none;
    position: relative;
}
header.affix .header-searchbar,
header.navbar-main--small .header-searchbar {
    top: 50%;
}
header.affix .header-searchbar .form-control {
    padding: .75rem 1rem .75rem 4.5rem;
}
header.affix .header-searchbar .form-control--with-icon-left+.icon {
    padding: .75rem 1rem;
}
.header-searchbar.visible {
    display: block;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    right: auto;
    left: 310px;
    z-index: 10;
    @media screen and (min-width: 1200px) {
        left: 420px;
    }
}
.header-searchbar .form-control {
    font-size: 1rem;
    padding: 1.5rem 1rem 1.5rem 4.5rem;

    @media screen and (min-width: 768px){
        min-width: 300px;
    }
}
.header-searchbar .form-control--with-icon-left+.icon {
    padding: 1.25rem 1rem;
    font-size: 1.75rem;
}
.header-searchbar button {
    width:100%;
    display: block;
    margin-top:1rem;

    @media screen and (min-width: 768px){
        margin-top:0;
        text-indent: -19999px;
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        width:55px;
        background:transparent !important;
        border:0;
    }
}





/* Navbar Left start */
.navbar-left{
    background: var(--bg-lightgrey);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(-100%); /* also in inline layout */
    transition: transform 300ms ease;
    z-index: 101;

    @media screen and (max-width: 767px){
        display: none;
    }
}

.navbar-main.is-open .navbar-left {
    transform: translateX(0);
    visibility: visible;
}

.navbar-left__main{
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    width: 26%;
    height: 100%;
    float: left;
    position: relative;

    @media screen and (min-width: 1400px){
        padding:50px 60px;
    }

    @media screen and (min-width: 768px){
        padding: 40px;
    }
}

/* hide 4th nav level */
.navbar-left__main ul ul ul ul ul {
    display: none;
}

.navbar-left__nav{
    padding-left: 0;
    list-style: none;
    text-align: left;
    position: relative;

    @media screen and (min-width: 768px) {
        text-align: right;
    }
}

.navbar-left__nav>li>a{
    font-size: 27px;
    line-height: 1.4;
    font-family: var(--font-bold-family);
    text-transform: uppercase;
    padding: 7px 0;
    display: inline-block;
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1023px){
        font-size: 20px;
    }
    @media screen and (min-width: 1200px){
        font-size: 32px;
    }
    @media screen and (min-width: 1600px){
        font-size: 38px;
    }
}
.navbar-left__nav>li.is-open>a,
.navbar-left__nav>li.is-active>a,
.navbar-left__nav>li>a:hover,
.navbar-left__nav>li>a:focus{
    color: var(--color-primary);
}

.portal-default .navbar-left__nav>li>a{
    color: #000;
}

.navbar-left__nav>li>a:before{
    content: '';
    height: 5px;
    width: 60px;
    background: var(--color-primary);
    position: absolute;
    bottom:3px;
    right: 0;
    transform: scaleX(0);
    transition: transform 300ms ease;
    transform-origin: 100% 50%;

    @media screen and (min-width: 1024px){
        bottom:5px;
    }
}

.portal-default .navbar-left__nav>li>a:before{
    background: var(--color-default);
}

.navbar-left__nav>li.is-active>a:before,
.navbar-left__nav>li.is-open>a:before{
    transform: scaleX(1);
}

.navbar-left__bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.navbar-left__bottom>li{
    padding: 0;
    margin:0 !important;
    border-right: 2px solid #FFE999;
}

.navbar-left__bottom>li:last-child{
    border-right: none;
}

.navbar-left__bottom>li>a{
    font-size: 13px;
    line-height: 1;
    padding: 26px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #FEC900;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    text-align: center;
    @media screen and (min-width: 1400px){
        font-size: 18px;
    }
}

.navbar-left__bottom>li>a:hover{
    background: #edbc00;
}

.navbar-left__bottom>li>a .icon{
    font-size: 22px;
    margin-right: 10px;
    position: relative;
    @media screen and (min-width: 1800px){
        font-size: 30px;
    }
}

/*.navbar-left__bottom>li>a.navbar-left__bottom__booking{
    line-height: 36px;
}*/

@media screen and (min-width: 768px){
    .navbar-left__lang.navbar-left__lang {
        margin-bottom:30px;
        display: flex;
        justify-content: flex-end;
        margin-top: 2.5rem;
    }
}

.navbar-xs__lang{
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 13px 24px;
    box-shadow: 0 0 7px #00000033;
    background-color: #FFF;
    z-index: 20;
}

.navbar-xs__lang>li a,
.navbar-left__lang>li a{
    color: var(--color-lightgrey);
}
.navbar-xs__lang>li a{
    display: inline-block;
    margin-top: 5px;
}
.navbar-xs__lang>li a img{
    position: relative;
    top: -3px;
}
.navbar-left__lang>li a{
    margin-left:15px;
}
.navbar-left__lang>li a img{
    position: relative;
    top: -1px;
    margin-right:3px;
}

.navbar-xs__lang>li a.active,
.navbar-xs__lang>li a:hover,
.navbar-left__lang>li a.active,
.navbar-left__lang>li a:hover{
    color: var(--color-default);
}

.navbar-xs__bottom{
    text-align: center;
}

.navbar-xs__bottom .list-inline-item:not(:last-child){
    margin-right: 20px;
}

.navbar-xs__bottom>li>a {
    display: block;
    position: relative;
    padding:5px 0 5px 30px;
    font-size: 20px;
    font-family:var(--font-light-family);
    color: var(--color-default);
}
.navbar-xs__bottom>li>a>.icon {
    position: absolute;
    left:0;
    top:8px;
}


.navbar-left__nav>li>.navbar-subnav{
    @media screen and (min-width: 768px){
        position: absolute;
        top: 0;
        right: 0;
        left:calc(100% + 75px); /* ie11 bugfix */
        transform: translateX(0); /* ie11 bugfix */
        text-align: left;
        display: none;
        max-width: 280px;
        width: 100%;
        z-index:10;
    }
    @media screen and (min-width:1400px){
        left:calc(100% + 150px); /* ie11 bugfix */
        max-width: 345px;
    }

}

.navbar-left__nav>li>.navbar-subnav>ul>li>a{
    font-size: 25px;
    font-family: var(--font-light-family);
    line-height: 1.2;
    display: inline-block;
    padding: 5px 0;
    margin: 5px 0;
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1023px){
        font-size: 18px;
    }
}
.navbar-left__nav>li>.navbar-subnav>ul>li>a.toggle-subnav{
    padding-right:30px;
}
@media screen and (min-width: 768px){
    .navbar-left__nav>li>.navbar-subnav ul li a .icon {
        font-size:50%;
        position: absolute;
        right: 0;
        top: 15px;
        transform: scale(-1, 1);
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px){
    .navbar-left__nav>li>.navbar-subnav ul li a .icon {
        top: 10px;
    }
}
.navbar-left__nav>li>.navbar-subnav>ul>li li>a{
    position: relative;
}

.navbar-left__nav>li>.navbar-subnav>ul>li li>a:before,
.navbar-left__nav>li>.navbar-subnav>ul>li>a:before{
    content: '';
    height: 2px;
    background: var(--color-primary);
    position: absolute;
    bottom:0;
    right: 0;
    left:0;
    transform: scaleX(0);
    transition: transform 300ms ease;
}

.portal-default .navbar-left__nav>li>.navbar-subnav>ul>li>a:before{
    background: #000;
}

@media screen and (min-width: 768px){
    .navbar-left__nav>li>.navbar-subnav>ul>li li.is-open>a:before,
    .navbar-left__nav>li>.navbar-subnav>ul>li.is-open>a:before,
    .navbar-left__nav>li>.navbar-subnav>ul>li li.is-active>a:before,
    .navbar-left__nav>li>.navbar-subnav>ul>li.is-active>a:before{
        transform: scaleX(1);
    }
}

.navbar-left__nav>li>.navbar-subnav>ul>li li.is-active>a,
.navbar-left__nav>li>.navbar-subnav>ul>li li.is-open>a,
.navbar-left__nav>li>.navbar-subnav>ul>li li>a:hover,
.navbar-left__nav>li>.navbar-subnav>ul>li li>a:focus,
.navbar-left__nav>li>.navbar-subnav>ul>li.is-active>a,
.navbar-left__nav>li>.navbar-subnav>ul>li.is-open>a,
.navbar-left__nav>li>.navbar-subnav>ul>li>a:hover,
.navbar-left__nav>li>.navbar-subnav>ul>li>a:focus{
    color: var(--color-primary);
}

.portal-default .navbar-left__nav>li>.navbar-subnav>ul>li.is-active>a,
.portal-default .navbar-left__nav>li>.navbar-subnav>ul>li.is-open>a,
.portal-default .navbar-left__nav>li>.navbar-subnav>ul>li>a:hover{
    color: #000;
}

.navbar-left__nav>li.is-open>.navbar-subnav{
    @media screen and (min-width: 768px){
        display: block;

    }
}

.navbar-sub-subnav{
    position: absolute;
    top: 0;
    right: 0;
    left:calc(100% + 25px); /* ie11 bugfix */
    transform: translateX(0); /* ie11 bugfix */
    text-align: left;
    display: none;
    /*max-width: 345px;*/
    width: 100%;
    z-index:11;
}

.navbar-sub-subnav>ul>li>a{
    display: inline-block;
    font-size: 22px;
    padding: 5px 0;
    font-family: var(--font-light-family);
    @media screen and (min-width: 768px) and (max-width: 1023px){
        font-size: 17px;
        line-height: 1.25;
    }
}
.navbar-sub-subnav>ul>li>a.toggle-subnav{
    padding-right:30px;
}
.navbar-sub-subnav>ul>li>a:hover{
    color: var(--color-primary);
}

.portal-default .navbar-sub-subnav>ul>li>a:hover{
    color: #000;
}

.navbar-left__nav>li>.navbar-subnav>ul>li.is-open>.navbar-sub-subnav,
.navbar-left__nav>li>.navbar-subnav>ul>li li.is-open>.navbar-sub-subnav{
    @media screen and (min-width: 768px){
        display: block;
    }
}

/* Navbar Content */

.navbar-left__content{
    width: 74%;
    height: 100%;
    float: left;
    position: relative;
}
.navbar-teaser{
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    padding:60px 40px;
    transition: all 0.2s linear;

    @media screen and (min-width:1800px){
        padding:60px 140px;
    }
}
.navbar-teaser.hide{
    bottom:-800px;
}
.btn-close-overlay,
.btn-close-navbar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: absolute;
    right:8px;
    top:8px;
    font-size: 20px;
    line-height: 1;
    z-index: 2;
    cursor: pointer;
    
    @media screen and (max-width: 767px){
       z-index: 1000;
    }

    @media screen and (min-width: 768px){
        width: 66px;
        height: 66px;
        font-size: 23px;
    }

    @media screen and (min-width:1400px){
        width: 81px;
        height: 81px;
        font-size: 26px;
        right:35px;
        top:35px;
    }
}

.btn-close-overlay:hover,
.btn-close-overlay:focus,
.btn-close-navbar:hover,
.btn-close-navbar:focus{
    background:var(--color-default);
    color:#fff;
}

.btn-close-overlay .icon,
.btn-close-navbar .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}



.navbar-top__stats {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10px;
    @media screen and (min-width: 768px) and (max-width: 1199px){
        row-gap: 5px;
        justify-content: center;
    }
    @media screen and (min-width: 1200px){
        column-gap: 60px;
        margin-left: 60px;
        flex-direction: row;
        align-items: center;
    }
}
.navbar-top__stats-item {
    display: flex;
    align-items: center;
    column-gap: 12px;
    @media screen and (min-width: 768px) and (max-width: 1199px){
        column-gap: 2px;
    }
}
.navbar-top__stats-icon {
    color: #fec900;
}
.navbar-top__stats-text {
    font-size: 14px;
}

.navbar-main--portal {
    @media (min-width: 768px) {
        height: 90px;
    }
    @media (min-width: 1200px) {
        height: 110px;
    }
}
.navbar-main--portal .navbar-top {
    @media (min-width: 992px) {
        height: 110px;
    }
}
.navbar-main--portal .navbar-top__item {
    @media (min-width: 992px) {
        height: 100%;
    }
}

.language-switch__dropdown .dropdown-menu {
    min-width: unset;
}
.language-switch__dropdown .dropdown-toggle {
    position: relative;
    right: .3rem;
}
.language-switch__dropdown .dropdown-toggle:after {
    content: var(--icon-arrow-dropdown);
    font-family: iconfont;
    font-size: .4rem;
    border: unset;
    position: relative;
    top: .05rem;
}

.navbar-language-switch-label {
    font-family: var(--font-default-family);
}

.language-switch__dropdown {
    display: flex;
    flex-direction: column;
}
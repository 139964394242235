#demi-event-content .filter-wrapper .col-md-4 .form-group>label,
.pimcore_area_event-list .filter-wrapper .col-md-4 .form-group>label {
    display: none;
}
/*#demi-event-content .filter-wrapper .demi-event__filterbox-btn,*/
.pimcore_area_event-list .filter-wrapper .demi-event__filterbox-btn {
    display: block;
    width: 100%;
}

.demi-event-paging>.container {
    padding:0;
}


@media screen and (min-width: 768px){
    .demi-event-teaser-list {
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
    }
    .demi-event-teaser-list .teaser__badge {
        left:0;
        transform: translateY(0) translateX(0);
    }
    .demi-event-teaser-list .teaser__body {
        padding-left:0;
        box-shadow: none;
    }
}

@media screen and (max-width: 767px){
    .event-teaser--xs-list .event-teaser__bottom {
        padding-top:5px;
    }
    .event-teaser--xs-list .event-teaser__headline.event-teaser__headline {
        font-size:17px;
        margin-bottom: 0;
    }
    .event-teaser--xs-list {
        box-shadow: 0 1px 3px rgba(0,0,0,.2);
    }
    .event-teaser--xs-list .teaser__body {
        box-shadow: none;
        padding: 14px 15px 50px;
    }
}


/* contentblocks */
.demi-event-overview-content-block--filter{
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin-left:auto !important;
    margin-right:auto !important;
}
.demi-event-detail-content-block.demi-event-detail-content-block--date {
    margin:0;
}
.demi-event-detail-content-block--date>.container {
    margin:0;
    padding:0;
}

/* headerimg */
.content-block-small.demi-event-detail-header {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    @media screen and (min-width: 768px){
        margin-left: 40px;
        margin-right: 40px;
    }
}
.demi-event-detail-header-img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 5px rgba(0,0,0,.4), inset 2px 2px 3px rgba(0,0,0,.16);
    z-index: 1;
}


/* content */
.demi-event-overview-content-block--filter {
    margin-top:0 !important;
}


.demi-event-detail-content-block .slick-dots {
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
}
.demi-event-detail-content-block .slick-arrow {
    display: none !important;
}

.wysiwyg .demi-event-detail-list {
    margin-top:20px;
}
.wysiwyg .demi-event-detail-list > li {
    position: relative;
    padding-left: 20px;
    text-transform: uppercase;
    font-size: 13px;
}
.wysiwyg ul.demi-event-detail-list>li:before {
    width: auto;
    height: auto;
    border-radius: 0;
    background: transparent;
    content: var(--icon-arrow-next);
    font-family: iconfont;
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 10px;
}

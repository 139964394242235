.inside-header{
    position: relative;
}

.inside-header__body{
    text-align: center;
    position: absolute;
    left:50%;
    top:30px;
    transform: translateX(-50%);

    @media screen and (min-width: 768px){
        top:75px;
    }
}


.inside-header__hl{
    font-size: 40px;
    @media screen and (min-width: 768px){
        font-size: 68px;
    }
}


a.add-inside-event-data {
    position: relative;
    padding:0 0 0 32px;
}
a.add-inside-event-data > .icon {
    position: absolute;
    left:3px;
    top:1px;
    font-size:18px;
}
a.add-inside-event-data > .icon:after {
    content:'';
    display: block;
    width:24px;
    height:24px;
    background:var(--bg-lightgrey);
    position: absolute;
    left:-3px;
    top:-3px;
    border-radius: 50%;
    z-index:-1;
}

.inside-event-form-loader {
    background:#fff;
    position: fixed;
    left:50%;
    top:50%;
    width:320px;
    height:250px;
    padding:40px;
    text-align: center;
    font-size:20px;
    margin:-125px 0 0 -160px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    z-index:9999;
}
.inside-event-form-loader-bg {
    background:rgba(0,0,0,.35);
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    z-index:9995;
}
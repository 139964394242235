.wysiwyg:not(.table-responsive) p,
.wysiwyg:not(.table-responsive) ul,
.wysiwyg:not(.table-responsive) ol {
    margin-bottom: 25px;
    @media screen and (min-width: 768px) {
        margin-bottom: 40px;
    }

}
.wysiwyg.lead {
    font-size: inherit;
    font-family: var(--font-bold-family);
}

.wysiwyg ul,
.wysiwyg ol {
    @media screen and (min-width: 768px) {
        padding-left: 18px;
    }
}

.wysiwyg ul{
    list-style: none;
    padding-left: 0;
}

.wysiwyg ul:not(.list-unstyled)>li{
    position: relative;
    padding-left: 25px;
    margin-bottom: 7px;
}

.wysiwyg ul:not(.list-unstyled)>li:before{
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #544b45;
    position: absolute;
    left:0;
    top:10px;
}

#mainContent p a:not(.btn),
.wysiwyg a{
    color: var(--color-primary);
    text-decoration: underline;
}

#mainContent p a:not(.btn):hover,
#mainContent p a:not(.btn):focus,
.wysiwyg a:hover,
.wysiwyg a:focus{
    text-decoration: none;
}

.portal-default .wysiwyg a{
    color: #000;
}

@media screen and (min-width: 768px){
    .wysiwyg-multicolumn-2{
        column-count: 2;
        column-gap: 53px;
    }
}


.wysiwyg-multicolumn-2__hl{
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
}

.event-intro .wysiwyg{
    font-size: 16px;
}

@media screen and (min-width: 768px){
    .event-intro .wysiwyg{
        font-size: 18px;
    }
}
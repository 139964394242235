.twitter-typeahead {
    width: 100%;
}
.tt-dataset:empty {
    display: none;
}
.tt-suggestion__link:hover,
.tt-suggestion__link:focus,
.tt-selectable:hover,
.tt-selectable:focus,
.tt-selectable.tt-cursor {
    cursor: pointer;
    color: var(--color-primary);
    background: #f0f0f0;
}
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
    width: 100%;
}
.tt-hint {
    color: #D8D8D8;
}
.tt-menu {
    background: #fff;
    width: 100%;
    max-height: 450px;
    overflow-y: auto;
}
.tt-suggestion {
    border-top:1px solid #D8D8D8;
    line-height: calc(19/16);
    padding-right: 10px;
}
.tt-suggestion__link {
    padding:1rem 1.5rem;
    /*font-family:var(--font-default-regular);*/
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-decoration:none !important;
}

@media screen and (max-width: 767px) {
    .input-group > .twitter-typeahead {
        position: static !important;
    }
}
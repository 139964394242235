.package-group {
    position: relative;
}
@media screen and (min-width: 768px) {
    .sticky-box {
        position: sticky;
        right: 0;
        top: 110px;
        z-index: 10;
    }
}
.card--marktplatz {
    border-radius: 0;
    background-color:var(--bg-lightgrey);
    border: none;
}
.card--marktplatz .card-header {
    font-size:1.25rem;
    font-family:var(--font-light-family);
    padding: 1rem;
    background-color: #fff;
    border-radius: 0;
    @media screen and (min-width: 768px) {
        padding: 1rem 2rem;
    }
}
.card--marktplatz .card-body {
    padding:1rem;
    @media screen and (min-width: 768px) {
        padding: 1rem 2rem;
    }
}
.card-addition {
    padding: calc(8rem/16) 1rem;
    @media screen and (min-width: 768px) {
        padding: calc(8rem/16) 2rem;
    }
}
.card-addition + .card-addition {
    margin-top: 1rem;
}
.card-addition__btn {
    padding-top: calc(8rem/16);
    padding-bottom: 1rem;
    @media screen and (min-width: 768px) {
        padding-top: calc(8rem/16);
        padding-bottom: 1rem;
    }
}
.card-addition--success {
    border: 2px solid #5fc45f;
}
.card-addition--error {
    border: 2px solid #da5959;
}
.marktplatz-add-form__result .marktplatz-add-form__result .btn-cta {
    display: none;
}
.marktplatz-add-form__description {
    font-size: calc(16rem/16);
    line-height: 1.2;
}

.marktplatz-form__result.loading,
.marktplatz-add-form__result.loading {
    position: relative;
    min-height:50px;
}
.marktplatz-booking__item select.form-control:not([size]):not([multiple]) {
    border-radius: 0;
    @media screen and (min-width: 768px) {
        height:3.5rem;
        padding-left:3rem;
    }
}
@media screen and (min-width: 768px) {
    .marktplatz-booking__price {
        margin-right:2rem;
    }
    .marktplatz-booking__item select.custom-select {
        background-position: 25px 50%;
    }
}


/* new version */
.marktplatz-tabs .tab-content {
    /*background-color: #DDDBDA;*/
}
.martkplatz__room-lbl {
    position: relative;
    display: inline-block;
    @media screen and (min-width: 768px) {
        top:-0.625rem;
    }
}
.nav-tabs--marktplatz {
    border:0;
    display: block;
}
.marktplatz-tab__headline {
    margin-bottom:2rem;
    @media screen and (min-width: 768px) {
        margin-bottom:4rem;
    }
}
.nav-tabs.nav-tabs--marktplatz li.nav-item {
    display: block;
    margin:0;
}
.marktplatz-tab__btn {
    position: relative;
    display: block;
    background-color: transparent;
    font-size: 18px;
    font-family:var(--font-bold-family);
    text-transform: uppercase;
    padding:15px 25px 15px 55px;
    border-radius: 0 !important;
    border-bottom:2px solid #38312D !important;
}
.marktplatz-tab__btn span {
    display: block;
    font-size:60%;
}
.nav-tabs.nav-tabs--marktplatz .marktplatz-tab__btn.active,
.nav-tabs.nav-tabs--marktplatz .marktplatz-tab__btn:hover,
.nav-tabs.nav-tabs--marktplatz .marktplatz-tab__btn:focus {
    background-color: #DDDBDA;
}
.marktplatz-tab__btn .icon {
    position: absolute;
    left:15px;
    top:50%;
    transform: translateY(-50%);
    font-size: 22px;
}
.marktplatz-tab__btn .icon--right {
    position: absolute;
    left:auto;
    right:10px;
    font-size: 22px;
    transform: translateY(-50%);
}
.marktplatz-tab__btn:not(.collapsed) .icon--right:before {
    content:var(--icon-cross);
}
.marktplatz-tab__btn .icon.icon-bed,
.marktplatz-tab__btn .icon.icon-gutschein {
    font-size: 16px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .marktplatz-tab__btn {
        font-size: 20px;
        padding:30px 15px 30px 85px;
    }
    .marktplatz-tab__btn .icon {
        left:15px;
        font-size: 32px;
    }
    .marktplatz-tab__btn .icon.icon-bed,
    .marktplatz-tab__btn .icon.icon-gutschein {
        font-size: 24px;
    }
}
@media screen and (min-width: 1200px) {
    .marktplatz-tab__btn {
        font-size: 25px;
        padding:44px 30px 44px 145px;
    }
    .marktplatz-tab__btn .icon {
        left:45px;
        font-size: 50px;
    }
    .marktplatz-tab__btn .icon.icon-bed,
    .marktplatz-tab__btn .icon.icon-gutschein {
        font-size: 36px;
    }
}
.marktplatz-tab__body {
    position: relative;
    padding:2rem 1rem;
    background-color: #DDDBDA;
    @media screen and (min-width: 768px) {
        padding:3.5rem 1rem;
    }
}
.marktplatz-tab__body--liftopia {
    background-color: #E8E9EA;
}
.marktplatz-tab__body-inner {
    position: relative;
    margin:0 auto;
    width:100%;
    max-width:600px;
}

a.marktplatz__add-room {
    display: inline-block;
    position: relative;
    text-decoration: none;
}
.marktplatz__add-room .icon {
    position: relative;
    top:0.25rem;
    margin-right: .75rem;
    font-size:1.25rem;
    display: inline-block;
    width:30px;
    height:30px;
    line-height:30px;
    text-align: center;
    color:#000;
    background:#fff;
    border-radius: 50%;
    box-shadow: inset 0 0 3px rgba(0,0,0,.5);
}
.marktplatz__remove-room {
    display: inline-block;
    position: absolute;
    right:1rem;
    top:-1.25rem;
    width:30px;
    height:30px;
    text-align: center;
    color:#000;
    background:#fff;
    border-radius: 50%;
    box-shadow: inset 0 0 3px rgba(0,0,0,.5);
    z-index:1;
    @media screen and (min-width: 768px) {
        top:0;
    }
}
.marktplatz__remove-room .icon {
    position: relative;
    top:5px;
    @media screen and (min-width: 768px) {
        top:3px; 
    }
}
.marktplatz__remove-room .icon:before {
    content: var(--icon-minus);
}

/* mobile accordeon */
.marktplatz-cards .card {
    margin:0;
    padding:0;
    border:0;
    border-radius:0;
    box-shadow: none;
}

.guestcard-form__wrapper {
    border: 1px solid rgba(0,0,0,.125);
    margin-bottom: calc(16rem/16);
    background-color: #efefef;
}
.guestcard-form__result .alert, .marktplatz-add-form__result .alert {
    background-color: transparent;
    margin-bottom: 0;
    border: none;
    font-size:  calc(16rem/16);
}
.marktplatz-add-form-btn.disabled {
    opacity: .25;
}
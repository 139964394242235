.fullwidth-teaser {
    position: relative;
    width: 100%;
    @media screen and (min-width: 768px) {
        height: 100%;
    }
}

.fullwidth-teaser:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .1);
    background-image: linear-gradient(227deg, transparent, #000);
    z-index: 1;
    opacity: .2;
    transition: opacity .25s ease-in-out;
}
.fullwidth-teaser:hover:before {
    opacity: .5;
}

.fullwidth-teaser__content {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.fullwidth-teaser__title,
.fullwidth-teaser__body {
    text-shadow: 0 0 calc(10rem /16) rgba(0, 0, 0, 75%);
}
ul.leader-list {
    padding: 0;
    overflow-x: hidden;
}
ul.leader-list li:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content:
            ". . . . . . . . . . . . . . . . . . . . "
            ". . . . . . . . . . . . . . . . . . . . "
            ". . . . . . . . . . . . . . . . . . . . "
            ". . . . . . . . . . . . . . . . . . . . "
}
ul.leader-list span:first-child {
    padding-right: 10px;
    background: #fff
}
ul.leader-list span + span {
    float: right;
    padding-left: 10px;
    background: #fff
}
.blog-header{
  position: relative;
}

.blog-header__body{
  text-align: center;
  position: absolute;
  left:50%;
  top:30px;
  transform: translateX(-50%);

  @media screen and (min-width: 768px){
    top:75px;
  }
}


.blog-header__hl{
  font-size: 40px;
  @media screen and (min-width: 768px){
    font-size: 68px;
  }
}
.blog-teaser__author{
  position: absolute;
  bottom:-15px;
  z-index: 3;
  width: 100%;
}

.blog-teaser__author__img{
  width: 66px;
  height: 66px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  position: absolute;
}

.blog-teaser__author__img img{
  border-radius: 50%;
}

.blog-teaser__author__name{
  background: #fff;
  position: absolute;
  width: 100%;<
  max-width: 170px;
  left:50%;
  top: -128px;
  transform: translateX(-50%);
  border-radius: 1000px;
  text-align: center;
  font-size: 15px;
  padding: 12px 15px;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 250ms ease;
}

.blog-teaser__author__img:hover+.blog-teaser__author__name{
  opacity: 1;
}

.blog-teaser__author__name:after{
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  position: absolute;
  bottom: -10px;
  left:50%;
  transform: translateX(-50%);
}

.blog-teaser .teaser__body{
  padding-bottom: 0;
}

.blog-teaser__categories{
  margin: 0 -15px;
  border-top: 1px solid #f5f5f5;
  color: #BBB7B5;
  font-size: 12px;
  padding: 5px 15px;
  margin-top: 15px;
}

.blog-teaser__categories ul>li.list-inline-item{
  margin-right: 0;
  padding: 0 6px 0 3px;
  line-height: 1;
}

.blog-teaser__categories ul>li.list-inline-item:not(:last-child){
  border-right: 1px solid #BBB7B5;
}

/* Blog Footer */

.footer.footer--classiccar,
.footer.footer--inside,
.footer.footer--blog{
    padding: 8px 15px 12px 15px;
    background:var(--color-default);
    color:#fff;

  @media screen and (min-width: 768px){
    padding: 20px 35px;
  }
}

.footer--blog .list-social-links .icon{
    font-size: 19px;
}

.footer--blog .list-social-links a {
    display: block;
    padding: 0 10px;
    margin: 0px 8px;
    color:#fff;
}

/* Blog Detail */

.blog-detail__date{
  font-size: 21px;
}

.blog-detail__sl{
  margin-top: 35px;

  @media screen and (min-width:768px){
    margin-top: 70px;
  }
}

.blog-detail .wysiwyg img {
    display: inline-block;
    max-width: 100%;
}

.social__follow-me__item{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  position: relative;
}

.social__follow-me__item--fb{ background-color: #3B5999; }
.social__follow-me__item--tw{ background-color: #4A90E2; }
.social__follow-me__item--tt{ background-color: #000; }
.social__follow-me__item--ig{ background: linear-gradient(180deg, #E80027 0%, #BF00A1 52.03%, #5700BF 100%); }

.social__follow-me__item:hover{
  color: #fff;
}

.social__follow-me__item .icon{
  position: absolute;
  left:50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 19px;
}

.social__follow-me__hl{
  font-size: 20px;
}
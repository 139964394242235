.image-text__tabbing .nav-item {
    font-size: calc(18rem / 16);
}

.image-text__tabbing .nav-link {
    font-family: var(--font-bold-family);
    color: var(--color-lightgrey);
    border: 0;
    border-radius: 0;
    padding: .25rem .5rem;
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: .25rem 1.5rem;
        border-bottom: 4px solid transparent;
    }
}

.image-text__tabbing .nav-link:focus, .nav-tabs .nav-link:hover,
.image-text__tabbing .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    @media screen and (min-width: 768px){
        border-color: #0a0a0a;
    }
    border-color: transparent;
    color: var(--color-default);
}

.image-text__tabbing {
    position: relative;
    z-index: 3;
    padding-bottom: 0;

    @media screen and (max-width: 767px) {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    @media screen and (min-width: 768px) {
        justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .image-text__tabbing__wrapper {
        position: relative;
    }
    .image-text__tabbing__wrapper:after {
        content: "";
        position: absolute;
        right: calc(-2rem / 16);
        top: 0;
        bottom: 0;
        z-index: 5;
        width: calc(80rem / 16);
        background: linear-gradient(to right, #ffffff00 0%, #ffffff 90%);
        pointer-events: none;
    }
}


	@media screen and (min-width: 768px){
        .image-text__tabbing .nav-link.is-centered{
            border-bottom: calc(2rem/16) solid black;
        }
	}

.image-text__tabbing.nav-tabs{
    border-bottom: 0;
}


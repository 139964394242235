.job-card__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: calc(24rem/16);
}
.job-card {
    border: 1px solid #C1BFB5;
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-column-gap: calc(23rem/16);
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
}
.job-card__img-wrapper {
    @media screen and (min-width: 768px) {
        width: calc(200rem/16);
        height: calc(150rem/16);
    }
}
.job-card__img {
    object-fit: cover;
}
.job-card__title {
    font-size: calc(24rem/16) !important;
    line-height: calc(28/24) !important;
}
.job-card__content {
    padding: calc(20rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(26rem/16);
    }
}
.job-card__btn {
    margin: calc(26rem/16);
    font-size: calc(18rem/16);
    width: calc(120rem/16);
    height: calc(60rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.job-card__info {
    display: flex;
    column-gap: calc(20rem/16);
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        column-gap: calc(10rem/16);
    }
}
.job-card__info-item {
    position: relative;
    flex-shrink: 0;
}
.job-card__info-item:last-child::after {
    display: none;
}
.job-card__info-item::after {
    content: "";
    position: absolute;
    inset: calc(6rem/16) calc(-11rem/16) calc(6rem/16) auto;
    width: calc(2rem/16);
    background-color: var(--color-default);
    @media screen and (max-width: 767px) {
        inset: calc(6rem/16) calc(-6rem/16) calc(6rem/16) auto;
        width: calc(1rem/16);
    }
}
.social-teaser{
    background-image: url(/static/img/structure.png);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.social-teaser__sl{
    font-size: 21px;
    margin-top: 3px;
    line-height: calc(25/21);
}

.social-teaser__body{
    padding: 20px;

    @media screen and (min-width: 1100px){
        padding: 40px;
    }
}

.social-teaser__bottom{
    position: absolute;
    bottom:20px;
    right: 20px;
    left:20px;

    @media screen and (min-width: 1100px){
        bottom:40px;
        right: 40px;
        left:40px;
    }
}


.list-socials>li{
    text-align: center;
}

.list-socials__item{
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    position: relative;
    text-align: center;
    transition: transform 100ms ease-in-out;

    @media screen and (min-width: 1100px){
        width: 65px;
        height: 65px;
        font-size: 20px;
    }
}
.list-socials__item:focus,
.list-socials__item:hover{
    color: #fff;
    transform:translateY(-5px);
}

.list-socials__item.list-socials__item--small{
    width: 54px;
    height: 54px;
}

.list-socials__item .icon {
    position: relative;
    top: 50%;
    transform:translateY(-50%);
    left: 0;

    @media screen and (min-width: 1100px){
        left: -1px;
    }
}

.list-socials__item.item-ig{
    background-color: #e4405f;
    background-image: linear-gradient(180deg, #e80027 0%, #bf00a1 52%, #5700bf 100%);
}

.list-socials__item.item-tw{
    background: #4990e2;
}

.list-socials__item.item-tt{
    background: #000;
}

.list-socials__item.item-fb{
    background: #3b5999;
}

.list-socials__item.item-yt{
    background: #cd201f;
}


.fb-popup {
    position: absolute;
    top:-50px;
    background:#fff;
    height:40px;
    line-height:40px;
    padding:0 20px;
    border-radius:20px;
    font-size:13px;
    color:#3b5999 !important;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}
.fb-popup:hover,
.fb-popup:focus {
    background:#3b5999;
    color:#fff !important;
}
.fb-popup--left {
    left:45px;
}
.fb-popup--right {
    right:50px;
}
.fb-popup--small.fb-popup--left {
    left:35px;
}
.fb-popup--small.fb-popup--right {
    right:40px;
}
.fb-popup--right:after,
.fb-popup--left:after {
    position: absolute;
    top:100%;
    left:8px;
    margin-top:-5px;
    content:'';
    transform:rotate(25deg);
    border-style: solid;
    border-width: 16px 9px 0 12px;
    border-color: #fff transparent transparent transparent;
}
.fb-popup--right:after {
    left:auto;
    right:8px;
    transform:rotate(-25deg);
}
.fb-popup--right:hover:after,
.fb-popup--left:hover:after,
.fb-popup--right:focus:after,
.fb-popup--left:focus:after {
    border-color: #3b5999 transparent transparent transparent;
}